import ButtonCustom from "@/components/Button/ButtonCustom";
import RHFAutoComplete from "@/components/Form/RHFAutoComplete";
import RHFPasswordField from "@/components/Form/RHFPasswordField";
import RHFTextField from "@/components/Form/RHFTextField";
import AcceptPrivacyPolicyCheckbox from "@/components/Policy/AcceptPrivacyPolicy";
import { useTranslation } from "@/i18n/client";
import { RegisterFormSchema } from "@/libs/types/authentication.type";
import color from "@/styles/color";
import { Grid, Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { ENV_CONFIG } from "@/libs";
import RHFPhoneNumberField from "@/components/Form/RHFPhoneNumberField";
import { Turnstile } from "@marsidev/react-turnstile";
import { authentication } from "@/libs/services/authentication/authentication.service";
import { genericType } from "@/libs/helpers/map.helper";

type RegisterFormProps = {
  form: UseFormReturn<RegisterFormSchema, any, undefined>;
};

type HowDidyouKnowAboutUsResponseType = {
  id: number
  title: string
  lang: string
}

const RegisterForm: FC<RegisterFormProps> = () => {
  const { t, i18n } = useTranslation("", {
    keyPrefix: "Authen",
  });
  const { setValue, watch } = useFormContext();
  const [isAccpt, tokenCaptcha] = watch(["isAccept", "tokenCaptcha"]);
  const [howDidYouKnowAboutUs, setHowDidYouKnowAboutUs] = useState<Record<string, string>[]>([])


  const getHowDidYouKnowData = async () => {
    try {
      const responseHowDidYouKnowAboutUs = await authentication.howDidYouKnowAboutUs(i18n?.resolvedLanguage === "th" ? "th" : "en");
      if (responseHowDidYouKnowAboutUs.status === 200) {
        const data = genericType<HowDidyouKnowAboutUsResponseType[]>(responseHowDidYouKnowAboutUs.data || []);
        const howDidYouKnowAboutUs = data
          .map((content) => ({
            label: content.title,
            value: String(content.id),
          }));
        setHowDidYouKnowAboutUs(howDidYouKnowAboutUs);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getHowDidYouKnowData();
  }, []);

  return (
    <Stack sx={{ gap: {xs: 2, sm: 1} }}>
      <Stack
        sx={{
          overflowY: { xs: "auto", sm: "unset" },
          maxHeight: { xs: "310px", sm: "unset" },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <RHFTextField
              name="firstName"
              label={t("form.first-name")}
              autoComplete="off"
              placeholder={t("placeholder.first-name")}
              labelSx={{
                fontSize: { xs: "12px", sm: "14px" },
                lineHeight: { xs: "18px", sm: "24px" },
              }}
              inputProps={{
                style: { padding: '12px 12px' },
              }}
              InputProps={{
                sx: {
                  height: '46px !important',
                  fontSize: "14px",
                  "& input::placeholder": {
                    fontSize: "14px"
                  }
                }
              }}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFTextField
              name="lastName"
              label={t("form.last-name")}
              autoComplete="off"
              placeholder={t("placeholder.last-name")}
              labelSx={{
                fontSize: { xs: "12px", sm: "14px" },
                lineHeight: { xs: "18px", sm: "24px" },
              }}
              inputProps={{
                style: { padding: '12px 12px' }
              }}
              InputProps={{
                sx: {
                  height: '46px !important',
                  fontSize: "14px",
                  "& input::placeholder": {
                    fontSize: "14px"
                  }
                }
              }}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFPhoneNumberField
              name="phone"
              label={t("form.phone")}
              autoComplete="off"
              placeholder={t("placeholder.phone")}
              labelSx={{
                fontSize: { xs: "12px", sm: "14px" },
                lineHeight: { xs: "18px", sm: "24px" },
              }}
              inputProps={{
                style: { padding: '12px 12px' }
              }}
              InputProps={{
                sx: {
                  height: '46px !important',
                  fontSize: "14px",
                  "& input::placeholder": {
                    fontSize: "14px"
                  }
                },
                autoComplete: 'new-phone'
              }}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFTextField
              name="email"
              label={t("form.email")}
              autoComplete="off"
              placeholder={t("placeholder.email")}
              labelSx={{
                fontSize: { xs: "12px", sm: "14px" },
                lineHeight: { xs: "18px", sm: "24px" },
              }}
              inputProps={{
                style: { padding: '12px 12px' }
              }}
              InputProps={{
                sx: {
                  height: '46px !important',
                  fontSize: "14px",
                  "& input::placeholder": {
                    fontSize: "14px"
                  }
                },
                autoComplete: 'new-email'
              }}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFPasswordField
              name="password"
              label={t("form.password")}
              autoComplete="off"
              placeholder={t("placeholder.password")}
              labelSx={{
                fontSize: { xs: "12px", sm: "14px" },
                lineHeight: { xs: "18px", sm: "24px" },
              }}
              inputProps={{
                style: { padding: '12px 0px' }
              }}
              InputProps={{
                sx: {
                  height: '46px !important',
                  fontSize: "14px",
                  "& input::placeholder": {
                    fontSize: "14px"
                  }
                },
                autoComplete: 'new-password'
              }}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFPasswordField
              name="confirmPassword"
              label={t("form.confirm-password")}
              autoComplete="off"
              placeholder={t("placeholder.confirm-password")}
              labelSx={{
                fontSize: { xs: "12px", sm: "14px" },
                lineHeight: { xs: "18px", sm: "24px" },
              }}
              inputProps={{
                style: { padding: '12px 0px' }
              }}
              InputProps={{
                sx: {
                  height: '46px !important',
                  fontSize: "14px",
                  "& input::placeholder": {
                    fontSize: "14px"
                  }
                },
                autoComplete: 'new-confirm-password'
              }}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <RHFAutoComplete
              id="howToKnow"
              name="howToKnow"
              label={t("form.how-know-tsure")}
              options={howDidYouKnowAboutUs}
              placeholder={t("placeholder.how-know-tsure")}
              labelSx={{
                fontSize: { xs: "12px", sm: "14px" },
                lineHeight: { xs: "18px", sm: "24px" },
              }}
              sx={{
                height: '46px',
                fontSize: '14px',
                padding: '12px 12px'
              }}
              SelectDisplayProps={{
                style: { padding: '12px 12px' },
              }}
              inputProps={{}}
            />
          </Grid>
        </Grid>
      </Stack>
      <AcceptPrivacyPolicyCheckbox
        checked={isAccpt}
        onCheckedAcceptPolicy={() => setValue("isAccept", !isAccpt)}
      />
      <Turnstile
        siteKey={ENV_CONFIG.CAPTCHA_SITEKEY}
        options={{
          theme: "light",
        }}
        style={{ margin: "auto" }}
        onSuccess={(token) => {
          setValue("tokenCaptcha", token);
        }}
      />
      <ButtonCustom
        disabled={!isAccpt || !tokenCaptcha}
        type="submit"
        label={t(`register.button-register`)}
        sx={{
          height: { xs: "40px" },
          fontSize: { xs: "14px", xl: "16px" },
          background: color.redGradient,
          color: color.white,
        }}
      />
    </Stack>
  );
};

export default RegisterForm;
