import { FC, useMemo } from "react";
import { SxProps, TextFieldProps } from "@mui/material";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";

import { useTranslation } from "@/i18n/client";
import FormControlContainer from "./FormControlContainer";
import InputPassword from "../Input/InputPassword";

export type RHFPasswordFieldProps = TextFieldProps & {
  name: NonNullable<TextFieldProps["name"]>;
  label: string;
  rules?: ControllerProps["rules"];
  containerSx?: SxProps
  labelSx?: SxProps
};

const RHFPasswordField: FC<RHFPasswordFieldProps> = ({ name, label, rules, labelSx, containerSx, ...rest }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const isRequired = useMemo(() => {
    return Boolean(rules?.required || false);
  }, [rules]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, value, ...other }, fieldState: { error } }) => {
        return (
          <FormControlContainer
            labelSx={labelSx}
            label={label}
            containerSx={containerSx}
            isRequired={isRequired}>
            <InputPassword
              {...other}
              {...rest}
              inputRef={ref}
              error={!!error}
              value={(value as unknown) ?? ""}
              helperText={t(error?.message as string)}
            />
          </FormControlContainer>
        );
      }}
    />
  );
};

export default RHFPasswordField;
