"use client";

import { Box, Stack, Typography } from "@mui/material";

import { DividerCustom } from "@/components/Divider/DividerCustom";
import { useTranslation } from "@/i18n/client";
import color from "@/styles/color";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";
import { useForm } from "react-hook-form";
import { RegisterFormSchema } from "@/libs/types/authentication.type";
import RHFFormProvider from "@/libs/react-hook-form/FormProvider";
import RegisterForm from "../forms/RegisterForm";
import SocialLogin from "../SocialLogin";
import { registerSchemaValidator } from "../forms/schema/register.schema";
import { OnClickProp } from "../AuthenticationContent";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { useAuthenticationStore } from "@/contexts/useAuthentication";

const Register = ({ onClick }: OnClickProp) => {
  const { t } = useTranslation("", {
    keyPrefix: "Authen.register",
  });
  const { setCurrentPage } = useAuthenticationStore();
  const { isLessThanSm, isHeightMoreThanXl } = useReadScreen();

  const registerForm = useForm<RegisterFormSchema>({
    defaultValues: {
      isAccept: false,
      howToKnow: undefined,
    },
    resolver: yupResolver(registerSchemaValidator),
  });

  const handleOnSubmit = registerForm.handleSubmit(async (params) => {
    const phone = params.phone.replaceAll("-", "");
    const howToKnow = Number(params.howToKnow)
    const payload = {
      ...params,
      phone,
      howToKnow,
    };
    onClick({ event: AuthenticationPageEnum.Register, body: payload });
  });

  return (
    <Stack sx={{ gap: { xs: 2, sm: 1 }, marginTop: "3px" }}>
      <>
      <Box sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#CCC"
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#888" 
        }
      }}>      
        <RHFFormProvider
          methods={registerForm}
          style={{ ...(isLessThanSm ? {} : { 
            ...isHeightMoreThanXl ? {
              height: "32vh",
            } : {
              height: "40vh",
            },            
            
          }) }}
          onSubmit={handleOnSubmit}
        >
          <RegisterForm form={registerForm} />
        </RHFFormProvider>
        </Box>
        <DividerCustom>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: { xs: "0.875rem" },
              lineHeight: { xs: "1.5rem" },
              color: color.gray400,
            }}
          >
            {t("register-with")}
          </Typography>
        </DividerCustom>
        <SocialLogin />
        <Stack sx={{ flexDirection: "row", gap: 1, margin: "0 auto" }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: "0.875rem" },
              lineHeight: { xs: "1.825rem" },
              color: color.gray400,
            }}
          >
            {t("already-account")}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: "0.875rem" },
              lineHeight: { xs: "1.825rem" },
              color: color.red,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => setCurrentPage(AuthenticationPageEnum.Login)}
          >
            {t("login")}
          </Typography>
        </Stack>
      </>
    </Stack>
  );
};

export default Register;
