"use client";

import { Stack, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import { DividerCustom } from "@/components/Divider/DividerCustom";
import { useTranslation } from "@/i18n/client";
import color from "@/styles/color";
import { useAuthenticationStore } from "@/contexts/useAuthentication";
import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";
import LoginForm from "../forms/LoginForm";
import { useForm } from "react-hook-form";
import { LoginFormSchema } from "@/libs/types/authentication.type";
import RHFFormProvider from "@/libs/react-hook-form/FormProvider";
import { loginSchema } from "../forms/schema/login.schema";
import SocialLogin from "../SocialLogin";
import { OnClickProp } from "../AuthenticationContent";
import { useReadScreen } from "@/hooks/useReadScreen.hook";

const Login = ({ onClick }: OnClickProp) => {
  const { isHeightMoreThanMd } = useReadScreen();
  const { t } = useTranslation("", {
    keyPrefix: "Authen.login",
  });

  const { setCurrentPage } = useAuthenticationStore();
  const loginForm = useForm<LoginFormSchema>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmitSignIn = loginForm.handleSubmit(async (values) => {
    onClick({ event: AuthenticationPageEnum.Login, body: values });
  });

  return (
    <Stack sx={{ 
      gap: { xs: 2, sm: 1 },
      ...(!isHeightMoreThanMd) && {
        maxHeight: "70vh" ,
      }
      }}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: { xs: "1rem" },
          lineHeight: { xs: "1.575rem" },
          color: color.black200,
          textAlign: "center",
        }}
      >
        {t("title")}
      </Typography>
      <RHFFormProvider methods={loginForm} onSubmit={onSubmitSignIn}>
        <LoginForm form={loginForm} />
      </RHFFormProvider>
      <DividerCustom>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: { xs: "0.875rem" },
            lineHeight: { xs: "1.5rem" },
            color: color.gray400,
          }}
        >
          {t("login-with")}
        </Typography>
      </DividerCustom>
      <SocialLogin />
      <Stack sx={{ flexDirection: "row", gap: 1, margin: "0 auto" }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: "0.875rem" },
            lineHeight: { xs: "1.825rem" },
            color: color.gray400,
          }}
        >
          {t("no-account")}
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: "0.875rem" },
            lineHeight: { xs: "1.825rem" },
            color: color.red,
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => setCurrentPage(AuthenticationPageEnum.Register)}
        >
          {t("register")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Login;
