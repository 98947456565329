import "@/styles/globals.css";
import { Box } from "@mui/material";
import IconLoader from "../IconLoader";

type DirectionType = "up" | "right" | "left" | "down";

interface ExpandMoreProps {
  direction?: DirectionType;
  svgClassName?: string;
}

const ExpandMore = ({ direction, svgClassName }: ExpandMoreProps) => {
  const getDirection = (dt?: DirectionType): string | undefined => {
    switch (dt) {
      case "up":
        return "rotate(180deg)";
      case "left":
        return "rotate(90deg)";
      case "right":
        return "rotate(270deg)";
      default:
        return undefined;
    }
  };
  return (
    <Box
      className={svgClassName ? svgClassName : undefined}
      sx={{
        transform: getDirection(direction),
      }}
    >
      <IconLoader iconName="ArrowDownAccordionIcon" />
    </Box>
  );
};

export default ExpandMore;
