const color = {
  white: "#FFFFFF",
  white90: "rgba(255, 255, 255, 0.9)",
  white100: "#FAFAFA",

  whiteGradient100:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(254, 230, 230, 0.5) 100%)",

  black: "#000000",
  black10: "#0A0A0A",
  black25: "#252525",
  grey: "#A1A1A1",
  black100: "#252525",
  black200: "#181819",
  black300: "#2F2F30",
  blackOp60: "#00000099",
  black400: "#00000033",
  black500: "#000000B2",
  black600: "#0000001A",
  black700: "#2525254D",
  red: "#F10503",
  red10: "#F1A6A2",
  red110: "#CF0301",
  red50: "#FFF6F6",
  yellow: "yellow",

  blackO1: "rgba(0, 0, 0, 0.1)",
  blackO2: "rgba(0, 0, 0, 0.2)",
  pipe: "rgba(51, 51, 51, 0.1)",

  formField: "#F2F2F2",

  redGradient: "linear-gradient(90deg, #F10503 0%, #CF0301 100%)",
  redGradient100: "linear-gradient(90deg, #F10503 0%, #93291E 100%)",
  redGradient200: "linear-gradient(90deg, #FF6767 0%, #FF6866 100%)",
  redDarkGradient:
    "linear-gradient(90deg, #F10503 0%, #6C0100 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",

  greenGradient: "linear-gradient(90deg, rgb(64 143 2) 0%, rgb(41 100 0) 100%)",
  goldGradient: "linear-gradient(90deg, rgb(175 139 1) 0%, rgb(150 113 2) 100%)",

  primary100: "#1A1C1E",

  redGray: "#E9E0E0",

  gray100: "#5B5E66",
  gray200: "#BFBFBF",
  gray300: "#686868",
  gray400: "#333333",
  gray450: "#EDEDED",
  gray500: "#EFEFEF",
  gray600: "#E2E2E2",
  gray650: "#E7E7E7",
  gray700: "#9D9D9D",
  gray800: "#5E5E5E",
  gray900: "#E1E1E1",
  gray90: "#F0F0F0",
  gray110: "#F1F1F1",
  gray120: "#F4F4F4",
  gray125: "#F5F5F5",
  gray130: "#C1C1C1",
  gray140: "#A4A4A4",
  gray150: "#F8F8F8",
  gray160: "#CCCCCC",
  gray170: "#E8E8E8",
  gray180: "#DCDCDC",
  gray185: "#737373",
  gray190: "#747475",
  gray210: "#B6B6B6",
  gray220: "#ECECEC",
  gray230: "#656565",
  gray240: "#DEDEDE",
  gray250: "#959595",
  gray260: "#DADADA",
  gray270: "#F3F3F3",
  gray280: "#F2F3F2",
  gray290: "#BABABA",
  gray310: "#E5E5E5",
  gray320: "#DDDDDD",
  grayGradient:
    "linear-gradient(180deg, rgba(248,248,248,1) 0%, rgba(255,255,255,1) 50%, rgba(153,153,153, 0.5) 100%)",
  grayGradient100:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(153, 153, 153, 0.5) 100%)",

  pink100: "#FFE2E2",
  pink200: "#FEE6E6",
  pinkGradient:
    "linear-gradient(180deg, rgba(245, 245, 245, 0.5) 0%, rgba(254, 230, 230, 0.5) 100%)",
  pinkGradient100: "linear-gradient(180deg, #FFFFFF 0%, #FEE6E6 49.42%)",
  pinkGradient200: "linear-gradient(180deg, #F5F5F5 0%, #FFC6C6 100%)",
  pinkGradient300: "linear-gradient(180deg, #FFFFFF 25.5%, #FEE6E6 64.5%)",

  green100: "#D1FFE4",
  green200: "#16994B",

  yellow100: "#E7AA3D",
  yellowKrungsri: "#FFD400",
  yellowGray: "#E9E8E0",

  brown100: "#6F5F5E",

  blueTtb: "#004FF1",

  orange100: "#F68C1F",

  stepInactive: "#CFD6DC",
};

export default color;
