"use client";

import DrawerBottomCustom from "@/components/Drawer/DrawerBottomCustom";
import ModalCloseOutBoxCustom from "@/components/Modal/ModalCloseOutBoxCustom";
import { DealerLine, useDealerStore } from "@/contexts/useDealer";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { useTranslation } from "@/i18n/client";
import DealerDetailLineContactModalContent from "@/modules/dealer-detail/components/ContentModal/DealerDetailLineContactModalContent";
import DealerDetailSocialContactModalContent from "@/modules/dealer-detail/components/ContentModal/DealerDetailSocialContactModalContent";
import { useMemo } from "react";

const ModalSocial = () => {
  const { t } = useTranslation();
  const { isLessThanSm } = useReadScreen();
  const { dealerInfo, currentType, clearDealerInfo } = useDealerStore();

  const label = useMemo(() => {
    let titleInMobile: string = "";
    if (currentType == "facebook") {
      titleInMobile = "common.contact.facebook-page";
    } else if (currentType == "line") {
      titleInMobile = "common.contact.add-line";
    } else {
      titleInMobile = "common.contact.contact-dealer";
    }

    return t(titleInMobile);
  }, [currentType]);

  return (
    <>
      {Boolean(!isLessThanSm && dealerInfo) && (
        <ModalCloseOutBoxCustom
          name="dealer_social_contact"
          open={Boolean(!isLessThanSm && dealerInfo)}
          onClose={() => clearDealerInfo()}
          dialogProps={{
            sx: {
              "& .MuiPaper-root": {
                bgcolor: "transparent",
                margin: "8px",
                boxShadow: "unset",
                width: { xs: "1060px" },
                maxWidth: { xs: "1060px" },
              },
              "& .MuiModal-backdrop": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              },
            },
          }}
        >
          {currentType === "facebook" && (
            <DealerDetailSocialContactModalContent
              contact={dealerInfo?.tel || []}
              facebookID={dealerInfo?.facebookID || ""}
              title={t("common.contact.facebook-page")}
              from={currentType}
              isMobile={false}
              showPhoneNumber={false}
            />
          )}

          {currentType === "telephone" && (
            <DealerDetailSocialContactModalContent
              contact={dealerInfo?.tel || []}
              facebookID={dealerInfo?.facebookID || ""}
              title={t("common.contact.contact-dealer")}
              from={currentType}
              isMobile={false}
              showPhoneNumber={true}
            />
          )}

          {currentType === "line" && (
            <DealerDetailLineContactModalContent
              lineContact={dealerInfo?.line || ({} as DealerLine)}
              title={t("common.contact.add-line")}
              from={currentType}
            />
          )}
        </ModalCloseOutBoxCustom>
      )}
      {Boolean(isLessThanSm && dealerInfo) && (
        <DrawerBottomCustom
          labelName={label}
          isOpen={Boolean(isLessThanSm && dealerInfo)}
          onCloseDrawer={clearDealerInfo}
        >
          {currentType === "telephone" && (
            <DealerDetailSocialContactModalContent
              contact={dealerInfo?.tel || []}
              facebookID={dealerInfo?.facebookID || ""}
              title={t("common.contact.contact-dealer")}
              from={currentType}
              isMobile={true}
              showPhoneNumber={true}
            />
          )}

          {currentType === "facebook" && (
            <DealerDetailSocialContactModalContent
              contact={dealerInfo?.tel || []}
              facebookID={dealerInfo?.facebookID || ""}
              title={t("common.contact.facebook-page")}
              from={currentType}
              isMobile={true}
              showPhoneNumber={false}
            />
          )}

          {currentType === "line" && (
            <DealerDetailLineContactModalContent
              lineContact={dealerInfo?.line || ({} as DealerLine)}
              title={t("common.contact.add-line")}
              from={currentType}
            />
          )}
        </DrawerBottomCustom>
      )}
    </>
  );
};

export default ModalSocial;
