import { axiosDSureInstance } from "@/libs/axios/axios-dsure-instance";
import { apiPaths } from "@/libs/constant/api";
import { DealerListParams, DealerListResponse } from "@/libs/types/dealer.type";

export const DealerServices = {
  getDealerList: async (params: DealerListParams): Promise<DealerListResponse> => {
    const res: DealerListResponse = await axiosDSureInstance
      .get(apiPaths.dealer.getDealerData, {
        params,
      })
      .then((response) => response.data);
    return {
      DealerInfo: res.DealerInfo,
      total: res.DealerInfo.length,
    };
  },
};
