export const haversineDistance = (
  branchLatitude: number,
  branchLongitude: number,
  currentLatitude: number,
  currentLongitude: number,
) => {
  const R = 6371;
  const toRad = (angle: number) => angle * (Math.PI / 180);
  const dLat = toRad(currentLatitude - branchLatitude);
  const dLon = toRad(currentLongitude - branchLongitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(branchLatitude)) *
      Math.cos(toRad(currentLatitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

export const getCurrentLanguage = () => {
  let lang = "th";

  if (navigator?.language && navigator?.language !== "th") {
    lang = "en";
  }

  return lang;
};
