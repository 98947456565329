import { User } from "@/libs/types/authentication.type";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type ProfileStore = {
  isLogged: boolean;
  profile: User | null;
  setProfile: (profile: User | null) => void;
  setLogged: (isLogged: boolean) => void;
};

const initialState = {
  isLogged: false,
  profile: null,
};

export const useAuthStore = create<ProfileStore>()(
  persist(
    (set) => ({
      profile: initialState.profile,
      isLogged: initialState.isLogged,
      setProfile: (profile: User | null) => set({ profile: profile }),
      setLogged: (isLogged: boolean) => set({ isLogged }),
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
