"use client";

import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack, SxProps, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "@/i18n/client";

import DrawerBottomCustom from "@/components/Drawer/DrawerBottomCustom";
import { DividerCustom } from "@/components/Divider/DividerCustom";
import ModalCloseOutBoxCustom from "@/components/Modal/ModalCloseOutBoxCustom";
import AuthenticationContent from "./components/AuthenticationContent";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";
import color from "@/styles/color";
import { currentUrl, genericType } from "@/libs/helpers/map.helper";
import { useSingUpStore } from "@/contexts/useAnonymous";
import { authentication } from "@/libs/services/authentication/authentication.service";
import {
  ChangePasswordFormSchema,
  eventAuthType,
  ForgotPasswordFormSchema,
  LoginFormSchema,
  RegisterFormSchema,
  RegisterResponse,
  ResetPasswordFormSchema,
  Verify,
} from "@/libs/types/authentication.type";
import { useAuthStore } from "@/contexts/useAuth";
import { useAuthenticationStore } from "@/contexts/useAuthentication";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { getCurrentLanguage } from "@/libs/helpers/calculate-distance";
import { UserProfile } from "@/libs/types/edit-profile.type";
import dynamic from "next/dynamic";
import { AxiosError } from "axios";
import RateLimitPopup from "../dealer/components/RateLimitPopup";
import { useRateLimitStateStore } from "@/contexts/useDialogRateLimit";
import { _get } from "@/libs/utils/helper";

const ProgressPopup = dynamic(() => import("@/modules/dealer/components/ProgressPopup"), {
  ssr: false,
});

const ModalContactSuccess = dynamic(() => import("../find-my-car/components/modal/ModalContactSuccess"), {
  ssr: false,
});

const AuthenticationPopup = () => {
  const { t } = useTranslation();
  const { isLessThanSm, isHeightMoreThanMd } = useReadScreen();
  const { setShowAuthenticationPopup, setCurrentPage, currentPage, showAuthenticationPopup } =
    useAuthenticationStore();
  const { setLoading, setError, setEmail, isLoading, isError, email } = useSingUpStore();
  const { setProfile, setLogged, isLogged } = useAuthStore();
  const [success, setSuccess] = useState(false);
  const [isDoneSignup, setIsDoneSignup] = useState(false)
  const [eventAuth, setEventAuth] = useState<eventAuthType>();
  const {onOpenModal} = useRateLimitStateStore();

  const allowShow = [AuthenticationPageEnum.ForgotPassword, AuthenticationPageEnum.Register];
  const isShow = allowShow.includes(currentPage) && !isLogged;
  const router = useRouter()
  const path = usePathname()
  const params = useSearchParams();
  const token = params.get("token");
  const tokenKey = params.get("token_key");
  const tokenVerify = params.get("token_verify");

  const getEmail = useCallback(
    async (token: string) => {
     try {
      if (!token) return
      const response = await authentication.getEmail(token);
      if (response.data?.email) {
        setEmail(response.data?.email);
        setShowAuthenticationPopup(true);
        setCurrentPage(AuthenticationPageEnum.ResetPassword);
      } else {
        setShowAuthenticationPopup(false);
      }
     } catch (error) {}
    },
    [email, currentPage, showAuthenticationPopup],
  );

  const validateToken = useCallback(async (tokenVerify: string) => {
    try {
      if (!tokenVerify) return
      const response = await authentication.verifySignup(tokenVerify)
      if (response.status === 201 && response.data?.isExpired) {
        setShowAuthenticationPopup(true);
        setCurrentPage(AuthenticationPageEnum.Verify);
      } else if (response.status === 201 && !response.data?.isExpired) {
        setShowAuthenticationPopup(true);
        setCurrentPage(AuthenticationPageEnum.Login);
      }
    } catch (error) {}
  }, [currentPage, showAuthenticationPopup])

  useEffect(() => {
    if (token) getEmail(token);
  }, [token]);

  useEffect(() => {
    if (tokenVerify) validateToken(tokenVerify);
  }, [tokenVerify]);

  const getProfile = async () => {
    try {
      const response = await authentication.getProfile();
      if (response.status === 200) {
        setProfile(response.data);
        setLogged(true);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (tokenKey) getProfile();
  }, [tokenKey]);

  const handleClose = useCallback(() => {
    setError(false);
    setShowAuthenticationPopup(false);
    setEventAuth(undefined)
    setIsDoneSignup(false)
    router.replace(currentUrl(path, params, ['token_verify', 'token'], {}), { scroll: false })
  }, [router, path, params, showAuthenticationPopup, eventAuth, isDoneSignup]);

  const label = useMemo(() => {
    return t(`Authen.${currentPage}.header`);
  }, [currentPage, t]);

  const setCloseModal = () => {
    setTimeout(() => {
      setSuccess(false);
    }, 1500);
  };

  const labelSx = useMemo(() => {
    return {
      fontWeight: { xs: 600, sm: 700 },
      fontSize: { xs: "16px", sm: "18px", lg: "22px" },
      lineHeight: { xs: "22px", sm: "24px" },
      background: color.redGradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    } as SxProps;
  }, []);

  const handleClickBack = useCallback(() => {
    setCurrentPage(AuthenticationPageEnum.Login);
    router.replace(currentUrl(path, params, ['token_verify', 'token'], {}), { scroll: false })
  }, [router, path, params, currentPage]);

  const onClick = async (args: Record<string, string | Object>) => {
    setShowAuthenticationPopup(false);
    switch (args.event) {
      case AuthenticationPageEnum.Register:
        return onSignUp(args);
      case AuthenticationPageEnum.Login:
        return onSignIn(args);
      case AuthenticationPageEnum.ForgotPassword:
        return onSendEmail(args);
      case AuthenticationPageEnum.ResetPassword:
        return onResetPassword(args);
      case AuthenticationPageEnum.EditProfile:
        return onUpdateProfile(args);
      case AuthenticationPageEnum.ChangePassword:
        return onChangePassword(args);
      case AuthenticationPageEnum.Verify:
        return onVerify(args);
      default:
        break;
    }
  };

  const onSignUp = async (params: Record<string, Object>) => {
      setLoading(true);
    try {
      const locale = getCurrentLanguage();
      const body = genericType<RegisterFormSchema>(params.body);

      if (body.phone) {
        body.phone = body.phone.replace(/(\d{3})(\d{3})(\d{4})/gi, '$1-$2-$3');
      }

      const response = await authentication.signUp({ ...body, lang: locale });
      if (response.status == 429){
        onOpenModal();
      }
      setLoading(false);
      setIsDoneSignup(true)
      setEventAuth('DONE_SIGNUP')
    } catch (error) {
      setLoading(false);
      const exception = genericType<AxiosError>(error)
      if (exception?.response?.status === 409) {
        setEventAuth('DUPLICATE_EMAIL')
      } else {
        setError(true);
      }
    } finally {
      setCloseModal();
    }
  };

  const onSignIn = async (params: Record<string, Object>) => {
    setLoading(true);
    try {
      const locale = getCurrentLanguage();
      const body = genericType<LoginFormSchema>(params.body);
      const response = await authentication.signIn(body, locale);
      const responseData = genericType<RegisterResponse>(response.data);
      if (response.status === 200 && responseData?.user) {
        setProfile(responseData?.user);
        setLogged(true);
      }
      if (response.status == 429){
        onOpenModal();
      }

      setLoading(false);
      setSuccess(true);
    } catch (error) {
      const exception = genericType<AxiosError>(error)
      const errorMessage = _get(exception, "response.data.message")

      setLoading(false);
      if (exception?.response?.status === 406) {
        setEventAuth('RESET_PASSWORD')
      } else if (exception?.response?.status === 400 && errorMessage === 'password incorrect') {
        setEventAuth('INVALID_PASSWORD')
      } else if (exception?.response?.status === 400) {
        setEventAuth('INVALID_EMAIL')
      } else if (exception?.response?.status === 404) {
        setEventAuth('ACTIVE_EMAIL')
      } else {
        setError(true);
      }

    } finally {
      setCloseModal();
    }
  };

  const onSendEmail = async (params: Record<string, Object>) => {
    setLoading(true);
    try {
      const body = genericType<ForgotPasswordFormSchema>(params.body);
      const locale = getCurrentLanguage();
      const response = await authentication.forgotPassword({ ...body, locale });
      if (response.status == 429){
        onOpenModal();
      }
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setError(true);
    } finally {
      setCloseModal();
    }
  };

  const onResetPassword = async (_params: Record<string, Object>) => {
    setLoading(true);
    try {
      const body = genericType<ResetPasswordFormSchema>(_params.body);
      await authentication.resetPassword(body);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setError(true);
    } finally {
      router.replace(currentUrl(path, params, ['token'], {}), { scroll: false })
      setCloseModal();
    }
  };

  const onUpdateProfile = async (params: Record<string, Object>) => {
      setLoading(true);
    try {
      delete params['email']
      const body = genericType<UserProfile>(params.body);
      const response = await authentication.updateProfile(body);
      if (response.status === 200) await getProfile()

      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setError(true);
    } finally {
      setCloseModal();
    }
  };

  const onChangePassword = async (params: Record<string, Object>) => {
    setLoading(true);
    try {
      const body = genericType<ChangePasswordFormSchema>(params.body);
      await authentication.changePassword(body);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      const exception = genericType<AxiosError>(error)
      if (exception?.response?.status === 406) {
        setEventAuth('MIS_MATCHED_PASSWORD')
      } else {
        setError(true);
      }
    } finally {
      setCloseModal();
    }
  };

  const onVerify = async (param: Record<string, Object>) => {
    setLoading(true);
    try {
      const body = genericType<Verify>(param.body);
      await authentication.requestVerify(body.email);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setError(true);
    } finally {
      setCloseModal();
    }
  }

  return (
    <>
      <ModalCloseOutBoxCustom
        name="auth-modal"
        open={Boolean(!isLessThanSm && showAuthenticationPopup)}
        onClose={handleClose}
        dialogProps={{
          sx: {
            ...!isHeightMoreThanMd && {
              "& .MuiDialog-container": {
                height: 'auto'
              },
            },
            "& .MuiPaper-root": {
              bgcolor: "transparent",
              margin: "8px",
              boxShadow: "unset",
              width: { sm: "610px", lg: "660px" },
              maxWidth: { sm: "610px", lg: "660px" },
            },
            "& .MuiModal-backdrop": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
            "& .MuiDialogContent-root": {
              overflow: "hidden",
            },
          },
        }}
      >
        <Box component={"div"} sx={{ width: "100%", padding: { sm: "30px" } }}>
          <Box
            component={"div"}
            sx={{
              width: "100%",
              bgcolor: color.white,
              borderRadius: "1rem",
              display: "flex",
              flexDirection: "column",
              padding: { sm: "32px 24px" },
              gap: 1,
            }}
          >
            <Stack gap={1}>
              <Toolbar
                variant="dense"
                sx={{
                  minHeight: 30,
                  justifyContent: "center",
                  paddingX: { xs: 2, sm: 0 },
                }}
                disableGutters
              >
                <Typography sx={labelSx}>{label}</Typography>
              </Toolbar>
              <DividerCustom sx={{ borderColor: color.gray170 }} />
            </Stack>
            <AuthenticationContent event={currentPage} onClick={onClick} />
          </Box>
        </Box>
      </ModalCloseOutBoxCustom>

      <ProgressPopup handleClose={handleClose} isError={isError} isDoneSignup={isDoneSignup} isLoading={isLoading} errorKey={eventAuth!} />
      <RateLimitPopup />
      <ModalContactSuccess visible={success || success && isLogged} message={t(success && isLogged ? "common.modal.message-logged-success" : "common.modal.message-success")} />
      <DrawerBottomCustom
        labelName={label}
        labelSx={labelSx}
        isOpen={Boolean(isLessThanSm && showAuthenticationPopup)}
        onCloseDrawer={handleClose}
        showBackButton={isShow}
        onClickBack={handleClickBack}
      >
        <DividerCustom sx={{ borderColor: color.gray170 }} />
        <AuthenticationContent event={currentPage} onClick={onClick} />
      </DrawerBottomCustom>
    </>
  );
};

export default AuthenticationPopup;
