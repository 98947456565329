import color from "@/styles/color";
import { FormControl, FormLabel, SxProps } from "@mui/material";
import { FC, ReactNode } from "react";

type FormControlContainerProps = {
  label: string;
  name?: string;
  isRequired?: boolean;
  children: ReactNode;
  containerSx?: SxProps;
  labelSx?: SxProps;
};

const FormControlContainer: FC<FormControlContainerProps> = ({
  label,
  name = "common",
  children,
  isRequired = false,
  containerSx,
  labelSx
}) => {
  return (
    <FormControl sx={{ width: "100%", ...containerSx }}>
      <FormLabel
        sx={{
          fontWeight: 500,
          fontSize: { xs: "12px", sm: "16px" },
          lineHeight: { xs: "18px", sm: "24px" },
          color: color.gray400,
          "&.Mui-focused": {
            color: color.gray400,
          },
          ...labelSx,
        }}
        htmlFor={name}
      >
        {label}
        {isRequired && <span style={{ color: color.red }}>*</span>}
      </FormLabel>
      {children}
    </FormControl>
  );
};

export default FormControlContainer;
