import {
  DetailedHTMLProps,
  FormEventHandler,
  FormHTMLAttributes,
  PropsWithChildren,
} from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

type RHFFormProviderProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
> = PropsWithChildren &
  DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
    methods: UseFormReturn<TFieldValues, TContext>;
    onSubmit?: FormEventHandler<HTMLFormElement>;
  };

const RHFFormProvider = <T extends FieldValues>({
  children,
  methods,
  onSubmit,
  ...rest
}: RHFFormProviderProps<T>) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} {...rest}>
        {children}
      </form>
    </FormProvider>
  );
};

export default RHFFormProvider;
