import { FC } from "react";
import { Box, Stack, SvgIcon, SxProps, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useTranslation } from "@/i18n/client";
import color from "@/styles/color";

type CardAddCarProps = {
  onClick?: () => void;
  layout?: "horizontal" | "vertical";
  customContainerSx?: SxProps;
};

const CardAddCar: FC<CardAddCarProps> = ({ onClick, customContainerSx }) => {
  const { t } = useTranslation();

  return (
    <Stack
      role="button"
      flexDirection="row"
      sx={{
        borderRadius: 4,
        border: `1px dashed ${color.gray700}`,
        cursor: "pointer",
        height: { xs: "80px", sm: "100%" },
        justifyContent: "center",
        ...customContainerSx,
      }}
      onClick={() => onClick && onClick()}
    >
      <Box
        sx={{
          color: color.gray700,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SvgIcon
          sx={{
            fontSize: "32px",
          }}
        >
          <AddCircleOutlineIcon />
        </SvgIcon>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "24px",
          }}
        >
          {t("CompareCar.modal.placeholder-add-car")}
        </Typography>
      </Box>
    </Stack>
  );
};

export default CardAddCar;
