import {
  IconButton,
  Stack,
  SvgIcon,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { DividerCustom } from "@/components/Divider/DividerCustom";
import { useCompareCarStore } from "@/contexts/useCompareCar";
import { useTranslation } from "@/i18n/client";

import color from "@/styles/color";

const DrawerHeaderSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { setShowDrawer } = useCompareCarStore();

  const isLessThanSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setShowDrawer(false);
  };

  return (
    <Stack gap={2}>
      <Toolbar
        variant="dense"
        sx={{
          minHeight: 30,
          justifyContent: isLessThanSm ? "space-between" : "flex-end",
          paddingX: { xs: 2, sm: 0 },
        }}
        disableGutters
      >
        {isLessThanSm ? (
          <>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "30px",
                color: color.black200,
              }}
            >
              {t("CompareCar.modal.title")}
            </Typography>
            <IconButton onClick={handleClose} edge="end">
              <SvgIcon
                sx={{
                  fontSize: "32px",
                  color: color.black,
                }}
                component={CloseIcon}
              />
            </IconButton>
          </>
        ) : (
          <Typography
            onClick={handleClose}
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
              background: color.redGradient,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              position: "relative",
              cursor: "pointer",
              "&::after": {
                content: "''",
                position: "absolute",
                borderImage: `${color.redGradient} 1`,
                borderStyle: "solid",
                borderBottomWidth: 1,
                width: "100%",
                bottom: 2,
                left: 0,
              },
            }}
          >
            {t("CompareCar.modal.close-title")}
          </Typography>
        )}
      </Toolbar>
      {isLessThanSm && <DividerCustom sx={{ borderColor: color.gray170 }} />}
    </Stack>
  );
};

export default DrawerHeaderSection;
