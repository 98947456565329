"use client";

import { Stack } from "@mui/material";
import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";
import { useForm } from "react-hook-form";
import RHFFormProvider from "@/libs/react-hook-form/FormProvider";
import { OnClickProp } from "../AuthenticationContent";
import ChangePasswordForm from "../forms/changePassword";
import { ChangePasswordFormSchema } from "@/libs/types/authentication.type";
import { changePasswordSchema } from "../forms/schema/reset-password";
import { yupResolver } from "@hookform/resolvers/yup";

const ChangePasswordPage = ({ onClick }: OnClickProp) => {

  const changePassword = useForm<ChangePasswordFormSchema>({
    resolver: yupResolver(changePasswordSchema),
  });

  const handleOnSubmit = changePassword.handleSubmit(async (params) => {
    onClick({ event: AuthenticationPageEnum.ChangePassword, body: params });
  });

  return (
    <Stack sx={{ gap: { xs: 3, sm: 2 } }}>
      <RHFFormProvider methods={changePassword} onSubmit={handleOnSubmit}>
        <ChangePasswordForm form={changePassword} />
      </RHFFormProvider>
    </Stack>
  );
};

export default ChangePasswordPage;
