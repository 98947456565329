"use client";

import React from "react";
import {
  defaultShouldDehydrateQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import getQueryClient from "@/libs/common/getQueryClient";

export default function QueryProvider({ children }: { children: React.ReactNode }) {
  const queryClient = getQueryClient()
  // const [queryClient] = React.useState(
  //   () =>
  //     new QueryClient({
  //       defaultOptions: {
  //         queries: {
  //           staleTime: 60 * 1000,
  //           refetchOnWindowFocus: false,
  //         },
  //         dehydrate: {
  //           shouldDehydrateQuery: (query) =>
  //             defaultShouldDehydrateQuery(query) || query.state.status === "pending",
  //         },
  //       },
  //     }),
  // );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
