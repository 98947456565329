import { create } from "zustand";

type PageLoadingStore = {
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingError: boolean;
  isLoadingEnd: boolean;

  setLoading: () => void;
  setLoadingSuccess: () => void;
  setLoadingError: () => void;
  setLoadingEnd: () => void;
  resetLoading: () => void;
};

export const usePageLoadingStore = create<PageLoadingStore>((set) => ({
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingError: false,
  isLoadingEnd: false,

  setLoading: () => {
    set({
      isLoading: true,
      isLoadingSuccess: false,
      isLoadingError: false,
      isLoadingEnd: false,
    });
  },
  setLoadingSuccess: () => {
    set({
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingError: false,
      isLoadingEnd: false,
    });
  },
  setLoadingError: () => {
    set({
      isLoading: false,
      isLoadingSuccess: false,
      isLoadingError: true,
      isLoadingEnd: false,
    });
  },
  setLoadingEnd: () => {
    set({
      isLoading: false,
      isLoadingSuccess: false,
      isLoadingError: false,
      isLoadingEnd: true,
    });
  },
  resetLoading: () => {
    set({
      isLoading: false,
      isLoadingSuccess: false,
      isLoadingError: false,
      isLoadingEnd: false,
    });
  },
}));
