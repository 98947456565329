import * as yup from "yup";

const htmlTagPattern = /<\/?[^>]+(>|$)/g;
const scriptTagPattern = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
const todayNow = new Date();

const dropLeadValidationSchema = yup.object().shape({
  customerName: yup.string()
    .test(
      'no-html-or-script',
      "common.droplead.error.html-script",
      (value) => {
        if (!value) return true;  // Skip validation if input is empty
        return !htmlTagPattern.test(value) && !scriptTagPattern.test(value);
      }
    ).required("common.droplead.error.full-name"),
  contactBack: yup.date()
    .min(todayNow, "common.droplead.error.date-time")
    .required("common.droplead.error.date-time"),
  phoneNumber: yup
    .string()
    .required("common.droplead.error.phone-number")
    .matches(/^\S+$/, "common.droplead.error.phone-number")
    // .matches(/^0(\d{9})$/, "common.droplead.error.phone-number-wrong"),
    .matches(/^0\d{2}-\d{3}-\d{4}$/, "common.droplead.error.phone-number-wrong"),
  vehicleMasterId: yup.number().required(),
  contactType: yup.string().required(),
  isAccept: yup.boolean().optional(),
});

export default dropLeadValidationSchema;
