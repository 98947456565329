import { GetVehicleDetailParams } from "./type";
import { axiosDSureInstance } from "@/libs/axios/axios-dsure-instance";
import { apiPaths } from "@/libs/constant/api";
import {
  VehicleDetail,
  VehicleReponse,
  VehicleSearchParams,
  VehicleSearchReponse,
} from "@/libs/types/vehicle.type";

export const VehicleServices = {
  getVehicleDetail: async (params: GetVehicleDetailParams) => {
    const result = await axiosDSureInstance
      .get<{ VehicleDetails: VehicleDetail }>(apiPaths.vehicle.getVehicleData, {
        params,
      })
      .then((res) => {
        return res.data;
      });
    return result.VehicleDetails;
  },
  getVihicleSearch: async (params: VehicleSearchParams) => {
    const results = await axiosDSureInstance
      .post<VehicleSearchReponse>(apiPaths.vehicle.vehicleSearch, params)
      .then((res) => res.data);

    return results;
  },
  getVihicleByDealer: async (params: VehicleSearchParams) => {
    const results = await axiosDSureInstance
      .post<VehicleReponse>(apiPaths.vehicle.vehicleSearch, params)
      .then((res) => res.data);

    return {
      SearchResult: results.SearchResult,
      TotalCount: results.TotalCount,
    };
  },
};
