import ButtonCustom from "@/components/Button/ButtonCustom";
import RHFPasswordField from "@/components/Form/RHFPasswordField";
import { useTranslation } from "@/i18n/client";
import { ChangePasswordFormSchema } from "@/libs/types/authentication.type";
import color from "@/styles/color";
import { Grid, Stack } from "@mui/material";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";

type ChangePasswordForm = {
  form: UseFormReturn<ChangePasswordFormSchema, any, undefined>;
};

const ChangePasswordForm: FC<ChangePasswordForm> = () => {
  const { t } = useTranslation("", {
    keyPrefix: "Authen",
  });

  return (
    <Stack sx={{ gap: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <RHFPasswordField
            name="oldPassword"
            autoComplete="off"
            label={t("form.current-password")}
            placeholder={t("placeholder.current-password")}
            InputProps={{
              autoComplete: 'new-old-password'
            }}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <RHFPasswordField
            name="password"
            autoComplete="off"
            label={t("form.new-password")}
            placeholder={t("placeholder.new-password")}
            InputProps={{
              autoComplete: 'new-password'
            }}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <RHFPasswordField
            name="confirmPassword"
            autoComplete="off"
            label={t("form.confirm-new-password")}
            placeholder={t("placeholder.confirm-new-password")}
            InputProps={{
              autoCorrect: 'new-confirm-password'
            }}
            rules={{ required: true }}
          />
        </Grid>
      </Grid>
      <ButtonCustom
        type="submit"
        label={t(`change-password.button-change-password`)}
        sx={{
          height: { xs: "40px" },
          fontSize: { xs: "14px", xl: "16px" },
          background: color.redGradient,
          color: color.white,
        }}
      />
    </Stack>
  );
};

export default ChangePasswordForm;
