import { useMemo } from "react";
import { useTranslation } from "@/i18n/client";
import { Box, Stack, SxProps, Typography } from "@mui/material";

import { useCompareCarStore } from "@/contexts/useCompareCar";
import ButtonOutlinedCustom from "@/components/Button/ButtonOutlinedCustom";
import ButtonCustom from "@/components/Button/ButtonCustom";
import ModalCloseOutBoxCustom from "@/components/Modal/ModalCloseOutBoxCustom";
import { useReadScreen } from "@/hooks/useReadScreen.hook";

import color from "@/styles/color";
import IconLoader from "@/components/IconLoader";

const ModalCompareCarLimitError = () => {
  const { t } = useTranslation();
  const { showAlertLimitModal, setShowAlertLimitModal, setShowDrawer } = useCompareCarStore();

  const { isMoreThanMd, isMoreThanSm } = useReadScreen();

  const handleClose = () => {
    setShowAlertLimitModal(false);
  };

  const buttonStyleSx: SxProps = {
    height: { xs: "40px", md: "58px" },
    fontSize: { xs: "0.875rem", md: "1.25rem" },
    lineHeight: { xs: "1.3125rem", md: "1.4375rem" },
    padding: 0,
    width: "100%",
  };

  const title = useMemo(() => {
    return `${t("CompareCar.error.title").replace(`{{wrap}}`, isMoreThanSm ? "" : "\n")}`;
  }, [t, isMoreThanSm]);

  const description = useMemo(() => {
    return `${t("CompareCar.error.description").replace(`{{wrap}}`, isMoreThanMd ? "" : "\n")}`;
  }, [t, isMoreThanMd]);

  return (
    <>
      {showAlertLimitModal && (
        <ModalCloseOutBoxCustom
          name="dealer_social_contact"
          open={showAlertLimitModal}
          onClose={handleClose}
          dialogProps={{
            sx: {
              "& .MuiPaper-root": {
                bgcolor: "transparent",
                boxShadow: "unset",
                width: { xs: "288px", sm: "373px", md: "703px" },
                maxWidth: { xs: "288px", sm: "373px", md: "703px" },
              },
              "& .MuiModal-backdrop": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              },
            },
          }}
          showCloseIcon={isMoreThanMd}
        >
          <Box component={"div"} sx={{ width: "100%", padding: { md: "30px" } }}>
            <Box
              component={"div"}
              sx={{
                width: "100%",
                bgcolor: color.white,
                borderRadius: "1rem",
                padding: { xs: "1.5rem", md: "4rem" },
                display: "flex",
                flexDirection: "column",
                gap: { xs: "1rem", md: "2rem" },
              }}
            >
              <Box
                sx={{
                  width: "fit-content",
                  borderRadius: "100%",
                  background: color.pink200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  padding: { xs: "12px", md: "20px" },
                }}
              >
                <IconLoader iconName="ErrorRedGradientIcon" sx={{ fontSize: { lg: "36px" } }} />
              </Box>
              <Stack
                sx={{
                  gap: { xs: "0.5rem", md: "1rem" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "1rem", md: "1.5rem" },
                    lineHeight: { xs: "1.5rem", md: "2.25rem" },
                    whiteSpace: "pre",
                    textAlign: "center",
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: "0.875rem", md: "1.25rem" },
                    lineHeight: { xs: "1.3125rem", md: "1.875rem" },
                    whiteSpace: "pre",
                    textAlign: "center",
                  }}
                >
                  {description}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  gap: { xs: "1rem", md: "1.5rem" },
                }}
              >
                <ButtonCustom
                  onClick={() => {
                    handleClose();
                    setShowDrawer(true);
                  }}
                  label={t("CompareCar.error.confirm-button")}
                  sx={{
                    ...buttonStyleSx,
                    color: color.formField,
                  }}
                />
                <ButtonOutlinedCustom
                  onClick={handleClose}
                  label={t("CompareCar.error.close-button")}
                  sx={{ ...buttonStyleSx, color: color.gray400 }}
                />
              </Stack>
            </Box>
          </Box>
        </ModalCloseOutBoxCustom>
      )}
    </>
  );
};

export default ModalCompareCarLimitError;
