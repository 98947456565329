import { Dealer } from "@/libs/types/dealer.type";
import { FinanceInfo, VehicleCard, VehicleImage } from "@/libs/types/vehicle.type";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { DealerServices } from "@/libs/services/dealer";
import { VehicleServices } from "@/libs/services/vehicle";
import { ProductDetailServices } from "@/libs/services/product-detail/product-detail.service";
import dayjs from "dayjs";
import { wishlist } from "@/libs/services/wish-list/wish-list.service";
import { genericType } from "@/libs/helpers/map.helper";
import { useAuthStore } from "./useAuth";
import { VehicleDetail } from "@/libs/services/want-to-buy/types";
import { FinancialInfoItem } from "@/libs/types/financial-car-product.type";
import { _get } from "@/libs/utils/helper";

export type DealerWithVehicles = Dealer & {
  Vehicles: VehicleCard[];
};

export type VehicleDetailWithDealer = {
  Vehicle: VehicleCard
  CarType: string
  DealerInfo: Dealer;
  Timestamp: string;
};

export type VehicleDetailWithTime = VehicleDetail & {
  FinanceInfo?: FinanceInfo[];
  Timestamp: string;
  DealerId: number;
};

export type DealerDetail = {
  id: number;
  lat: string;
  lng: string;
  name: string;
};

export type DealerDetailVehicles = DealerDetail & {
  data: VehicleDetailWithTime[];
};

export const getVehiclesDetail = async (vehiclesId: number) => {
  const vehiclePromise = VehicleServices.getVehicleDetail({ VehicleMasterId: vehiclesId });
  const dealerPromise = DealerServices.getDealerList({ VehicleMasterId: vehiclesId.toString() });
  const financialPromise = ProductDetailServices.getFinancialByProductId({ VehicleMasterId: vehiclesId });
  return Promise.all([vehiclePromise, dealerPromise, financialPromise]);
};

const sort = (financeInfo: FinancialInfoItem[]) => {
  return financeInfo
  .map((curr) => ({
    FinanceId: curr.FinanceID,
    FinanceName: curr.FinanceName,
    FinanceLogoURL: curr.LogoURL,
    MonthlyInstallment: _get(curr, `InstallmentOptions[0]['MonthlyInstallment']`, 0)
  }))
};

const findImage = (vehicleImages: VehicleImage[]) => {
  return vehicleImages.find((vehicleImage) => vehicleImage.Position.toLowerCase() === 'cover')
}

type WishlistStore = {
  isDelete: boolean;
  currentTab: string;
  isCheckAll: boolean;
  vehicles: VehicleDetailWithDealer[];
  currentFilter: string | undefined;
  vehiclesIdsDict: Record<number, boolean>;
  checked: Record<number, Record<number, number>>;
  reset: () => void;
  getTotal: () => number;
  clearChecked: () => void;
  removeVehicles: () => void;
  getVehiclesChecked: () => number;
  syncVehicles: () => Promise<void>;
  setDelete: (isDelete: boolean) => void;
  setCurrentTab: (value: string) => void;
  setCurrentFilter: (value: string) => void;
  getCheckedAll: (isCheckAll: boolean) => void;
  getVehiclesDict: () => Record<number, boolean>;
  setVehiclesIdsDict: (vehiclesId: number) => void;
  setVehicleByID: (vehicleId: number) => Promise<void>;
  setChecked: (dealerId: number, vehicleId: number) => void;
  setVehicles: (vehicles: VehicleDetailWithDealer[]) => void;
  sortBy: (vehicles: VehicleDetailWithDealer[], currentFilter?: string) => VehicleDetailWithDealer[];
};

const initialState = {
  currentFeilter: undefined,
  isCheckAll: false,
  isDelete: false,
  currentTab: "",
  blacklist: {},
  vehicles: [],
  vehiclesIdsDict: {},
};

export const useWishlist = create<WishlistStore>()(
  persist(
    (set, get) => ({
      checked: initialState.blacklist,
      isCheckAll: initialState.isCheckAll,
      isDelete: initialState.isDelete,
      currentTab: initialState.currentTab,
      currentFilter: initialState.currentFeilter,
      vehicles: initialState.vehicles,
      vehiclesIdsDict: initialState.vehiclesIdsDict,
      setDelete: (isDelete: boolean) => set({ isDelete }),
      removeVehicles: async () => {
        const { vehicles, checked } = get();

        const removeByIds: number[] = [];
        const removed = vehicles.filter((vehicle) => {
          const vehicleInDealer = _get(
            checked,
            `${vehicle?.DealerInfo?.DealerId}.${vehicle?.Vehicle?.VehicleMasterId}`,
            NaN,
          );
          if (vehicleInDealer) removeByIds.push(vehicleInDealer);
          return vehicleInDealer !== vehicle.Vehicle?.VehicleMasterId;
        });

        const isLogged = useAuthStore.getState().isLogged;
        if (isLogged) await wishlist.removeByIds(removeByIds);

        set({ vehicles: removed, checked: {}, isDelete: false });
      },
      getTotal: () => {
        const { vehicles, currentTab } = get();
        return vehicles.filter((vehicle) => {
          if (!currentTab) return true;
          return vehicle.CarType === currentTab;
        }).length;
      },
      getCheckedAll: (isCheckAll: boolean) => {
        const { vehicles, currentTab } = get();
        let checkAll = {};

        if (isCheckAll) {
          checkAll = vehicles
            .filter((vehicle) => {
              if (!currentTab) return true;
              return vehicle.CarType === currentTab;
            })
            .reduce<Record<number, Record<number, number>>>((acc, vehicle) => {
              const { DealerId } = vehicle.DealerInfo;
              const { Vehicle: { VehicleMasterId } } = vehicle;

              if (!acc[DealerId]) {
                acc[DealerId] = {};
              }

              acc[DealerId][VehicleMasterId] = VehicleMasterId;
              return acc;
            }, {});
        } else {
          checkAll = {};
        }

        set({ isCheckAll, checked: checkAll });
      },
      getVehiclesChecked: () => {
        const { checked } = get();
        return Object.values(checked).reduce((prev, curr) => {
          return (prev += Object.values(curr).length);
        }, 0);
      },
      getVehiclesDict: () => {
        const { vehicles } = get();
        return vehicles.reduce((prev, curr) => ({ ...prev, [curr.Vehicle?.VehicleMasterId]: true }), {});
      },
      clearChecked: () => {
        set({ checked: {} });
      },
      reset: () => set({ checked: {}, vehicles: [], vehiclesIdsDict: {} }),
      setChecked: (dealerId: number, vehicleId: number) => {
        const { checked } = get();
        const { vehicles } = get();
        const checkedWishlist = checked[dealerId];

        const vehicleInDealer = _get(checked, `${dealerId}.${vehicleId}`, NaN);

        if (checkedWishlist && vehicleId) {
          if (vehicleInDealer) {
            delete checked[dealerId][vehicleId];
          } else {
            checked[dealerId][vehicleId] = vehicleId;
          }
        } else if (dealerId && !vehicleId) {
          const vehiclesInDealer = vehicles
            .filter((vehicle) => vehicle.DealerInfo.DealerId === dealerId)
            .reduce(
              (prev, curr) => ({ ...prev, [curr.Vehicle?.VehicleMasterId]: curr.Vehicle?.VehicleMasterId }),
              {},
            );

          if (
            Object.values(checkedWishlist || {}).length === Object.values(vehiclesInDealer).length
          ) {
            delete checked[dealerId];
          } else {
            checked[dealerId] = vehiclesInDealer;
          }
        } else {
          checked[dealerId] = { [vehicleId]: vehicleId };
        }

        set({ checked });
      },
      setCurrentFilter: (value: string) => set({ currentFilter: value }),
      setCurrentTab: (value: string) => set({ currentTab: value }),
      setVehiclesIdsDict: (vehiclesId: number) => {
        const { vehiclesIdsDict } = get();
        if (!!vehiclesIdsDict[vehiclesId]) {
          delete vehiclesIdsDict[vehiclesId];
        } else {
          vehiclesIdsDict[vehiclesId] = true;
        }

        set({ vehiclesIdsDict: vehiclesIdsDict });
      },
      setVehicles: (_vehicles: VehicleDetailWithDealer[]) => {
        set({ vehicles: _vehicles });
      },
      setVehicleByID: async (vehicleId: number) => {
        const { vehicles } = get();

        const [_vehicle, dealer, financial] = await getVehiclesDetail(vehicleId);
        const dealerInfo = _get(dealer, `DealerInfo[0]`, null);
        if (!dealerInfo) return;

        const FinanceInfo = sort(financial.FinanceInfo || [])
        const imageCoverSize = findImage(_vehicle.ExternalImage || [])
        const vehicle: VehicleCard = {
          VehicleMasterId: _vehicle.VehicleMasterId,
          VehicleImageURL: imageCoverSize?.FileURL || _vehicle.VehicleClassImageURL || "",
          VehicleVideoURL: _vehicle.Video,
          Province: _vehicle.Province,
          Brand: _vehicle.Brand,
          Model: _vehicle.Brand,
          Grade: _vehicle.Grade,
          Gear: _vehicle.Transmission,
          EngineCapacity: +_vehicle.EngineCapacity || 0,
          PromotionPrice: _vehicle.PromotionPrice,
          ResellingPrice: _vehicle.ResellingPrice,
          PromotionPriceWithVAT: _vehicle.PromotionPriceWithVAT,
          ResellingPriceWithVAT: _vehicle.ResellingPriceWithVAT,
          Year: _vehicle.Year,
          Mileage: _vehicle.Mileage,
          AverageMileage: _vehicle.AverageMileage,
          ClassImageURL: _vehicle.VehicleClassImageURL,
          FinanceInfo
        }

        const vehiclePayload: VehicleDetailWithDealer = {
          CarType: _vehicle.CarType || "",
          Vehicle: vehicle,
          DealerInfo: dealerInfo,
          Timestamp: new Date().toISOString(),
        };

        const isLogged = useAuthStore.getState().isLogged;
        const existIndex = vehicles.findIndex((vehicle) => Number(vehicle?.Vehicle?.VehicleMasterId) === vehicleId);
        if (existIndex < 0) {
          if (isLogged) await wishlist.add(_vehicle.VehicleMasterId);
          vehicles.unshift(vehiclePayload)
          const _vehicles = vehicles
          set({ vehicles: _vehicles });
        } else {
          if (isLogged) await wishlist.remove(_vehicle.VehicleMasterId);
          const _vehicles = vehicles.filter((vehicle) => Number(vehicle?.Vehicle?.VehicleMasterId) !== vehicleId)
          set({ vehicles: _vehicles });
        }
      },
      syncVehicles: async () => {
        const isLogged = useAuthStore.getState().isLogged;
        if (!isLogged) return;

        const exist = await wishlist.get();
        const vehiclesIds = genericType<number[]>(exist.data?.vehicleIds || []);
        const _vehicles: VehicleDetailWithDealer[] = [];
        for (const vehiclesId of vehiclesIds) {
          const [_vehicle, dealer, financial] = await getVehiclesDetail(vehiclesId);
          const dealerInfo = _get(dealer, `DealerInfo[0]`, undefined);

          if (dealerInfo && _vehicle && financial) {

            const FinanceInfo = (financial?.FinanceInfo || []).map((_financial) => ({
              FinanceId: _financial.FinanceID,
              FinanceName: _financial.FinanceName,
              FinanceLogoURL: _financial.LogoURL,
              MonthlyInstallment: _get(_financial, `InstallmentOptions[0]['MonthlyInstallment']`, 0),
            }));

            const imageCoverSize = findImage(_vehicle.ExternalImage || [])
            const vehicle: VehicleCard = {
              VehicleMasterId: _vehicle.VehicleMasterId,
              VehicleImageURL: imageCoverSize?.FileURL || _vehicle.VehicleClassImageURL || "",
              VehicleVideoURL: _vehicle.Video || "",
              Province: _vehicle.Province,
              Brand: _vehicle.Brand,
              Model: _vehicle.Brand,
              Grade: _vehicle.Grade,
              Gear: _vehicle.Transmission,
              EngineCapacity: +_vehicle.EngineCapacity || 0,
              PromotionPrice: _vehicle.PromotionPrice,
              ResellingPrice: _vehicle.ResellingPrice,
              PromotionPriceWithVAT: _vehicle.PromotionPriceWithVAT || 0,
              ResellingPriceWithVAT: _vehicle.ResellingPriceWithVAT || 0,
              Year: _vehicle.Year || 0,
              Mileage: _vehicle.Mileage,
              AverageMileage: _vehicle.AverageMileage|| 0,
              ClassImageURL: _vehicle.VehicleClassImageURL || "",
              FinanceInfo: FinanceInfo || []
            }

            _vehicles.push({
              Vehicle: vehicle,
              DealerInfo: dealerInfo,
              CarType: _vehicle.CarType,
              Timestamp: dayjs().toISOString(),
            });
          }
        }

        set({ vehicles: _vehicles });
      },
      sortBy(vehicles: VehicleDetailWithDealer[], currentFilter?: string) {
        switch (currentFilter) {
          case "latest_added":
            return vehicles.sort(
              (prev, curr) => dayjs(curr.Timestamp).unix() - dayjs(prev.Timestamp).unix(),
            );
          case "new_car":
            return vehicles.sort((prev, curr) => (+curr.Vehicle?.Year || 0) - (+prev.Vehicle?.Year || 0));
          case "mile_low_to_hight":
            return vehicles.sort((prev, curr) => (+prev.Vehicle?.Mileage || 0) - (+curr.Vehicle?.Mileage || 0));
          case "mile_hight_to_low":
            return vehicles.sort((prev, curr) => (+curr.Vehicle?.Mileage || 0) - (+prev.Vehicle?.Mileage || 0));
          default:
            return vehicles;
        }
      },
    }),
    {
      name: "wishlist-storage",
      partialize: (state) => ({
        vehicles: state.vehicles,
        vehiclesIdsDict: state.vehiclesIdsDict,
      }),
    },
  ),
);
