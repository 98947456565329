"use client";
import React, { memo, useEffect, useMemo, useState } from "react";
import {
  AppBar,
  Backdrop,
  Box,
  Container,
  Menu,
  MenuItem,
  SxProps,
  Toolbar,
  Typography,
} from "@mui/material";
import Link from "next/link";
import dynamic from "next/dynamic";
import MenuIcon from "@mui/icons-material/Menu";

import { NavbarMenuType } from "./MenuItem";
import favHeart from "@/assets/image/car_favorited.webp";
import thailand from "@/assets/image/thailand.webp";
import english from "@/assets/image/english.webp";
import color from "@/styles/color";
import { NavbarMenuBtn, RedGradientBtn } from "@/styles/customStyle";
import { usePathname, useRouter } from "next/navigation";
import ExpandMore from "../ExpandMore/ExpandMore";
import { switchLocaleAction } from "@/libs/actions/switch-locale";
import { useTranslation } from "@/i18n/client";
import {
  NavbarAboutUsData,
  NavbarArticleData,
  NavbarWantToBuyData,
  TNavbarLinkItem,
  TSubNavbarLinkItem,
} from "@/libs/types/navbar.type";
import { useAuthenticationStore } from "@/contexts/useAuthentication";
import AuthenticationPopup from "@/modules/authentication/AuthenticationPopup";
import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";
import { useAuthStore } from "@/contexts/useAuth";
import DrawerNavbarCustom from "./components/DrawerNavbarCustom";
import MainMenuDrawer from "./components/MainMenuDrawer";
import { NavbarKeyType } from "@/libs/constant/navbar-item.constant";
import NavbarItemContainer from "./components/NavbarItemContainer";
import { VehicleSearchReponse } from "@/libs/types/vehicle.type";
import { authentication } from "@/libs/services/authentication/authentication.service";
import { PagePath } from "@/libs/constant/path";
import IconLoader from "../IconLoader";
import { NavbarQueryKeyEnum } from "@/libs/enum/nav-bar-query-key.enum";
import { useQuery } from "@tanstack/react-query";
import { ContentManagementListResponse } from "@/libs/types/content-management.type";
import { ContentManagementServices } from "@/libs/services/banners/banners.service";
import { ContentManagementQueryMap } from "@/libs/types/content-management-query.type";
import { ContentManagementQueryKeyEnum } from "@/libs/enum/content-management-query-key.enum";
import { VehicleServices } from "@/libs/services/vehicle";
import { useWishlist } from "@/contexts/useWishlist";
import { VehicleSearchSortByEnum } from "@/libs/enum/wtb-query-key.enum";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { useVehicleStore } from "@/contexts/useVehicle";

const pathHome = ["/", "/home"];
interface NavbarProps {
  navBarSx?: SxProps;
  toolBarSx?: SxProps;
}

// Dynamic import of the ToyotaLogo component
const ToyotaSureLogo = dynamic(() => import("@/components/ToyotaSureLogo"), { ssr: true });
const MemorizeToyotaSureLogo = memo(ToyotaSureLogo);

const WantToBuyNavbar = dynamic(() => import("./components/WantToBuyNavbar"), { ssr: false });
const ArticleNavbar = dynamic(() => import("./components/ArticleNavbar"), { ssr: false });
const AboutUsNavbar = dynamic(() => import("./components/AboutUsNavbar"), { ssr: false });

const Navbar = ({ navBarSx = {}, toolBarSx = {} }: NavbarProps) => {
  const { isLessThanSm, isLessThanMd } = useReadScreen();
  const { setShowAuthenticationPopup, setCurrentPage } = useAuthenticationStore();
  const { showFloatingHeader } = useVehicleStore();
  const { i18n, t } = useTranslation();

  const router = useRouter();
  const handleRefresh = () => {
    window.location.reload(); // Refresh the page
  };
  const pathname = usePathname();

  const { profile, isLogged, setLogged, setProfile } = useAuthStore();
  const { reset } = useWishlist();

  const isHomePage = useMemo(() => {
    return pathHome.includes(pathname);
  }, [pathname]);

  const [hasScroll, setHasScroll] = useState<boolean>(false);
  const [anchorElNav, setAnchorElNav] = useState<boolean>(false);

  const [anChorW2b, setAnchorW2b] = useState<null | HTMLElement>(null);
  const [anChorDoc, setAnchorDoc] = useState<null | HTMLElement>(null);
  const [anChorAboutUs, setAnchorAboutUs] = useState<null | HTMLElement>(null);
  const [drawerKey, setDrawerKey] = useState<NavbarKeyType | null>(null);

  const knowTipParams = new ContentManagementQueryMap();
  knowTipParams.set(ContentManagementQueryKeyEnum.KNOWTIP, {
    Length: 2000,
    Limit: 5,
    ImageType: isLessThanMd ? "Thumbnail Mobile" : "Thumbnail",
    SortBy: "UpdateDate",
    SortDirection: "DESC",
  });

  const cusNewsParams = new ContentManagementQueryMap();
  cusNewsParams.set(ContentManagementQueryKeyEnum.CUSTNEWS, {
    Length: 200,
    Limit: 5,
    ImageType: isLessThanMd ? "Thumbnail Mobile" : "Thumbnail",
    SortBy: "CreateDate",
    SortDirection: "DESC",
  });

  const promotionParams = new ContentManagementQueryMap();
  promotionParams.set(ContentManagementQueryKeyEnum.ALLPROM, {
    Length: 200,
    Limit: 5,
    ImageType: isLessThanMd ? "Thumbnail Mobile" : "Thumbnail",
    SortBy: "CreateDate",
    SortDirection: "DESC",
  });

  const faqParams = new ContentManagementQueryMap();
  faqParams.set(ContentManagementQueryKeyEnum.FAQ, {
    Limit: 4,
    Length: 1,
    SortBy: "CreateDate",
    SortDirection: "DESC",
  });

  const { data: resKnowtips } = useQuery<ContentManagementListResponse>({
    queryKey: [NavbarQueryKeyEnum.Knowtips, i18n.language],
    queryFn: () => ContentManagementServices.getContentManagement(knowTipParams),
    enabled: Boolean(!!anChorDoc || anchorElNav),
  });

  const { data: resNews } = useQuery<ContentManagementListResponse>({
    queryKey: [NavbarQueryKeyEnum.News, i18n.language],
    queryFn: () => ContentManagementServices.getContentManagement(cusNewsParams),
    enabled: Boolean(!!anChorDoc || anchorElNav),
  });

  const { data: resPromotions } = useQuery<ContentManagementListResponse>({
    queryKey: [NavbarQueryKeyEnum.Promotions, i18n.language],
    queryFn: () => ContentManagementServices.getContentManagement(promotionParams),
    enabled: Boolean(!!anChorDoc || anchorElNav),
  });

  const { data: resFaq } = useQuery<ContentManagementListResponse>({
    queryKey: [NavbarQueryKeyEnum.FAQ, i18n.language],
    queryFn: () => ContentManagementServices.getContentManagement(faqParams),
    enabled: Boolean(!!anChorAboutUs || anchorElNav),
  });

  const { data: resVehicles } = useQuery<VehicleSearchReponse>({
    queryKey: [NavbarQueryKeyEnum.Vehicle, i18n.language],
    queryFn: () =>
      VehicleServices.getVihicleSearch({
        Limit: 4,
        SortBy: VehicleSearchSortByEnum.NewArrival,
        SortDirection: "DESC" as SortDirection,
        StartIndex: 0,
        IsCertified: true,
      }),
    enabled: Boolean(!!anChorW2b || anchorElNav),
  });

  const knowTips = useMemo(() => {
    return resKnowtips ? resKnowtips : ({} as ContentManagementListResponse);
  }, [resKnowtips]);

  const customerNews = useMemo(() => {
    return resNews ? resNews : ({} as ContentManagementListResponse);
  }, [resNews]);

  const promotions = useMemo(() => {
    return resPromotions ? resPromotions : ({} as ContentManagementListResponse);
  }, [resPromotions]);

  const faqs = useMemo(() => {
    return resFaq ? resFaq : ({} as ContentManagementListResponse);
  }, [resFaq]);

  const tempFaq: TSubNavbarLinkItem[] = [];
  if (faqs.TotalCount > 0) {
    faqs.ContentManagement.map((data, index) => {
      tempFaq.push({
        id: index + 1,
        title: data.Title,
        link: `${PagePath.faqList}/${data.ContentId}`,
      });
    });
  }
  const dataLabelFaq: TNavbarLinkItem = {
    id: 2,
    title: "Navbar.about_us.faq.title",
    link: `${PagePath.faqList}`,
    subItem: tempFaq,
  };
  NavbarAboutUsData[1] = dataLabelFaq;

  const vehicles = useMemo(() => {
    return resVehicles ? resVehicles : ({} as VehicleSearchReponse);
  }, [resVehicles]);

  const handleOpenNavbarMenu = (event: React.MouseEvent<HTMLElement>, navType: NavbarMenuType) => {
    switch (navType) {
      case "w2b":
        setAnchorAboutUs(null);
        setAnchorDoc(null);
        setAnchorW2b(event.currentTarget);
        break;
      case "doc":
        setAnchorAboutUs(null);
        setAnchorW2b(null);
        setAnchorDoc(event.currentTarget);
        break;
      case "about":
        setAnchorDoc(null);
        setAnchorW2b(null);
        setAnchorAboutUs(event.currentTarget);
        break;
      default:
        return;
    }
  };

  const userProfile = useMemo(() => {
    if (!isLogged) {
      return <Typography>{t("Authen.login.signin-or-signup")}</Typography>;
    }

    return <Typography>{profile?.firstName}</Typography>;
  }, [profile, isLogged, t]);
  const handleClearNavbar = () => {
    setAnchorAboutUs(null);
    setAnchorDoc(null);
    setAnchorW2b(null);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);
  const openLang = Boolean(anchorElLang);

  const clickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const clickLanguage = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const clickLogin = () => {
    setShowAuthenticationPopup(true);
    setCurrentPage(AuthenticationPageEnum.Login);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElLang(null);
  };

  const wishlist = () => {
    if (!isLogged) {
      setAnchorEl(null);
      router.push(PagePath.wishListAnonymous);
      return;
    }

    router.push(PagePath.wishlistAuth);
  };

  const signOut = async () => {
    try {
      await authentication.signOut();
      reset();
    } catch (error) {
      console.error("signOut error => ", error);
    }
    setProfile(null);
    setLogged(false);
  };

  const onEdit = () => {
    setShowAuthenticationPopup(true);
    setCurrentPage(AuthenticationPageEnum.EditProfile);
  };

  const onChangePassword = () => {
    setShowAuthenticationPopup(true);
    setCurrentPage(AuthenticationPageEnum.ChangePassword);
  };

  const wishlistUrl = useMemo(() => {
    if (!isLogged) {
      return PagePath.wishListAnonymous;
    }

    return PagePath.wishlistAuth;
  }, [isLogged]);

  const drawerComponentMap: Record<NavbarKeyType, JSX.Element | null> = {
    car_interest: (
      <WantToBuyNavbar
        data={NavbarWantToBuyData}
        vehicles={vehicles}
        onCloseDrawer={() => {
          setDrawerKey(null);
          setAnchorElNav(false);
        }}
      />
    ),
    article: (
      <ArticleNavbar
        data={NavbarArticleData}
        customNewsDataContent={customerNews}
        knowTipDataContent={knowTips}
        promotionDataContent={promotions}
        onCloseDrawer={() => {
          setDrawerKey(null);
          setAnchorElNav(false);
        }}
      />
    ),
    about_us: (
      <AboutUsNavbar
        data={NavbarAboutUsData}
        onCloseDrawer={() => {
          setDrawerKey(null);
          setAnchorElNav(false);
        }}
      />
    ),
    dealer: null,
  };

  const isHoverMenu = useMemo(() => {
    return anChorAboutUs || anChorDoc || anChorW2b;
  }, [anChorAboutUs, anChorDoc, anChorW2b]);

  const handleScroll = () => {
    setHasScroll(window.scrollY >= 10);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AppBar
        position={"fixed"}
        sx={{
          pointerEvents: "auto",
          top: 0,
          backgroundColor: {
            xs:
              !isHomePage || isHoverMenu
                ? color.black100
                : hasScroll
                  ? color.black100
                  : color.black700,
          },
          transition: "all 0.2s ease-in-out",
          zIndex: (theme) => theme.zIndex.drawer + 10,
          height: { xs: "56px", sm: "100px" },
          transform: `translateY(${showFloatingHeader ? -100 : 0}%)`,
          ...navBarSx,
        }}
        onMouseLeave={() => handleClearNavbar()}
      >
        {!isLessThanMd && (
          <>
            {anChorW2b && (
              <NavbarItemContainer>
                <WantToBuyNavbar data={NavbarWantToBuyData} vehicles={vehicles} />
              </NavbarItemContainer>
            )}
            {anChorDoc && (
              <NavbarItemContainer>
                <ArticleNavbar
                  data={NavbarArticleData}
                  customNewsDataContent={customerNews}
                  knowTipDataContent={knowTips}
                  promotionDataContent={promotions}
                />
              </NavbarItemContainer>
            )}
            {anChorAboutUs && (
              <NavbarItemContainer>
                <AboutUsNavbar data={NavbarAboutUsData} />
              </NavbarItemContainer>
            )}
          </>
        )}
        <Container
          maxWidth="2xl"
          sx={{ paddingLeft: "0px", paddingRight: "0px", position: "relative" }}
        >
          <Toolbar
            disableGutters
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              // margin: {
              //   xs: "10px 24px 10px 24px",
              //   sm: "20px 40px 20px 40px",
              //   xl: "23px 64px 23px 64px",
              // },

              // margin: {
              //   xs: "10px 24px 10px 24px",
              //   sm: "20px 40px 20px 40px",
              //   md: "20px 40px 20px 40px",
              //   lg: "20px 40px 20px 40px",
              //   xl: "20px 64px 20px 64px",
              // },

              margin: {
                xs: "10px 24px 10px 24px",
                sm: "12px 40px 20px 40px",
                md: "12px 40px 20px 40px",
                lg: "12px 40px 20px 40px",
                xl: "12px 64px 20px 64px",
              },
              minHeight: { xs: "unset", sm: "64px", md: "64px" },
              ...(isHomePage
                ? {
                    // margin: {
                    //   xs: "10px 24px 10px 24px",
                    //   sm: "20px 40px 20px 40px",
                    //   md: "20px 40px 20px 40px",
                    //   lg: "20px 40px 20px 40px",
                    //   xl: "20px 64px 20px 64px",
                    // },
                    margin: {
                      xs: "10px 24px 10px 24px",
                      sm: "12px 40px 20px 40px",
                      md: "12px 40px 20px 40px",
                      lg: "12px 40px 20px 40px",
                      xl: "12px 64px 20px 64px",
                    },
                    minHeight: { xs: "unset", sm: "64px", md: "64px" },
                  }
                : {}),
              ...toolBarSx,
            }}
          >
            <Link href="/" rel="noopener noreferrer">
              <Box
                sx={{
                  position: "relative",
                  width: { xs: 60, sm: 130 },
                  height: { xs: 37, sm: 80 },
                  aspectRatio: {
                    xs: 60 / 37,
                    sm: 130 / 80,
                  },
                  img: {
                    aspectRatio: {
                      xs: 60 / 37,
                      sm: 130 / 80,
                    },
                  },
                }}
                onMouseOver={() => {
                  if (anChorDoc || anChorAboutUs || anChorW2b) {
                    handleClearNavbar();
                  }
                }}
              >
                <MemorizeToyotaSureLogo />
              </Box>
            </Link>

            <Box
              sx={{
                display: { xs: "none", xl: "flex" },
                gap: "40px",
              }}
            >
              <Box
                component={"div"}
                onMouseOver={(event) => handleOpenNavbarMenu(event, "w2b")}
                sx={{ padding: "12px", cursor: "pointer" }}
              >
                <NavbarMenuBtn onClick={(event) => handleOpenNavbarMenu(event, "w2b")}>
                  {t("Navbar.title.car_interest")}
                  {!!anChorW2b ? <ExpandMore direction="up" /> : <ExpandMore direction="down" />}
                </NavbarMenuBtn>
              </Box>
              <Box
                component={"div"}
                onMouseOver={handleClearNavbar}
                sx={{ padding: "12px", cursor: "pointer" }}
              >
                <NavbarMenuBtn onClick={() => router.push(`/dealerdetails/dealerlist`)}>
                  {t("Navbar.title.dealer")}
                </NavbarMenuBtn>
              </Box>

              <Box
                component={"div"}
                onMouseOver={(event) => handleOpenNavbarMenu(event, "doc")}
                sx={{ padding: "12px", cursor: "pointer" }}
              >
                <NavbarMenuBtn onClick={(event) => handleOpenNavbarMenu(event, "doc")}>
                  {t("Navbar.title.article")}
                  {!!anChorDoc ? <ExpandMore direction="up" /> : <ExpandMore direction="down" />}
                </NavbarMenuBtn>
              </Box>

              <Box
                component={"div"}
                onMouseOver={(event) => handleOpenNavbarMenu(event, "about")}
                sx={{ padding: "12px", cursor: "pointer" }}
              >
                <NavbarMenuBtn onClick={(event) => handleOpenNavbarMenu(event, "about")}>
                  {t("Navbar.title.about_us")}
                  {!!anChorAboutUs ? (
                    <ExpandMore direction="up" />
                  ) : (
                    <ExpandMore direction="down" />
                  )}
                </NavbarMenuBtn>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                {!isLessThanSm && (
                  <>
                    <Box
                      component={"img"}
                      src={i18n?.resolvedLanguage === "th" ? thailand.src : english.src}
                      alt="TH icon"
                      sx={{
                        display: { xs: "none", sm: "block" },
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                      onClick={clickLanguage}
                    />
                    <Menu
                      anchorEl={anchorElLang}
                      id="language-menu"
                      open={openLang}
                      onClose={handleClose}
                      onClick={handleClose}
                      slotProps={{
                        paper: {
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            borderRadius: "16px !important",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                            "& .MuiButtonBase-root": {
                              display: "flex",
                              gap: 2,
                              width: "226px",
                            },
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        divider
                        onClick={async () => {
                          await switchLocaleAction("th");
                          handleRefresh();
                        }}
                      >
                        <Typography>{t("Navbar.change_laguage.thai")}</Typography>
                      </MenuItem>
                      <MenuItem
                        divider
                        onClick={async () => {
                          await switchLocaleAction("en");
                          handleRefresh();
                        }}
                      >
                        <Typography>{t("Navbar.change_laguage.eng")}</Typography>
                      </MenuItem>
                    </Menu>
                    <Link href={wishlistUrl} rel="noopener noreferrer">
                      <Box
                        component={"img"}
                        src={favHeart.src}
                        alt="fav icon"
                        sx={{
                          display: { xs: "none", sm: "block" },
                          width: "40px",
                          height: "40px",
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                  </>
                )}

                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                  <Box sx={{ flexGrow: 0, display: { xs: "none", sm: "flex" } }}>
                    {isLogged ? (
                      <React.Fragment>
                        <RedGradientBtn
                          sx={{
                            display: { xs: "none", sm: "flex" },
                            width: "226px !important",
                            maxWidth: "100%",
                          }}
                          onClick={clickMenu}
                        >
                          <IconLoader iconName="UserIcon" />
                          {userProfile}
                        </RedGradientBtn>
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          slotProps={{
                            paper: {
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                borderRadius: "16px !important",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                                "& .MuiButtonBase-root .MuiTypography-root: hover": {
                                  color: color.red,
                                },
                                "& .MuiButtonBase-root": {
                                  display: "flex",
                                  gap: 2,
                                  width: "226px",
                                },
                              },
                            },
                          }}
                          transformOrigin={{ horizontal: "right", vertical: "top" }}
                          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                          <MenuItem divider onClick={onEdit}>
                            <IconLoader iconName="EditGradientIcon" />
                            <Typography>{t("Authen.login.edit-account")}</Typography>
                          </MenuItem>
                          <MenuItem
                            divider
                            onClick={onChangePassword}
                            style={{ ...(profile?.isSocialAccount && { display: "none" }) }}
                          >
                            <IconLoader iconName="PasswordIcon" />
                            <Typography>{t("Authen.login.reset-password")}</Typography>
                          </MenuItem>
                          <MenuItem onClick={signOut}>
                            <IconLoader iconName="SignOutIcon" />
                            <Typography>{t("Authen.login.sign-out")}</Typography>
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <RedGradientBtn
                          sx={{
                            display: { xs: "none", sm: "flex" },
                            width: "226px !important",
                            maxWidth: "100%",
                          }}
                          onClick={clickLogin}
                        >
                          <IconLoader iconName="UserIcon" />
                          {userProfile}
                        </RedGradientBtn>
                      </React.Fragment>
                    )}
                  </Box>
                </Box>

                <Box
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  sx={{
                    display: { xs: "flex", lg: "flex", xl: "none" },
                    justifyContent: "center",
                    alignItems: "center",
                    width: isHomePage ? "36px" : "40px",
                    height: isHomePage ? "36px" : "40px",
                    background: "transparent",
                    borderRadius: "40px",
                    cursor: "pointer",
                  }}
                  onClick={() => setAnchorElNav(!anchorElNav)}
                >
                  <MenuIcon sx={{ color: color.white, fontSize: "40px" }} />
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={Boolean(anChorAboutUs || anChorDoc || anChorW2b)}
        onClick={handleClearNavbar}
      ></Backdrop>
      {anchorElNav && (
        <DrawerNavbarCustom
          isOpen={anchorElNav}
          onCloseDrawer={() => setAnchorElNav(false)}
          isMain={true}
        >
          <MainMenuDrawer
            userProfile={userProfile}
            profile={profile}
            isLogged={isLogged}
            goWishlist={wishlist}
            onClickLogin={clickLogin}
            onClose={() => setAnchorElNav(false)}
            lang={i18n.language}
            onChangeLanguage={switchLocaleAction}
            onGoSecondaryDrawer={setDrawerKey}
            onClickLogout={signOut}
            onClickEditUser={onEdit}
            onClickResetPassword={onChangePassword}
          />
        </DrawerNavbarCustom>
      )}
      {drawerKey !== null && (
        <DrawerNavbarCustom
          isOpen={drawerKey !== null}
          onCloseDrawer={() => {
            setDrawerKey(null);
            setAnchorElNav(false);
          }}
          onClickBack={() => setDrawerKey(null)}
          isMain={false}
          title={drawerKey ? t(`Navbar.title.${drawerKey}`) : ""}
        >
          {drawerKey && drawerComponentMap[drawerKey]}
        </DrawerNavbarCustom>
      )}
      <AuthenticationPopup />
    </>
  );
};

export default Navbar;
