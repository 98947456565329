import { FC, useCallback, useMemo } from "react";
import { Box, SxProps, Typography } from "@mui/material";

import LineLogoImg from "@/assets/image/LINE_logo.webp";
import ChipCustom from "@/components/Chip/ChipCustom";
import ImageCustom from "@/components/Image/ImageCustom";
import ButtonCustom from "@/components/Button/ButtonCustom";
import IconLoader from "@/components/IconLoader";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { lineLink } from "@/libs/constant/dealer";
import color from "@/styles/color";
import { useTranslation } from "@/i18n/client";

type TDealerDetailLine = {
  lineId: string;
  containerSx?: SxProps;
  containerSxCopyBox?: SxProps;
  onCopy: (text: string) => void;
};

const DealerDetailLine: FC<TDealerDetailLine> = ({ lineId, containerSx, onCopy, containerSxCopyBox }) => {
  const { t } = useTranslation();
  const { isLessThanLg } = useReadScreen();

  const memoLineId = useMemo(() => {
    return lineId !== "" ? lineId : "-";
  }, [lineId]);

  const onAddLine = useCallback(() => {
    const urlLine = lineLink.concat(memoLineId);
    window.open(urlLine, "_blank");
  }, [memoLineId]);

  const onCopyLineId = useCallback(() => {
    onCopy(memoLineId);
  }, [memoLineId, onCopy]);

  return (
    <Box
      sx={{
        paddingLeft: 2,
        paddingRight: 2,
        height: { xs: "52px" },
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "100px",
        border: `1px solid ${color.gray700}`,
        width: "100%",
        gap: 2,
        ...containerSx,
      }}
    >
      <Typography
        component={"span"}
        sx={{
          fontWeight: {xs:400, sm: 600},
          fontSize: { xs: "12px", sm: "14px", xl: "16px" },
          lineHeight: { xs: "21px", xl: "24px" },
          color: color.gray400,
        }}
      >
        {memoLineId}
      </Typography>
      <Box
        component={"div"}
        sx={{
          flex: 1,
          display: "flex",
          gap: { xs: "12px", xl: "16px" },
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <ChipCustom
          icon={
            <ImageCustom
              imageSrc={LineLogoImg.src}
              imageAlt="Line Logo"
              sx={{
                img: {
                  borderRadius: "100%",
                  objectFit: "contain",
                  maxWidth: "unset",
                  width: {
                    xs: "20px !important",
                    xl: "24px !important",
                  },
                  height: {
                    xs: "20px !important",
                    xl: "24px !important",
                  },
                },
              }}
              options={{
                sizes: "10vw",
              }}
            />
          }
          containerStyles={{
            width: "40px",
            height: { xs: "39px", sm: "30px" },
            justifyContent: "center",
            border: `1px solid ${color.gray700}`,
            cursor: "pointer",
            gap: "4px",
            ...containerSxCopyBox,
          }}
          labelStyles={{
            color: color.black,
            textAlign: "center",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
          }}
          onClick={onAddLine}
        />

        {!isLessThanLg && (
          <ButtonCustom
            label={t("common.copy")}
            sx={{
              height: "36px",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "16px",
              "& .MuiButton-startIcon": {
                marginRight: { xs: "8px", sm: "4px", xl: "8px" },
                marginLeft: { xs: "-4px", sm: "0px", xl: "-4px" },
              },
              width: "fit-content",
              ...containerSxCopyBox,
            }}
            startIcon={
              <IconLoader
                iconName="CopyIcon"
                sx={{
                  fontSize: "24px",
                  color: color.white,
                }}
              />
            }
            onClick={onCopyLineId}
          />
        )}

        {isLessThanLg && (
          <ChipCustom
            icon={
              <Box
                onClick={onCopyLineId}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <IconLoader
                  iconName="CopyIcon"
                  sx={{
                    fontSize: { xs: "22px" },
                    color: color.white,
                    background: color.red,
                    borderRadius: "100%",
                  }}
                />
              </Box>
            }
            containerStyles={{
              width: "40px",
              height: { xs: "39px", sm: "30px" },
              justifyContent: "center",
              border: `1px solid ${color.gray700}`,
              cursor: "pointer",
              gap: "4px",
              ...containerSxCopyBox,
            }}
            labelStyles={{
              color: color.black,
              textAlign: "center",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
            }}
            onClick={onCopyLineId}
          />
        )}
      </Box>
    </Box>
  );
};

export default DealerDetailLine;
