import { FC, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { useTranslation } from "@/i18n/client";

import ButtonCustom from "@/components/Button/ButtonCustom";
import CheckboxCustom from "@/components/Checkbox/CheckboxCustom";
import RHFEmailField from "@/components/Form/RHFEmailField";
import RHFPasswordField from "@/components/Form/RHFPasswordField";
import { useAuthenticationStore } from "@/contexts/useAuthentication";
import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";
import { LoginFormSchema } from "@/libs/types/authentication.type";
import color from "@/styles/color";
import { ENV_CONFIG } from "@/libs";
import { Turnstile } from "@marsidev/react-turnstile";
import IconLoader from "@/components/IconLoader";

type LoginFormProps = {
  form: UseFormReturn<LoginFormSchema, any, undefined>;
};

const LoginForm: FC<LoginFormProps> = ({ form }) => {
  const { t } = useTranslation("", {
    keyPrefix: "Authen",
  });
  const { setValue } = useFormContext();
  const { setCurrentPage } = useAuthenticationStore();

  const [saveLogin, setSaveLogin] = useState<boolean>(false);

  return (
    <Stack sx={{ gap: 1 }}>
      <Stack sx={{ gap: {xs: 2, sm: 1} }}>
        <RHFEmailField
          name="email"
          label={t("form.email")}
          autoComplete="off"
          placeholder={t("placeholder.email")}
          labelSx={{
            fontSize: { xs: "12px", sm: "14px" },
            lineHeight: { xs: "18px", sm: "24px" },
          }}
          inputProps={{
            style: { padding: '12px 12px' },
          }}
          InputProps={{ 
            sx: {
              height: '46px !important',
              padding: '12px 12px',
              fontSize: "14px",
              "& input::placeholder": {
                fontSize: "14px"
              }
            },
            autoComplete: 'new-email'
          }}
          rules={{ required: true }}
        />
        <RHFPasswordField
          name="password"
          label={t("form.password")}
          autoComplete="off"
          placeholder={t("placeholder.password")}
          labelSx={{
            fontSize: { xs: "12px", sm: "14px" },
            lineHeight: { xs: "18px", sm: "24px" },
          }}
          inputProps={{
            style: { padding: '12px 12px' }
          }}
          InputProps={{ 
            sx: {
              height: '46px !important',
              padding: '12px 12px',
              fontSize: "14px",
              "& input::placeholder": {
                fontSize: "14px"
              },
            },
            autoComplete: 'new-password'
          }}
          rules={{ required: true }}
        />
        <Turnstile
          style={{ margin: "auto" }}
          siteKey={ENV_CONFIG.CAPTCHA_SITEKEY}
          options={{
            theme: "light",
          }}
          onSuccess={(token) => setValue("tokenCaptcha", token)}
        />
      </Stack>
      <Stack sx={{ alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
          onClick={() => setSaveLogin((state) => !state)}
        >
          <CheckboxCustom
            checked={saveLogin}
            sx={{
              color: color.gray400,
              width: "30px",
              height: "30px",
            }}
            iconSize="30px"
            icon={
              <IconLoader
                iconName="UncheckedIcon"
                sx={{ fontSize: "24px", color: color.gray400 }}
              />
            }
          />
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: { xs: "14px" },
              lineHeight: { xs: "21px" },
              color: color.gray400,
            }}
          >
            {t("login.save-login")}
          </Typography>
        </Box>
        <Typography
          onClick={() => setCurrentPage(AuthenticationPageEnum.ForgotPassword)}
          sx={{
            fontWeight: 500,
            fontSize: { xs: "14px" },
            lineHeight: { xs: "24px" },
            color: color.red,
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {t("login.forgot-password")}
        </Typography>
      </Stack>
      <ButtonCustom
        type="submit"
        disabled={!form.watch('tokenCaptcha')}
        label={t(`login.button-login`)}
        sx={{
          height: { xs: "40px" },
          fontSize: { xs: "14px", xl: "16px" },
          background: color.redGradient,
          color: color.white,
        }}
      />
    </Stack>
  );
};

export default LoginForm;
