import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Authen.errors.email-require")
    .matches(/^\S+$/, "Authen.errors.email-require")
    .matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, "Authen.errors.email-is-incorrect-pattern"),
  password: yup
    .string()
    .required("Authen.errors.password-require")
    .matches(/^\S+$/, "Authen.errors.password-require")
    .min(6, "Authen.errors.password-must-be-longer-6-length")
    .max(16, "Authen.errors.password-must-be-less-than-6-length"),
});
