import { FC } from "react";

import { InputAdornment, TextFieldProps } from "@mui/material";

import TextFieldInputCustom from "./TextFieldInputCustom";
import color from "@/styles/color";
import IconLoader from "../IconLoader";
import { _get } from "@/libs/utils/helper";

type InputEmailProps = TextFieldProps & {
  withIcon?: boolean;
};

const InputEmail: FC<InputEmailProps> = ({ placeholder, withIcon = true, ...rest }) => {
  return (
    <TextFieldInputCustom
      {...rest}
      placeholder={placeholder}
      InputProps={{
        type: "text",
        ...(withIcon && {
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                color: color.gray400,
              }}
            >
              <IconLoader iconName="UserIcon" />
            </InputAdornment>
          ),
        }),
        sx: {
          paddingLeft: "14px",
          ..._get(rest?.InputProps, "sx", {}),
        },
      }}
    />
  );
};

export default InputEmail;
