import ButtonCustom from "@/components/Button/ButtonCustom";
import RHFDateTimePickerField from "@/components/Form/RHFDateTimepicker";
import RHFPhoneNumberField from "@/components/Form/RHFPhoneNumberField";
import RHFTextField from "@/components/Form/RHFTextField";
import AcceptPrivacyPolicyCheckbox from "@/components/Policy/AcceptPrivacyPolicy";
import { useTranslation } from "@/i18n/client";
import { DropLeadSchema } from "@/libs/types/drop-lead.type";
import color from "@/styles/color";
import { buttonBaseClasses, Stack, Grid, Typography, SxProps } from "@mui/material";
import { FC } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";

export type DropleadSchema = {
  form: UseFormReturn<DropLeadSchema, any, undefined>;
  header?: number;
};

const DropLeadForm: FC<DropleadSchema> = ({ header }) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const isAccept = watch("isAccept");
  const buttonStyleSx: SxProps = {
    height: { xs: "40px", md: "64px" },
    fontSize: { xs: "0.875rem", md: "1.25rem" },
    lineHeight: { xs: "1.3125rem", md: "1.4375rem" },
    padding: 0,
    width: "100%",
    color: color.formField,
  };
  return (
    <Stack sx={{ gap: 3 }}>
      <Typography
        sx={{
          fontWeight: 500,
          lineHeight: { xs: "15px", sm: "18px", xl: "24.18px" },
          fontSize: { xs: "0.625rem", sm: "12px", xl: "1rem" },
          // display: { xs: "none", sm: "none", xl: "block" },
        }}
      >
        {t(`common.droplead.topic-${header}`)}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <RHFTextField
            name="customerName"
            label={t("common.droplead.form.full-name")}
            placeholder={t("common.droplead.placeholder.full-name")}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <RHFPhoneNumberField
            name="phoneNumber"
            label={t("common.droplead.form.phone-number")}
            placeholder={t("common.droplead.placeholder.phone-number")}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <RHFDateTimePickerField
            name="contactBack"
            label={t("common.droplead.form.date-time")}
            placeholder={t("common.droplead.placeholder.date-time")}
            rules={{ required: true }}
          />
        </Grid>
      </Grid>
      <AcceptPrivacyPolicyCheckbox
        checked={isAccept}
        onCheckedAcceptPolicy={() => setValue("isAccept", !isAccept)}
      />
      <ButtonCustom
        disabled={!isAccept}
        type="submit"
        label={t("common.droplead.submit-button")}
        sx={{
          ...buttonStyleSx,
          margin: "auto",
          height: { xs: "40px" },
          fontSize: { xs: "14px", xl: "16px" },
          width: "300px",
          background: color.redGradient,
          color: color.white,
          [`&.${buttonBaseClasses.disabled}`]: {
            color: color.formField,
            opacity: 0.4,
          },
        }}
      />
    </Stack>
  );
};

export default DropLeadForm;
