import { FC, useMemo } from "react";
import { SxProps, TextFieldProps } from "@mui/material";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";

import { useTranslation } from "@/i18n/client";
import TextFieldInputCustom from "../Input/TextFieldInputCustom";
import FormControlContainer from "./FormControlContainer";

export type RHFPhoneNumberFieldProps = TextFieldProps & {
  name: NonNullable<TextFieldProps["name"]>;
  label: string;
  rules?: ControllerProps["rules"];
  containerSx?: SxProps
  labelSx?: SxProps;
};

const RHFPhoneNumberField: FC<RHFPhoneNumberFieldProps> = ({ name, label, rules, value, labelSx, containerSx, ...rest }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const isRequired = useMemo(() => {
    return Boolean(rules?.required || false);
  }, [rules]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControlContainer labelSx={labelSx} containerSx={containerSx} label={label} isRequired={isRequired}>
          <TextFieldInputCustom
            {...field}
            {...rest}
            error={!!error}
            helperText={t(error?.message as string)}
            onChange={(e) => {
              const formattedValue = e.target.value
                .replace(/[^\d]/g, "")
                .replace(/(\d{3})(\d)/, "$1-$2")
                .replace(/(\d{3})(\d)/, "$1-$2")
                .slice(0, 12);
              field.onChange(formattedValue);
            }}
          />
        </FormControlContainer>
      )}
    />
  );
};

export default RHFPhoneNumberField;
