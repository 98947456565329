export const apiPaths = {
  content: {
    getContentData: "/TSure_API/rest/ContentManagement/GetContentData",
  },
  dealer: {
    getDealerData: "/TSure_API/rest/DealerInfo/GetDealerData",
  },
  finance: {
    getFinanceData: "/TSure_API/rest/FinanceInfo/GetFinanceData",
  },
  vehicle: {
    getVehicleData: "/TSure_API/rest/VehicleDetails/GetVehicleData",
    vehicleSearch: "/TSure_API/rest/SearchResult/Result?Debug=2",
  },
  selfInstance: {
    generateToken: "/auth/generate_token",
    refreshToken: "/auth/refresh_token",
  },
  tsure: {
    generateToken: "/TSure_API/rest/Auth/GenerateToken",
    refreshToken: "/TSure_API/rest/Auth/RefreshToken",
  },
  master: {
    filter: "/TSure_API/rest/SearchFilter/GetFilterData",
  },
  droplead: {
    contact: "TSure_API/rest/InterestedVehicle/SetInterestedVehicleData",
  },
  viewCount: "/TSure_API/rest/ViewCount/IncreaseViewCount",
};
