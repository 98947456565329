export enum WantToBuyQueryKeyEnum {
  WTB_CAR_PRODUCT_LIST = "want-to-buy",
  WTB_CAR_PROMOTION_BANNER_LIST = "wtb-car-promotion-banner-list",
  WTB_CAR_PRD_CONTENT = "want-car-prd-content",
  WTB_VEHICLE_SEARCH_RESULT = "wtb-vehicle-search-result",
  DEALER_FILTER_CAR = "dealer-filter-car",
  CAR_FILTER_PROMOTION = "car-filter-promotion",
  CAR_FILTER_CAR_TYPE = "car-filter-cartype",
  CAR_FILTER_CAR_BRAND = "car-filter-carbrand",
  CAR_FILTER_CAR_MODEL = "car-filter-carmodel",
  CAR_FILTER_GRADE = "car-filter-grade",
  CAR_FILTER_PROVINCE = "car-filter-province",
  CAR_FILTER_GEAR = "car-filter-gear",
  CAR_FILTER_FUEL_TYPE = "car-filter-fuel-type",
  CAR_FILTER_PICKUP_TYPE = "car-filter-pickup-type",
  CAR_FILTER_DEALER = "car-filter-dealer",
  CAR_FILTER_BRANCH = "car-filter-branch",
  CAR_FILTER_YEAR = "car-filter-year",
  CAR_FILTER_MILEAGE = "car-filter-mileage",
  CAR_FILTER_PRICE = "car-filter-price",
  CAR_FILTER_WHEEL_DRIVE = "car-filter-wheel-drive",
  CAR_FILTER_DOOR = "car-filter-door",
  CAR_FILTER_UNIQUE_SELL_POINT = "car-filter-unique-sell-point",
  CAR_FILTER_TAGS = "car-filter-tags",
  CAR_FILTER_CONTENT = "car-filter-content",
  CAR_FILTER_COLOR = "car-filter-color",
}

export enum VehicleSortEnum {
  NewArrival = "NewArrival",
  MostView = "MostView",
  MileageAsc = "MileageAsc",
  MileageDesc = "MileageDesc",
  YearAsc = "YearAsc",
  YearDesc = "YearDesc",
  PriceAsc = "PriceAsc",
  PriceDesc = "PriceDesc",
}

export enum VehicleSearchSortByEnum {
  NewArrival = "NewArrival",
  ViewCount = "ViewCount",
  Year = "Year",
  Mileage = "Mileage",
  Price = "Price",
  SpecialPrice = "SpecialPrice",
  NormalPrice = "NormalPrice",
  ResellingPriceWithVAT = "ResellingPriceWithVAT",
  PromotionPricewithVAT = "PromotionPriceWithVAT",
  Installment = "Installment",
  SuggestCars = "SuggestCars",
}
