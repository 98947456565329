import { RegisterFormSchema } from "@/libs/types/authentication.type";
import { create } from "zustand";

type SignupStore = {
  isLoading: boolean;
  signUp: RegisterFormSchema | null;
  isError: boolean;
  email: string | null;
  setSignUp: (userData: RegisterFormSchema) => void;
  setLoading: (isLoading: boolean) => void;
  setError: (isError: boolean) => void;
  setEmail: (email: string) => void;
};

const initialState = {
  isError: false,
  isLoading: false,
  signUp: null,
  email: null,
};

export const useSingUpStore = create<SignupStore>((set) => ({
  email: initialState.email,
  isError: initialState.isError,
  signUp: initialState.signUp,
  isLoading: initialState.isLoading,
  setError: (isError: boolean) => set({ isError }),
  setLoading: (isLoading: boolean) => set({ isLoading }),
  setSignUp: (signUpData: RegisterFormSchema) => set({ signUp: signUpData }),
  setEmail: (email: string) => set({ email }),
}));
