"use client";
import React from "react";
import { Backdrop, Fade, Stack } from "@mui/material";

import "./SpinnerDotLoader.scss";
import { usePageLoadingStore } from "@/contexts/usePageLoading";

const SpinnerDotLoader = () => {
  const { isLoading } = usePageLoadingStore();

  return (
    <Fade in={isLoading} unmountOnExit>
      <Backdrop open={isLoading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1001 }}>
        <Stack justifyContent="center" alignItems="center">
          <span className="spinner_dot_loader"></span>
        </Stack>
      </Backdrop>
    </Fade>
  );
};

export default SpinnerDotLoader;
