import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import RHFFormProvider from "@/libs/react-hook-form/FormProvider";
import ResetPasswordForm from "../forms/ResetPasswordForm";
import { ResetPasswordFormSchema } from "@/libs/types/authentication.type";
import { useEffect } from "react";
import { useSearchParams } from "next/navigation";
import { useSingUpStore } from "@/contexts/useAnonymous";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "../forms/schema/reset-password";
import { OnClickProp } from "../AuthenticationContent";
import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";

const ResetPassword = ({ onClick }: OnClickProp) => {
  const params = useSearchParams();
  const token = params.get("token");

  const { email } = useSingUpStore();

  const resetPasswordForm = useForm<ResetPasswordFormSchema>({
    resolver: yupResolver(resetPasswordSchema),
  });

  useEffect(() => {
    if (token && email) resetPasswordForm.reset({ email, token });
    else if (email) resetPasswordForm.reset({ email });
  }, [token, email]);

  const handleOnSubmit = resetPasswordForm.handleSubmit((values) =>
    onClick({ event: AuthenticationPageEnum.ResetPassword, body: values }),
  );

  return (
    <Stack sx={{ gap: 3 }}>
      <RHFFormProvider methods={resetPasswordForm} onSubmit={handleOnSubmit}>
        <ResetPasswordForm form={resetPasswordForm} />
      </RHFFormProvider>
    </Stack>
  );
};

export default ResetPassword;
