import { axiosServerInstance } from "@/libs/axios/axios-server-instance";

export const wishlist = {
  remove: async (vehicleId: number) => {
    return axiosServerInstance
      .delete("/api/v1/wishlis/" + vehicleId)
      .then((response) => response)
      .catch((err) => err);
  },
  add: async (vehicleId: number) => {
    return axiosServerInstance
      .post("/api/v1/wishlist/add", { vehicleId })
      .then((response) => response)
      .catch((err) => err);
  },
  clear: async () => {
    return axiosServerInstance
      .post("/api/v1/wishlist/clear")
      .then((response) => response)
      .catch((err) => err);
  },
  get: async () => {
    return axiosServerInstance
      .get("/api/v1/wishlist")
      .then((response) => response)
      .catch((err) => err);
  },
  removeByIds: async (vehiclesIds: number[]) => {
    return axiosServerInstance
      .post("/api/v1/wishlist/remove/list", { id: vehiclesIds })
      .then((response) => response)
      .catch((err) => err);
  },
};
