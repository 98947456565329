import React from "react";
import { Box, Drawer, DrawerProps, IconButton, Typography } from "@mui/material";
import color from "@/styles/color";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ImageCustom from "@/components/Image/ImageCustom";
import toyotaLogoWithText from "@/assets/image/toyota-sure-main-logo.webp";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

interface IDrawerNavbarCustomProps extends DrawerProps {
  isOpen: boolean;
  isMain: boolean;
  title?: string;
  onCloseDrawer: () => void;
  onClickBack?: () => void;
}

function DrawerNavbarCustom({
  isOpen,
  isMain,
  title,
  children,
  onCloseDrawer,
  onClickBack,
  ...rest
}: IDrawerNavbarCustomProps) {
  return (
    <Drawer
      {...rest}
      anchor={"right"}
      open={isOpen}
      onClose={onCloseDrawer}
      sx={{
        display: { xs: "flex", xl: "none" },
        "& .MuiDrawer-paper": {
          maxWidth: { xs: "100%", sm: "437px", lg: "437px" },
          minWidth: { xs: "100%", sm: "437px", lg: "437px" },
          width: "100%",
          backgroundColor: color.black100,
        },
        zIndex: (theme) => theme.zIndex.drawer + 20,
      }}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: color.black100,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: { xs: "56px", sm: "56px", md: "56px", lg: "56px" },
            display: "flex",
            backgroundColor: color.black100,
            padding: { xs: "16px" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {isMain ? (
            <ImageCustom
              imageSrc={toyotaLogoWithText.src}
              imageAlt="Toyota Sure แหล่งซื้อขาย รถมือสอง"
              options={{
                height: 120,
                width: 74,
                style: {
                  width: "100%",
                  height: "auto",
                },
                loading: undefined,
                priority: true,
                sizes: "15vw",
              }}
              sx={{
                width: 120,
                height: 74,
                maxWidth: { xs: 60 },
                maxHeight: { xs: 37 },
                cursor: "pointer",
                img: {
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                },
              }}
            />
          ) : (
            <>
              <div
                role="button"
                tabIndex={0}
                style={{ cursor: "pointer" }}
                onClick={onClickBack}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    onClickBack?.();
                  }
                }}
                aria-label="Go back"
              >
                <ArrowBackRoundedIcon sx={{ fontSize: "32px", color: color.white }} />
              </div>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  lineHeight: "28px",
                  width: "100%",
                  color: color.white,
                  textAlign: "center",
                }}
              >
                {title}
              </Typography>
            </>
          )}
          <IconButton onClick={onCloseDrawer}>
            <CloseRoundedIcon sx={{ fontSize: "28px", color: color.white }} />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Drawer>
  );
}

export default DrawerNavbarCustom;
