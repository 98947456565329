import { axiosDSureInstance } from "@/libs/axios/axios-dsure-instance";
import { FinancialInfo } from "@/libs/types/financial-car-product.type";
import { IFinancialByProductIdReq } from "./type";

export const ProductDetailServices = {
  getFinancialByProductId: async (params: IFinancialByProductIdReq) => {
    const res: FinancialInfo = await axiosDSureInstance
      .get("/TSure_API/rest/FinanceInfo/GetFinanceData", {
        params,
      })
      .then((response) => response.data);
    return res;
  },
};
