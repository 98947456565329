"use client";

import color from "@/styles/color";
import ButtonCustom from "@/components/Button/ButtonCustom";
import AboutUsContactImg from "@/assets/image/about-us-navbar.webp";
import { Box, Container, Stack, SxProps, Typography } from "@mui/material";
import ImageCustom from "../Image/ImageCustom";
import Link from "next/link";
import {
  FooterFilterDirectLinkData,
  FooterOtherData,
  FooterQuickLinkData,
  TNavbarLinkItem,
} from "@/libs/types/navbar.type";
import AccordionCustom from "../Accordion/AccordionCustom";
import IconLoader from "../IconLoader";
import FilterNameComponent from "../CarFilterSection/FilterNameComponent";
import { PagePath } from "@/libs/constant/path";
import { usePageLoadingStore } from "@/contexts/usePageLoading";
import { useTranslation } from "@/i18n/client";

interface IFooterMegaMenuProps {
  data: TNavbarLinkItem;
  containerSx?: SxProps;
  titleSx?: SxProps;
  subContainerSx?: SxProps;
  subTitleSx?: SxProps;
}

const Footer = () => {
  const setLoading = usePageLoadingStore().setLoading;
  const { t } = useTranslation();

  const FooterMegaMenu = ({
    data,
    containerSx = {},
    titleSx = {},
    subContainerSx = {},
    subTitleSx = {},
  }: IFooterMegaMenuProps) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "8px", xl: "24px" },
          ...containerSx,
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: "14px", xl: "16px" },
            lineHeight: { xs: "21px", sm: "28px" },
            color: color.white100,
            ...titleSx,
          }}
        >
          {t(data.title)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: { xs: "8px", sm: "16px" },
            ...subContainerSx,
          }}
        >
          {data?.subItem?.length > 0 &&
            data.subItem.map((item, index) => (
              <Typography
                key={`sub-${data.id}-footer-item-${index + 1}`}
                component={Link}
                onClick={() => {
                  if (item.link?.includes(PagePath.wantToBuy)) {
                    setLoading();
                  }
                }}
                href={`${!item.link?.includes(PagePath.wantToBuy) ? item.link : `${PagePath.wantToBuy}?filterlogid=${item.id}`}`}
                sx={{
                  fontWeight: 300,
                  fontSize: { xs: "10px", xl: "14px" },
                  lineHeight: { xs: "18px", sm: "24px" },
                  color: color.white,
                  cursor: "pointer",
                  "&:hover": {
                    textDecorationLine: "underline",
                  },
                  ...subTitleSx,
                }}
              >
                {t(item.title)}
              </Typography>
            ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      component={"footer"}
      sx={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "auto" }}
    >
      <Box
        sx={{
          background: color.whiteGradient100,
        }}
      >
        <Container
          maxWidth="2xl"
          sx={{
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
            position: "relative",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            spacing={3}
            sx={{
              padding: "40px 100px",
              display: { xs: "none", xl: "flex" },
            }}
          >
            {FooterFilterDirectLinkData.map((item, index) => (
              <FooterMegaMenu
                key={`filter-direct-desk-link-item-${index + 1}`}
                data={item}
                titleSx={{ color: color.gray400 }}
                subTitleSx={{ color: color.gray400 }}
              />
            ))}
          </Stack>
          <Box
            sx={{
              display: { xs: "flex", xl: "none" },
              flexDirection: "column",
              gap: { xs: "10px", sm: "16px" },
              padding: { xs: "40px 24px", sm: "24px 40px" },
            }}
          >
            {FooterFilterDirectLinkData.map((item, index) => (
              <AccordionCustom
                key={`filter-direct-mobile-link-item-${index + 1}`}
                summaryName={`${index + 1}_direct_car_filter_item`}
                expanded={false}
                summaryElement={
                  <FilterNameComponent
                    title={t(item.title)}
                    icon={<IconLoader iconName="CarIcon" sx={{ fontSize: "24px" }} />}
                    labelSx={{
                      fontSize: "12px",
                      lineHeight: "28px",
                    }}
                  />
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "4px",
                    padding: "10px 24px 0px 24px",
                  }}
                >
                  {item?.subItem?.length > 0 &&
                    item.subItem.map((subItem, index) => (
                      <Typography
                        key={`sub-${item.id}-footer-item-${index + 1}`}
                        component={Link}
                        onClick={() => {
                          if (item.link?.includes(PagePath.wantToBuy)) {
                            setLoading();
                          }
                        }}
                        href={`${!subItem.link?.includes(PagePath.wantToBuy) ? item.link : `${PagePath.wantToBuy}?filterlogid=${subItem.id}`}`}
                        sx={{
                          fontWeight: 300,
                          fontSize: "12px",
                          lineHeight: "24px",
                          color: color.gray400,
                          cursor: "pointer",
                          "&:hover": {
                            textDecorationLine: "underline",
                          },
                        }}
                      >
                        {t(subItem.title)}
                      </Typography>
                    ))}
                </Box>
              </AccordionCustom>
            ))}
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: color.black,
        }}
      >
        <Container
          maxWidth="2xl"
          sx={{
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
            position: "relative",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            spacing={{ xs: 0, sm: 1, lg: 3 }}
            sx={{
              padding: { xs: "24px 24px 48px", sm: "40px", xl: "40px 100px" },
              rowGap: { xs: "48px", sm: "unset" },
              columnGap: { xs: "15px", sm: "unset" },
            }}
            flexWrap={{ xs: "wrap", sm: "nowrap" }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "unset" },
                display: "flex",
                flexDirection: "column",
                gap: { xs: "16px", xl: "32px" },
                textAlign: { xs: "center", sm: "unset" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: "16px", xl: "18px" },
                  lineHeight: "27px",
                  color: color.white,
                }}
              >
                Customer Contact Center
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: { xs: "16px", xl: "24px" },
                  alignItems: { xs: "start", xl: "center" },
                  flexDirection: { xs: "column", xl: "row" },
                }}
              >
                <ImageCustom
                  imageSrc={AboutUsContactImg.src}
                  imageAlt="About Us ContactNavbar Image"
                  options={{
                    height: 277,
                    width: 185,
                    style: {
                      width: "100%",
                      height: "auto",
                    },
                  }}
                  sx={{
                    margin: "0 auto",
                    width: { xs: "240px", xl: "277px" },
                    height: { xs: "160px", xl: "185px" },
                    img: {
                      objectFit: "cover",
                      borderRadius: "16px",
                      width: "100%",
                      height: { xs: "160px !important", xl: "185px !important" },
                      aspectRatio: { xs: 240 / 160, xl: 277 / 185 },
                    },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: "16px", xl: "8px" },
                    width: { xs: "100%", xl: "fit-content" },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: color.white,
                    }}
                  >
                    {t("common.footer.customer-service-center")} <br /> {t("common.footer.24H")}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: color.white,
                      paddingTop: { xs: "0px", xl: "8px" },
                    }}
                  >
                    {t("common.footer.contact-via-email")}
                    <br />
                    <Link rel="noopener noreferrer" href={"mailto:contactcenter@toyota.co.th"}>
                      contactcenter@toyota.co.th
                    </Link>
                  </Typography>
                  <ButtonCustom
                    label={t("common.contact.button")}
                    component={"a"}
                    href="mailto:contactcenter@toyota.co.th"
                    sx={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                      height: { xs: "44px", xl: "40px" },
                      width: { xs: "100%" },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <FooterMegaMenu data={FooterQuickLinkData} />
            <FooterMegaMenu data={FooterOtherData} />
          </Stack>
        </Container>
      </Box>
      <Box
        sx={{
          height: {
            xs: "72px",
            xl: "64px",
          },
          background: color.redGradient,
        }}
      >
        <Container
          maxWidth="2xl"
          sx={{
            paddingLeft: "0px",
            paddingRight: "0px",
            padding: { xs: "24px 0px", xl: "16px 0px 24px 0px" },
            display: "flex",
            gap: "6px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconLoader iconName="CopyrightIcon" sx={{ fontSize: "20px" }} />
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: { xs: "14px", xl: "16px" },
              lineHeight: { xs: "24px", xl: "24px" },
              color: color.white,
            }}
          >
            Copyright Toyota 2024. All right reserved
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
