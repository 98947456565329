"use client";

import { SvgIcon, Toolbar } from "@mui/material";

import ButtonCustom from "@/components/Button/ButtonCustom";
import { useCompareCarStore } from "@/contexts/useCompareCar";
import dynamic from "next/dynamic";
import color from "@/styles/color";
import CompareCarSelectedDrawer from "./CompareCarSelectedDrawer";
import ModalCompareCarLimitError from "@/modules/compare-car/components/ModalCompareCarLimitError";
import { useTranslation } from "@/i18n/client";
const IconLoader = dynamic(() => import("@/components/IconLoader"), { ssr: false });
const FloatingCompareButton = () => {
  const { showFloatingButton, setShowDrawer } = useCompareCarStore();
  const { t } = useTranslation();

  return (
    <>
      <Toolbar
        component={"nav"}
        sx={{
          background: color.white90,
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: 1000,
          paddingY: 3,
          transition: "all .3s ease-out",
          transform: `translateY(${showFloatingButton ? 0 : 100}%)`,
        }}
        variant="dense"
        disableGutters
      >
        <ButtonCustom
          label={t("CompareCar.view_comparison_list")}
          sx={{
            opacity: 1,
            margin: "0 auto",
            maxWidth: { xs: "240px", md: "260px" },
            fontSize: { xs: "14px", md: "20px" },
            lineHeight: "23px",
            height: { md: "48px" },
          }}
          startIcon={
            <SvgIcon sx={{ fontSize: "24px", color: color.white }}>
              <IconLoader iconName="ExpandIcon" />
            </SvgIcon>
          }
          onClick={() => setShowDrawer(true)}
        />
      </Toolbar>
      <CompareCarSelectedDrawer />
      <ModalCompareCarLimitError />
    </>
  );
};

export default FloatingCompareButton;
