import { env } from "next-runtime-env";

const API_URL = env("NEXT_PUBLIC_API_URL") || process.env.NEXT_PUBLIC_API_URL || undefined;
const CLIENT_ID = env("NEXT_PUBLIC_CLIENT_ID") || process.env.CLIENT_ID || undefined;
const CLIENT_SECRET = env("NEXT_PUBLIC_CLIENT_SECRET") || process.env.CLIENT_SECRET || undefined;
const COOKIE_PASSWORD =
  env("NEXT_PUBLIC_COOKIE_PASSWORD") || process.env.NEXT_PUBLIC_COOKIE_PASSWORD || undefined;
const MAIN_URL = env("NEXT_PUBLIC_MAIN_URL") || process.env.NEXT_PUBLIC_MAIN_URL || undefined;
const MAP_API_KEY = env("NEXT_PUBLIC_MAP_API_KEY") || process.env.NEXT_PUBLIC_MAP_API_KEY || "";
const NODE_ENV = env("NEXT_PUBLIC_NODE_ENV") || process.env.NODE_ENV || "develop";
const CAPTCHA_SITEKEY =
  env("NEXT_PUBLIC_CAPTCHA_SITEKEY") || process.env.NEXT_PUBLIC_CAPTCHA_SITEKEY || "";
const CAPTCHA_SECRET =
  env("NEXT_PUBLIC_CAPTCHA_SECRET") || process.env.NEXT_PUBLIC_CAPTCHA_SECRET || "";
const CAPTCHA_VERIFY =
  env("NEXT_PUBLIC_CAPTCHA_VERIFY") || process.env.NEXT_PUBLIC_CAPTCHA_VERIFY || "";
const SOCIAL_AUTH = env("NEXT_PUBLIC_SOCIAL_AUTH") || process.env.NEXT_PUBLIC_SOCIAL_AUTH || "";

// TMP ENV
const IS_BUILD_TIME =
  env("NEXT_PUBLIC_IS_BUILD_TIME") || process.env.NEXT_PUBLIC_IS_BUILD_TIME || undefined;

//* Content ID
const USED_CAR_GUARANTEED_BY_TOYOTA_ID =
  env("NEXT_PUBLIC_USED_CAR_GUARANTEED_BY_TOYOTA_ID") ||
  process.env.NEXT_PUBLIC_USED_CAR_GUARANTEED_BY_TOYOTA_ID ||
  "";
const BEST_CAR_DEALS_OF_MONTH_ID =
  env("NEXT_PUBLIC_BEST_CAR_DEALS_OF_MONTH_ID") ||
  process.env.NEXT_PUBLIC_BEST_CAR_DEALS_OF_MONTH_ID ||
  "";
const LOW_MILEAGE_ANGLE_CAR_ID =
  env("NEXT_PUBLIC_LOW_MILEAGE_ANGLE_CAR_ID") ||
  process.env.NEXT_PUBLIC_LOW_MILEAGE_ANGLE_CAR_ID ||
  "";
const EXCLUSIVE_CAR_GOOD_CONDITION_ID =
  env("NEXT_PUBLIC_EXCLUSIVE_CAR_GOOD_CONDITION_ID") ||
  process.env.NEXT_PUBLIC_EXCLUSIVE_CAR_GOOD_CONDITION_ID ||
  "";
const GOOD_CONDITION_AND_GREAT_PRICE_ID =
  env("NEXT_PUBLIC_PICKUP_GOOD_CONDITION_AND_GREAT_PRICE_ID") ||
  process.env.NEXT_PUBLIC_PICKUP_GOOD_CONDITION_AND_GREAT_PRICE_ID ||
  "";
const GOOD_CAR_INSTALLMENT_LESS_8000_ID =
  env("NEXT_PUBLIC_GOOD_CAR_INSTALLMENT_LESS_8000_ID") ||
  process.env.NEXT_PUBLIC_GOOD_CAR_INSTALLMENT_LESS_8000_ID ||
  "";

const TOYOTA_SURE_CONTACT_US_ID =
  env("NEXT_PUBLIC_TOYOTA_SURE_CONTACT_US_ID") || process.env.NEXT_PUBLIC_TOYOTA_SURE_CONTACT_US_ID || "";

const USED_CAR_GUARANTEED_FROM_TSURE_ID =
  env("NEXT_PUBLIC_USED_CAR_GUARANTEED_FROM_TSURE_ID") ||
  process.env.NEXT_PUBLIC_USED_CAR_GUARANTEED_FROM_TSURE_ID ||
  "";
const ASSESSMENT_STANDARD_AND_QUALITY_ASSURANCE_ID =
  env("NEXT_PUBLIC_ASSESSMENT_STANDARD_AND_QUALITY_ASSURANCE_ID") ||
  process.env.NEXT_PUBLIC_ASSESSMENT_STANDARD_AND_QUALITY_ASSURANCE_ID ||
  "";
const COUPON_USAGE_DETAIL_QUALITY_ASSURANCE_ID =
  env("NEXT_PUBLIC_COUPON_USAGE_DETAIL_QUALITY_ASSURANCE_ID") ||
  process.env.NEXT_PUBLIC_COUPON_USAGE_DETAIL_QUALITY_ASSURANCE_ID ||
  "";
const POLICY_TERM_AND_CONDITION_ID =
  env("NEXT_PUBLIC_POLICY_TERM_AND_CONDITION_ID") ||
  process.env.NEXT_PUBLIC_POLICY_TERM_AND_CONDITION_ID ||
  "";
const API_URL_INTERNAL =
  env("NEXT_PUBLIC_API_URL_INTERNAL") || process.env.NEXT_PUBLIC_API_URL_INTERNAL || "";

//* Content ID SERVICE_TERM
const COOKIE_POLICY_TERM_CONDITION_ID =
  env("NEXT_PUBLIC_COOKIE_POLICY_TERM_CONDITION_ID") ||
  process.env.NEXT_PUBLIC_COOKIE_POLICY_TERM_CONDITION_ID ||
  "";
const PRIVACY_POLICY_TERM_CONDITION_ID =
  env("NEXT_PUBLIC_PRIVACY_POLICY_TERM_CONDITION_ID") ||
  process.env.NEXT_PUBLIC_PRIVACY_POLICY_TERM_CONDITION_ID ||
  "";

const SERVICE_POLICY_TERM_CONDITION_ID =
  env("NEXT_PUBLIC_SERVICE_POLICY_TERM_CONDITION_ID") ||
  process.env.NEXT_PUBLIC_SERVICE_POLICY_TERM_CONDITION_ID ||
  "";

//* FAQ Navbar
const HOW_MANY_TSURE_ID =
  env("NEXT_PUBLIC_HOW_MANY_TSURE_ID") ||
  process.env.NEXT_PUBLIC_HOW_MANY_TSURE_ID ||
  "";
const CONDITION_24_HR_ID =
  env("NEXT_PUBLIC_CONDITION_24_HR_ID") ||
  process.env.NEXT_PUBLIC_CONDITION_24_HR_ID ||
  "";
const TSURE_BENEFIT_ID =
  env("NEXT_PUBLIC_TSURE_BENEFIT_ID") ||
  process.env.NEXT_PUBLIC_TSURE_BENEFIT_ID ||
  "";

//* Google Analytics
const GA_ID = env("NEXT_PUBLIC_GA_ID") || process.env.NEXT_PUBLIC_GA_ID || "";

// Google Tag Manager
const GTM_ID = env("NEXT_PUBLIC_GTM") || process.env.NEXT_PUBLIC_GTM || "";

// Sentry
const SENTRY_DSN = env("NEXT_PUBLIC_SENTRY_DSN") || process.env.NEXT_PUBLIC_SENTRY_DSN || "";

export const ENV_CONFIG = {
  API_URL: API_URL,
  API_URL_INTERNAL: API_URL_INTERNAL,
  CLIENT_ID: CLIENT_ID,
  CLIENT_SECRET: CLIENT_SECRET,
  COOKIE_PASSWORD: COOKIE_PASSWORD,
  MAIN_URL: MAIN_URL,
  MAP_API_KEY: MAP_API_KEY,
  NODE_ENV: NODE_ENV,
  CAPTCHA_SITEKEY: CAPTCHA_SITEKEY,
  CAPTCHA_SECRET: CAPTCHA_SECRET,
  CAPTCHA_VERIFY: CAPTCHA_VERIFY,
  SOCIAL_AUTH: SOCIAL_AUTH,

  USED_CAR_GUARANTEED_BY_TOYOTA_ID,
  BEST_CAR_DEALS_OF_MONTH_ID,
  LOW_MILEAGE_ANGLE_CAR_ID,
  EXCLUSIVE_CAR_GOOD_CONDITION_ID,
  GOOD_CONDITION_AND_GREAT_PRICE_ID,
  GOOD_CAR_INSTALLMENT_LESS_8000_ID,
  TOYOTA_SURE_CONTACT_US_ID,
  USED_CAR_GUARANTEED_FROM_TSURE_ID,
  ASSESSMENT_STANDARD_AND_QUALITY_ASSURANCE_ID,
  COUPON_USAGE_DETAIL_QUALITY_ASSURANCE_ID,
  POLICY_TERM_AND_CONDITION_ID,

  HOW_MANY_TSURE_ID,
  CONDITION_24_HR_ID,
  TSURE_BENEFIT_ID,

  GA_ID,
  GTM_ID,

  IS_BUILD_TIME,

  SENTRY_DSN,
};

export const ENV_CONFIG_SERVICE_TERM = {
  COOKIE_POLICY_TERM_CONDITION_ID,
  PRIVACY_POLICY_TERM_CONDITION_ID,
  SERVICE_POLICY_TERM_CONDITION_ID,
};

export const ENV_SERVER_CONFIG = {
  CLIENT_ID: CLIENT_ID,
  CLIENT_SECRET: CLIENT_SECRET,
};
