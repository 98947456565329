export const cookieConsentDetail = `
<div><p style="text-align:center"><span style="font-size:18px"><strong>นโยบายการใช้คุกกี้</strong></span></p>

<p><strong>นโยบายการใช้คุกกี้ (Cookies Policy)</strong></p>

<p>เว็บไซต์นี้ให้บริการโดยบริษัท โตโยต้า มอเตอร์ ประเทศไทย จำกัด (“บริษัท”) เว็บไซต์นี้ใช้คุกกี้เพื่อจำแนกความแตกต่างของท่านออกจากผู้ใช้รายอื่น ๆ ในเว็บไซต์ของบริษัท โดยนำเสนอฟังก์ชันการทำงานบางอย่างของเว็บไซต์ให้แก่ผู้ใช้และช่วยให้บริษัทปรับปรุงเว็บไซต์ให้ดีขึ้น กรณีที่ท่านใช้งานเว็บไซต์นี้ต่อไป ถือว่าท่านให้การยอมรับนโยบายการใช้คุกกี้</p>

<p><strong>คุกกี้คืออะไร</strong></p>

<p>คุกกี้ คือ Text Files ที่อยู่ในคอมพิวเตอร์ของท่านที่ใช้เพื่อจัดเก็บรายละเอียดข้อมูล Log การใช้งาน Internet ของท่าน หรือ พฤติกรรมการเยี่ยมชม Website ของท่าน ท่านสามารถศึกษารายละเอียดเพิ่มเติมของคุกกี้ได้จาก https://www.allaboutcookies.org/</p>

<p><strong>บริษัทใช้ คุกกี้ อย่างไร?</strong></p>

<ol>
	<li>เพื่อศึกษาพฤติกรรมการใช้งาน Website ของท่าน เพื่อนำไปพัฒนาให้สามารถใช้งานได้ง่าย รวดเร็ว และมีประสิทธิภาพยิ่งขึ้น</li>
	<li>เพื่อทราบถึงรูปแบบการใช้และประวัติการใช้งานเว็บไซต์ ข้อมูลหรือบริการที่ท่านสนใจ และนำไปวิเคราะห์ เพื่อพัฒนาการให้บริการ แสดงเนื้อหา โฆษณา หรือประชาสัมพันธ์กิจกรรมที่เหมาะสมรวมถึงการบริการต่าง ๆ ที่ตรงกับความสนใจของท่าน เพื่อเพิ่มความพึงพอใจให้แก่ท่านได้มากยิ่งขึ้น</li>
	<li>เพื่อแสดงโฆษณาบนเว็บไซต์ของบริษัท หรือเพื่อจัดการโฆษณาของบริษัทบนเว็บไซต์อื่น คู่ค้าเว็บไซต์อื่นของบริษัทอาจใช้เทคโนโลยีอย่างคุกกี้เพื่อรวบรวมข้อมูลกิจกรรมของท่านในเว็บไซต์นี้และเว็บไซต์อื่น เพื่อแสดงโฆษณาตามกิจกรรมและความสนใจที่ท่านเรียกดูให้กับท่าน</li>
</ol>

<p><strong>ประเภทของ คุกกี้ ที่บริษัทใช้</strong></p>

<p>บริษัทใช้ คุกกี้ ดังต่อไปนี้ สำหรับ Website ของบริษัท</p>

<p><strong>คุกกี้ที่จำเป็น (Necessary Cookies)</strong></p>

<p>คุกกี้ประเภทนี้มีความสำคัญต่อการทำงานของเว็บไซต์ ซึ่งรวมถึงคุกกี้ที่ทำให้ท่านสามารถเข้าถึงข้อมูลและใช้งานในเว็บไซต์ของบริษัทได้อย่างปลอดภัย การจัดการเครือข่ายและการเข้าถึงข้อมูลและใช้งานในเว็บไซต์ของบริษัทได้ทุกส่วน</p>

<p><strong>คุกกี้เพื่อการเก็บข้อมูลที่ท่านเลือก (Preferences Cookies)</strong></p>

<p>คุกกี้ประเภทนี้ใช้ในการจดจำสิ่งที่ท่านชอบและเลือกไว้เมื่อท่านกลับมาใช้งานเว็บไซต์อีกครั้ง ซึ่งจะช่วยให้บริษัทสามารถดึงเนื้อหาหรือการใช้งานที่ท่านเลือกไว้มาแสดงได้ อาทิ ภาษา หรือภูมิภาค หรือขนาดของตัวอักษรที่ท่านเลือกใช้ในการใช้งานในเว็บไซต์</p>

<p><strong>คุกกี้สำหรับเก็บสถิติ (Statistics Cookies)</strong></p>

<p>คุกกี้ประเภทนี้จะช่วยให้บริษัทสามารถจดจำและนับจำนวนผู้เข้าเยี่ยมชมเว็บไซต์ตลอดจนช่วยให้บริษัททราบถึงพฤติกรรมในการเยี่ยมชมเว็บไซต์ เพื่อช่วยบริษัทปรับปรุงเว็บไซต์ของบริษัทโดยรวบรวมและรายงานข้อมูลเกี่ยวกับวิธีการใช้งานของท่าน และช่วยให้บริษัทเข้าใจถึงความสนใจของผู้ใช้ และวัดความมีประสิทธิผลของโฆษณาของบริษัท คุกกี้จะรวบรวมข้อมูลในลักษณะที่ไม่สามารถระบุตัวบุคคลได้โดยตรง</p>

<p><strong>คุกกี้เพื่อการตลาด (Marketing Cookies)</strong></p>

<p>คุกกี้ประเภทนี้ใช้เพื่อผลประโยชน์ทางการตลาด เช่น การส่งเสริมการขาย วิเคราะห์พฤติกรรมของท่านเพื่อให้บริษัทสามารถนำเสนอสินค้าและบริการต่าง ๆ ให้เหมาะสม</p>

<p><strong>การจัดการคุกกี้</strong></p>

<p>แม้ว่าอินเทอร์เน็ตเบราว์เซอร์ (Internet Browsers) ส่วนมากจะได้รับการตั้งไว้ให้ยอมรับ Cookies โดยอัตโนมัติก็ตาม แต่อินเทอร์เน็ตเบราว์เซอร์ (Internet Browsers) ส่วนใหญ่ก็จะอนุญาตให้ท่านเปลี่ยนแปลงการตั้งค่าเพื่อสกัดกั้น Cookies หรือเตือนให้ท่านทราบเมื่อมีการส่ง Cookies เข้ามาที่อุปกรณ์ของท่าน</p>

<p>นอกจากทางเลือกที่ระบุไว้ข้างต้นแล้ว ท่านอาจปฏิเสธ ยอมรับ หรือเอา Cookies จากไซต์ออกไปในเวลาใดก็ได้โดยการเปิดใช้งานหรือเข้าไปในการตั้งเบราว์เซอร์ (Browser) ของท่าน ทั้งนี้ข้อมูลเกี่ยวกับขั้นตอนที่จะต้องปฏิบัติตามเพื่อให้ Cookies ใช้งานได้ หรือเพื่อปิดการใช้งานของ Cookies หรือการเอา Cookies ออกไปนั้นท่านจะหาดูได้จากเว็บไซต์ของผู้ให้บริการอินเทอร์เน็ตเบราว์เซอร์ (Internet Browser) ของท่านโดยดูจาก Help Screen</p>

<p>ทั้งนี้ขอเรียนว่าหากท่านปิดการใช้งาน Cookies หรือเอา Cookies ออกไปนั้นคุณลักษณะของบางอย่างไซต์อาจจะไม่ทำงานตามวัตถุประสงค์ที่กำหนดไว้ เช่น ท่านอาจจะไม่สามารถเข้าเยี่ยมพื้นที่บางส่วนของไซต์หรือท่านอาจจะไม่ได้รับข้อมูลที่จัดให้เป็นการเฉพาะตัวเมื่อท่านเยี่ยมชมไซต์</p>

<ol>
	<li>หากท่านใช้อุปกรณ์ต่างกันในการเปิดดูและเข้ามายังไซต์ (เช่น คอมพิวเตอร์ สมาร์ตโฟน แท็บเล็ต) ท่านจะต้องดำเนินการเพื่อปรับแต่ละเบราว์เซอร์ (Browser) ในแต่ละอุปกรณ์นั้นเพื่อให้สอดคล้องกับ Cookie Preferences ของท่าน</li>
	<li>Chrome</li>
	<li>Firefox</li>
	<li>Internet Explorer</li>
	<li>Safari</li>
	<li>Safari for ios</li>
	<li>Chrome for android</li>
	<li>Chrome for ios</li>
</ol>

<p></p>

<h2><strong>Cookies Policy</strong></h2>

<p><strong>Cookies Policy</strong></p>

<p>This website is provided by Toyota Motor Thailand Company Limited (the "Company"). This website uses cookies to distinguish you from other users by providing certain website functionality to users and helping the company improve the website. If you continue to use this website you are agreeing to the Cookies Policy.</p>

<p><strong>What are cookies?</strong></p>

<p>Cookies are text files placed on your computer that are used to store detailed log information of internet usage, or website visiting behaviors. You can learn more details of Cookies at https://www.allaboutcookies.org/</p>

<p><strong>How does the Company use Cookies?</strong></p>

<ol>
	<li>To study the behavior of your website usage to further improve the ease of use, the speed of use , and more efficiency.</li>
	<li>To understand your usage patterns on the website, usage history of the website, and the information or services of your interest to analyze and develop the appropriate service of displaying advertising content, promoting activities, and including services that match your interests to even more increase your satisfaction.</li>
	<li>To display advertisements on the Company’s website or to manage the Company’s advertisements on other websites. Other Company’s partners’ websites may use such technology like Cookies to gather information about your activity on this website and other websites to provide you with advertisements based on activities and interests that you have browsed.</li>
</ol>

<p><strong>The type of Cookies the Company uses</strong></p>

<p>The Company uses the following Cookies for the Company's website;</p>

<p><strong>Necessary Cookies</strong></p>

<p>This type of Cookies is important for the operation of the website. This includes Cookies that enable you to securely access the company website and its information, network management, data and to access throughout the Company’s website entirely.</p>

<p><strong>Preferences Cookies</strong></p>

<p>This type of Cookies is used to remember your preference and what you have chosen when you come back to use the website again. This will allow the company to retrieve the content or usage you have customed to display, such as the language, region, or the font size that you have chosen to use on the website.</p>

<p><strong>Statistics Cookies</strong></p>

<p>This type of Cookies supports the Company to record the number of visitors on the website, as well as helping the Company to understand the visitor’s behavior. To help the Company improve the Company's website by collecting and reporting your usage details and help the Company to understand the user’s interest and measure the effectiveness of the company's advertising Cookies. The information is collected anonymously.</p>

<p><strong>Marketing Cookies</strong></p>

<p>This type of Cookies is used for marketing purposes such as sales promotions to analyze your behavior for the Company to appropriately present various products and services.</p>

<p><strong>Cookies Management</strong></p>

<p>Although most Internet Browsers are set to accept Cookies automatically, but most Internet Browsers will allow you to change your settings to block Cookies or alert you when Cookies are sent to your device.</p>

<p>In addition to the options specified above, you may refuse to accept or remove cookies from the site at any time by activating or accessing your browser. The information regarding the steps to enable Cookies, disable Cookies, or to remove Cookies can be found on the Internet Browser provider’s website using the Help Screen.</p>

<p>Please note that if you disable Cookies or remove Cookies, certain website features may not function as intended, for example you may not be able to visit certain areas of the website and may not receive information that is personally provided for you when visiting the website.</p>

<ol>
	<li>If you use different devices to view and access the website (such as computers, smartphones, tablets), you must modify the browsers on all the devices to match with your Cookies preferences.</li>
	<li>Chrome</li>
	<li>Firefox</li>
	<li>Internet Explorer</li>
	<li>Safari</li>
	<li>Safari for ios</li>
	<li>Chrome for android</li>
	<li>Chrome for ios</li>
</ol>
</div>
`;
