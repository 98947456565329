import { Box, Button, Stack, Typography } from "@mui/material";
import color from "@/styles/color";
import IconLoader from "@/components/IconLoader";

type ErrorProp = {
  onRetry: () => void;
  content: {
    title: string
    centent: string
    labelButton: string
  }
};
const MyError = ({ onRetry, content }: ErrorProp) => {
  return (
    <Box component={"div"} sx={{ width: "100%", padding: { sm: "30px" } }}>
      <Box
        component={"div"}
        sx={{
          width: "100%",
          bgcolor: color.white,
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "32px 24px",
          gap: 4,
        }}
      >
        <Stack gap={2} textAlign={"center"} alignItems={"center"}>
          <Box paddingY={"16px"}>
            <IconLoader
              iconName="ErrorCircleShadowIcon"
              sx={{ fontSize: { xs: "54px", xl: "76px" } }}
            />
          </Box>
          <Typography
            sx={{ fontSize: { md: "16px", lg: "24px" }, fontWeight: 600, lineHeight: "33.6px" }}
          >
            {content.title}
          </Typography>
          <Typography
            sx={{ fontSize: { md: "14px", lg: "20px" }, fontWeight: 400, lineHeight: "33.6px" }}
          >
            {content.centent}

          </Typography>
          <Button
            onClick={onRetry}
            sx={{
              width: { xs: "212px", lg: "327px" },
              height: "56px",
              borderRadius: "36px",
              padding: "24px",
              alignItems: "center",
              fontSize: { md: "16px", lg: "20px" },
              fontWeight: 600,
              lineHeight: "23px",
              display: "flex",
              color: color.white,
              justifyContent: "space-around",
              background: color.redGradient,
            }}
          >
            {content.labelButton}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default MyError;
