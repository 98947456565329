import { Box, ButtonBase } from "@mui/material";
import styled from "@emotion/styled";
import color from "./color";

export const NavbarMenuBtn = styled(ButtonBase)({
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  fontSize: "18px",
  fontWeight: 600,
});

export const RedGradientBtn = styled(ButtonBase)({
  width: "240px",
  height: "40px",
  color: color.white,
  display: "flex",
  gap: "10px",
  borderRadius: "8px",
  background: color.redGradient,
  fontSize: "18px",
  fontWeight: 600,
});

export const GreyBorderBtn = styled(ButtonBase)({
  width: "240px",
  height: "71px",
  color: color.black,
  border: `1px solid ${color.grey}`,
  borderRadius: "36px",
  background: color.white,
  fontSize: "18px",
  fontWeight: 600,
});

export const Pipe = styled(Box)({
  width: "1px",
  height: "43px",
  background: color.pipe,
  // transform: "rotate(90deg)"
});
