import { Box, BoxProps, SvgIcon } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import color from "@/styles/color";
import { FC } from "react";

type RemoveButtonProps = {
  onClick: () => void;
  sx?: BoxProps["sx"];
};

const RemoveButton: FC<RemoveButtonProps> = ({ onClick, sx }) => {
  return (
    <Box
      sx={{
        width: { xs: "24px", sm: "30px" },
        height: { xs: "24px", sm: "30px" },
        borderRadius: "100%",
        background: color.redGradient,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
      onClick={onClick}
      role="button"
      aria-label="remove"
    >
      <SvgIcon
        sx={{
          fontSize: { xs: "20px", sm: "24px" },
          color: color.white,
        }}
      >
        <CloseIcon />
      </SvgIcon>
    </Box>
  );
};

export default RemoveButton;
