"use client";

import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack, SvgIcon, SxProps, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "@/i18n/client";

import DrawerBottomCustom from "@/components/Drawer/DrawerBottomCustom";
import { DividerCustom } from "@/components/Divider/DividerCustom";
import ModalCloseOutBoxCustom from "@/components/Modal/ModalCloseOutBoxCustom";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import color from "@/styles/color";
import dynamic from "next/dynamic";
import TabCustom from "@/components/Tab/TabCustom";
import { useForm } from "react-hook-form";
import RHFFormProvider from "@/libs/react-hook-form/FormProvider";
import DropLeadForm from "./dealer-droplead-forms/DealerDropLead";
import { DropLeadSchema } from "@/libs/types/drop-lead.type";
import { yupResolver } from "@hookform/resolvers/yup";
import dropLeadValidationSchema from "./schema/droplead";
import { dropleadService } from "@/libs/services/droplead/droplead.service";
import dayjs from "dayjs";
import { useDealerStore } from "@/contexts/useDealer";
import IconLoader from "@/components/IconLoader";

import * as Sentry from "@sentry/nextjs";
import { useRateLimitStateStore } from "@/contexts/useDialogRateLimit";

const ProgressPopup = dynamic(() => import("@/modules/dealer/components/ProgressPopup"), {
  ssr: false,
});

const DropLeadPopup = () => {
  const { t } = useTranslation();
  const { isLessThanSm } = useReadScreen();
  const [tab, setTab] = useState(0);
  const [loading, setLoad] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const { vehicleId, isOpenDroplead, setVehicleId, setOpenDroplead } = useDealerStore();
  const { onOpenModal } = useRateLimitStateStore();

  const dropleadTabs: { id: number; title: string; icon: JSX.Element }[] = useMemo(() => {
    return [
      {
        id: 1,
        title: "common.droplead.tab-label-2",
        icon: (
          <SvgIcon sx={{ fontSize: { xs: "1.25rem", xl: "1.8rem" } }}>
            <IconLoader iconName="TelephoneIcon" />
          </SvgIcon>
        ),
      },
      {
        id: 2,
        title: "common.droplead.tab-label-1",
        icon: <IconLoader iconName="CarIcon" />,
      },
    ];
  }, []);

  const labelSx = useMemo(() => {
    return {
      fontWeight: { xs: 600, sm: 700 },
      fontSize: { xs: "16px", sm: "20px", lg: "24px" },
      lineHeight: { xs: "30px", sm: "34px" },
      background: color.redGradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    } as SxProps;
  }, []);

  const droplead = useForm<DropLeadSchema>({
    resolver: yupResolver(dropLeadValidationSchema),
  });

  const handleOnSubmit = droplead.handleSubmit(async (params) => {
    try {
      setOpenDroplead(false);
      setLoad(true);
      const { contactBack, contactType, customerName, phoneNumber, vehicleMasterId } = params;
      const phoneNumberChanging = phoneNumber.replace(/-/g, "");
      const payload = {
        ContactType: contactType,
        ContactBack: dayjs(contactBack).format("YYYY-MM-DD HH:mm:ss"),
        CustomerName: customerName,
        PhoneNumber: phoneNumberChanging,
        VehicleMasterId: +vehicleMasterId,
      };
      console.log("Drop-lead >>>>>>>>>>>>>", JSON.stringify(payload));
      // capture payload to Sentry
      const scope = new Sentry.Scope();
      scope.setTag("section", "droplead");
      Sentry.captureMessage(JSON.stringify(payload), scope);
      const response = await dropleadService.contact(payload);
      if (response.status == 429) {
        onOpenModal();
      }
    } catch (error) {
      setIsError(true);
      droplead.setValue("isAccept", false);
    } finally {
      setSuccess(true);
      setLoad(false);
      await new Promise((res) => setTimeout(() => res(setSuccess(false)), 2500));
    }
  });

  const handleClose = useCallback(() => {
    setOpenDroplead(false);
    setVehicleId(NaN);
    droplead.reset({});
  }, [droplead, setOpenDroplead, setVehicleId]);

  useEffect(() => {
    if (vehicleId) droplead.reset({ vehicleMasterId: vehicleId, contactType: "DealerContact" });
  }, [droplead, vehicleId]);

  useEffect(() => {
    return () => {
      setOpenDroplead(false);
      setIsError(false);
      setLoad(false);
      setVehicleId(NaN);
      droplead.reset({});
    };
  }, [droplead, setOpenDroplead, setVehicleId]);

  const DropLeadTab = (): JSX.Element => {
    return (
      <TabCustom
        activeState={tab}
        onChangeActive={(index) => {
          setTab(index);
          if (index === 0)
            droplead.reset({ vehicleMasterId: vehicleId, contactType: "DealerContact" });
          if (index === 1)
            droplead.reset({ vehicleMasterId: vehicleId, contactType: "Appointment" });
        }}
        name="droplead_service"
        options={{
          widthFitContent: true,
          tabContainerSx: {
            width: "100px",
          },
          tabActiveSx: {
            color: "white !Important",
            fontSize: { xs: "12px !important", xl: "18px !important" },
            "--color-stop-3": color.white,
            "--color-stop-4": color.white,
          },
        }}
        getIndexTab={() => {}}
        tabChildren={dropleadTabs.map((item, index) => (
          <Box
            key={`droplead_tab_item_${index + 1}`}
            component={"div"}
            sx={{ display: "flex", alignItems: "center", gap: { xs: "8px", xl: "10px" } }}
          >
            <Box sx={{ color: tab === 0 ? color.white : color.red }}>{item.icon}</Box>
            <Typography
              sx={{
                fontWeight: 500,
                lineHeight: "24px",
                fontSize: { xs: "0.75rem", sm: "1rem" },
                // display: { xs: "none", sm: "none", xl: "block" },
              }}
            >
              {t(item.title)}
            </Typography>
          </Box>
        ))}
        contentChildren={[]}
        singleContentChildren={
          <Box border={"1px solid #DEDEDE"} padding={"24px"} borderRadius={"0px 0px 16px 16px"}>
            <RHFFormProvider methods={droplead} onSubmit={handleOnSubmit}>
              <DropLeadForm form={droplead} header={tab + 1} />
            </RHFFormProvider>
          </Box>
        }
      />
    );
  };

  return (
    <>
      {Boolean(!isLessThanSm && isOpenDroplead) && (
        <ModalCloseOutBoxCustom
          name="droplead"
          open={Boolean(!isLessThanSm && isOpenDroplead)}
          onClose={handleClose}
          dialogProps={{
            sx: {
              "& .MuiPaper-root": {
                bgcolor: "transparent",
                margin: "8px",
                boxShadow: "unset",
                width: { sm: "610px", lg: "880px" },
                maxWidth: "100%",
              },
              "& .MuiModal-backdrop": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              },
              "& .MuiDialogContent-root": {
                overflow: "hidden",
              },
            },
          }}
        >
          <Box component={"div"} sx={{ width: "100%", padding: { sm: "30px" } }}>
            <Box
              component={"div"}
              sx={{
                width: "100%",
                bgcolor: color.white,
                borderRadius: "1rem",
                display: "flex",
                flexDirection: "column",
                padding: { sm: "32px 24px" },
                gap: 2,
              }}
            >
              <Stack gap={2}>
                <Toolbar
                  variant="dense"
                  sx={{
                    minHeight: 30,
                    justifyContent: "center",
                    paddingX: { xs: 2, sm: 0 },
                  }}
                  disableGutters
                >
                  <Typography sx={labelSx}>{t("common.droplead.header")}</Typography>
                </Toolbar>
                <DividerCustom sx={{ borderColor: color.gray170 }} />
                <DropLeadTab />
              </Stack>
            </Box>
          </Box>
        </ModalCloseOutBoxCustom>
      )}
      <ProgressPopup
        handleClose={handleClose}
        isError={isError}
        isLoading={loading}
        isSuccess={success}
      />

      {Boolean(isLessThanSm && isOpenDroplead) && (
        <DrawerBottomCustom
          labelName={t("common.droplead.header")}
          labelSx={labelSx}
          headerSx={{ paddingBottom: "16px" }}
          isOpen={Boolean(isLessThanSm && isOpenDroplead)}
          onCloseDrawer={handleClose}
          showBackButton={false}
        >
          {/* <DividerCustom sx={{ borderColor: color.gray170 }} /> */}
          <DropLeadTab />
        </DrawerBottomCustom>
      )}
    </>
  );
};

export default DropLeadPopup;
