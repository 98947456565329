import color from "@/styles/color";
import { Box, Container } from "@mui/material";
import React from "react";
import "./WantToBuyNavBar.scss";

interface INavbarItemContainerProps {
  children: React.ReactNode;
}

function NavbarItemContainer({ children }: INavbarItemContainerProps) {
  return (
    <Box
      component={"div"}
      sx={{
        display: { xs: "none", xl: "block" },
        position: "absolute",
        top: "55%",
        width: "100vw",
        zIndex: (theme) => theme.zIndex.drawer + 10,
      }}
    >
      <Box
        component={"div"}
        sx={{
          marginTop: "44px",
          // height: "calc(50vh + 56px)",
          background: color.black100,
          display: "flex",
          padding: "34px 0px 34px",
        }}
        className="animate__animated nbct_animate__navBarCustomFadeInDown animate__faster"
      >
        <Container
          maxWidth="2xl"
          sx={{ paddingLeft: "0px", paddingRight: "0px", position: "relative" }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  );
}

export default NavbarItemContainer;
