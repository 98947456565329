import { FC, useState } from "react";
import { IconButton, InputAdornment, TextFieldProps } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import TextFieldInputCustom from "./TextFieldInputCustom";
import color from "@/styles/color";
import IconLoader from "../IconLoader";
import { _get } from "@/libs/utils/helper";

type InputPasswordProps = TextFieldProps;

const InputPassword: FC<InputPasswordProps> = ({ placeholder, ...rest }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <TextFieldInputCustom
      {...rest}
      placeholder={placeholder}
      InputProps={{
        type: showPassword ? "text" : "password",
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              color: color.gray400,
            }}
          >
            <IconLoader iconName="LockIcon" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              marginLeft: 0,
              marginRight: 1,
            }}
          >
            <IconButton
              onClick={() => setShowPassword((show) => !show)}
              edge="end"
              sx={{
                marginRight: 0,
              }}
            >
              {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
            </IconButton>
          </InputAdornment>
        ),
        sx: {
          paddingLeft: "14px",
          ..._get(rest?.InputProps, "sx", {}),
        },
      }}
    />
  );
};

export default InputPassword;
