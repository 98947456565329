import { Drawer, Stack } from "@mui/material";

import { useCompareCarStore } from "@/contexts/useCompareCar";

import DrawerHeaderSection from "./components/DrawerHeader";
import DrawerFooterSection from "./components/DrawerFooter";
import DrawerBodySection from "./components/DrawerBody";

const CompareCarSelectedDrawer = () => {
  const { showDrawer, setShowDrawer } = useCompareCarStore();

  const container = typeof window === "undefined" ? () => window.document.body : undefined;

  const handleClose = () => {
    setShowDrawer(false);
  };

  return (
    <>
      {showDrawer && (
        <Drawer
          anchor="bottom"
          container={container}
          variant="temporary"
          open={showDrawer}
          onClose={handleClose}
          ModalProps={{
            keepMounted: false,
          }}
          PaperProps={{
            sx: {
              width: "100%",
              zIndex: `1300 !important`,
            },
            elevation: 0,
          }}
          sx={{
            zIndex: `1300 !important`,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { xs: "100%", sm: "100%", lg: "max-content" },
              margin: { sm: "24px auto" },
              borderRadius: { xs: "24px 24px 0px 0px", sm: "16px" },
              boxShadow: "0px 4px 16px 0px #00000033",
              padding: { xs: "24px 16px 64px 16px", sm: "24px 40px" },
            },
            "& .MuiModal-backdrop": {
              backgroundColor: { xs: "rgba(0, 0, 0, 0.32)", sm: "transparent" },
            },
          }}
        >
          <Stack gap={3}>
            <DrawerHeaderSection />
            <DrawerBodySection />
            <DrawerFooterSection />
          </Stack>
        </Drawer>
      )}
    </>
  );
};

export default CompareCarSelectedDrawer;
