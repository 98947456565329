import { PagePath } from "../constant/path";
import { ContentManagementQueryKeyEnum } from "../enum/content-management-query-key.enum";
import { ENV_CONFIG } from "../env";
import { ViewFilter } from "./want-to-buy.type";

export type TSubNavbarLinkItem = {
  id: number;
  title: string;
  link: string;
  filter?: Partial<ViewFilter>;
};

export type TNavbarLinkItem = {
  id: number;
  title: string;
  link?: string;
  subItem: TSubNavbarLinkItem[];
  referenceCode?: string;
  type?: string;
};

export const NavbarWantToBuyData: TNavbarLinkItem[] = [
  {
    id: 1,
    title: "Navbar.car_interest.car_recommend.title",
    subItem: [
      {
        id: 1,
        title: "Navbar.car_interest.car_recommend.used_car_toyota_certificate",
        link: `${PagePath.wantToBuy}?content=${ENV_CONFIG.USED_CAR_GUARANTEED_BY_TOYOTA_ID}`,
      },
      {
        id: 2,
        title: "Navbar.car_interest.car_recommend.car_deal_of_month",
        link: `${PagePath.wantToBuy}?content=${ENV_CONFIG.BEST_CAR_DEALS_OF_MONTH_ID}`,
      },
    ],
  },
  {
    id: 2,
    title: "Navbar.car_interest.car_for_you.title",
    subItem: [
      {
        id: 1,
        title: "Navbar.car_interest.car_for_you.car_angle",
        link: `${PagePath.wantToBuy}?content=${ENV_CONFIG.LOW_MILEAGE_ANGLE_CAR_ID}`,
      },
      {
        id: 2,
        title: "Navbar.car_interest.car_for_you.exclusive_car",
        link: `${PagePath.wantToBuy}?content=${ENV_CONFIG.EXCLUSIVE_CAR_GOOD_CONDITION_ID}`,
      },
      {
        id: 3,
        title: "Navbar.car_interest.car_for_you.pickup_great_price",
        link: `${PagePath.wantToBuy}?content=${ENV_CONFIG.GOOD_CONDITION_AND_GREAT_PRICE_ID}`,
      },
      {
        id: 4,
        title: "Navbar.car_interest.car_for_you.grate_car_less_than_8000",
        link: `${PagePath.wantToBuy}?content=${ENV_CONFIG.GOOD_CAR_INSTALLMENT_LESS_8000_ID}`,
      },
    ],
  },
  {
    id: 3,
    title: "Navbar.car_interest.car_your_budget.title",
    subItem: [
      {
        id: 1,
        title: "Navbar.car_interest.car_your_budget.less_than_350000",
        link: `${PagePath.wantToBuy}/allbrand/allmodel/allyear/allprovince/allcartype/1%20-%20350000`,
        filter: {
          Price: {
            Min: 1,
            Max: 350000,
          },
        },
      },
      {
        id: 2,
        title: "Navbar.car_interest.car_your_budget.between_350000_500000",
        link: `${PagePath.wantToBuy}/allbrand/allmodel/allyear/allprovince/allcartype/350000%20-%20500000`,
        filter: {
          Price: {
            Min: 350000,
            Max: 500000,
          },
        },
      },
      {
        id: 3,
        title: "Navbar.car_interest.car_your_budget.between_500000_700000",
        link: `${PagePath.wantToBuy}/allbrand/allmodel/allyear/allprovince/allcartype/500000%20-%20700000`,
        filter: {
          Price: {
            Min: 500000,
            Max: 700000,
          },
        },
      },
      {
        id: 4,
        title: "Navbar.car_interest.car_your_budget.greater_than_1million",
        link: `${PagePath.wantToBuy}/allbrand/allmodel/allyear/allprovince/allcartype/1000000%20-%209999999`,
        filter: {
          Price: {
            Min: 1000000,
            Max: 9999999,
          },
        },
      },
    ],
  },
];

export const NavbarArticleData: TNavbarLinkItem[] = [
  {
    id: 1,
    title: "Navbar.article.news",
    link: "/home/news",
    referenceCode: ContentManagementQueryKeyEnum.CUSTNEWS,
    subItem: [
      { id: 1, title: "อ็อคชั่น เอ็กซ์เพรส รีแบรนด์ พลิกโฉมระบบประมูลใหม่", link: "" },
      { id: 2, title: "โตโยต้าจับมือพันธมิตร มอบประสบการณ์ความสุข", link: "" },
      { id: 3, title: "โตโยต้าถนนสีขาว ร่วมกับ มูลนิธิศูนย์ข้อมูลจราจรอัจฉริยะไทย", link: "" },
      { id: 4, title: "โตโยต้า ครบรอบ 60 ปี ปลูกป่า 600,000 ต้น", link: "" },
      { id: 5, title: "Toyota Corolla Altis GR Sport ตอกย้ำคุณภาพ", link: "" },
    ],
  },
  {
    id: 2,
    title: "Navbar.article.know_tips",
    link: "/home/knowledge",
    referenceCode: ContentManagementQueryKeyEnum.KNOWTIP,
    subItem: [
      { id: 1, title: "ถอยรถใหม่ป้ายแดงวันแรก ต้องเช็กอะไรบ้างก่อนออกจากโชว์รูม", link: "" },
      { id: 2, title: "รถจอดทิ้งไว้นานต้องระวัง 6 ปัญหาที่พบบ่อย", link: "" },
      { id: 3, title: "อัปเดตกฎหมายเมาแล้วขับ 2567 มีโทษอย่างไรบ้าง", link: "" },
      { id: 4, title: "อะไหล่รถยนต์มีกี่ประเภท เลือกอย่างไรให้ได้คุณภาพ", link: "" },
      { id: 5, title: "แชร์เคล็ดลับปรับกระจกมองข้างอย่างไรให้ปลอดภัย", link: "" },
    ],
  },
  {
    id: 3,
    title: "Navbar.article.promotion",
    link: "/promotions/promotionlist",
    referenceCode: ContentManagementQueryKeyEnum.ALLPROM,
    subItem: [
      { id: 1, title: "Lorem ipsum dolor sit amet consectetur.", link: "" },
      { id: 2, title: "Lorem ipsum dolor sit amet consectetur.", link: "" },
      { id: 3, title: "Lorem ipsum dolor sit amet consectetur.", link: "" },
      { id: 4, title: "Lorem ipsum dolor sit amet consectetur.", link: "" },
      { id: 5, title: "Lorem ipsum dolor sit amet consectetur.", link: "" },
    ],
  },
];

export const NavbarAboutUsData: TNavbarLinkItem[] = [
  {
    id: 1,
    title: "Navbar.about_us.get_to_know_tsure.title",
    link: `${PagePath.aboutUsList}`,
    subItem: [
      {
        id: 1,
        title: "Navbar.about_us.get_to_know_tsure.car_certificate",
        link: `${PagePath.aboutUsList}/${ENV_CONFIG.USED_CAR_GUARANTEED_FROM_TSURE_ID}`,
      },
      {
        id: 2,
        title: "Navbar.about_us.get_to_know_tsure.coupon_and_quality_detail",
        link: `${PagePath.aboutUsList}/${ENV_CONFIG.COUPON_USAGE_DETAIL_QUALITY_ASSURANCE_ID}`,
      },
      {
        id: 3,
        title: "Navbar.about_us.get_to_know_tsure.standard_and_quality",
        link: `${PagePath.aboutUsList}/${ENV_CONFIG.ASSESSMENT_STANDARD_AND_QUALITY_ASSURANCE_ID}`,
      },
      {
        id: 4,
        title: "Navbar.about_us.get_to_know_tsure.contact_us",
        link: `${PagePath.aboutUsList}/${ENV_CONFIG.TOYOTA_SURE_CONTACT_US_ID}`,
      },
    ],
  },
  {
    id: 2,
    title: "Navbar.about_us.faq.title",
    link: `${PagePath.faqList}`,
    subItem: [
      // {
      //   id: 1,
      //   title: "Navbar.about_us.faq.how_many_tsure",
      //   link: `${PagePath.faqList}/${ENV_CONFIG.HOW_MANY_TSURE_ID}`,
      // },
      // {
      //   id: 3,
      //   title: "Navbar.about_us.faq.condition_24hr_service",
      //   link: `${PagePath.faqList}/${ENV_CONFIG.CONDITION_24_HR_ID}`,
      // },
      // {
      //   id: 4,
      //   title: "Navbar.about_us.faq.tsure_benefite",
      //   link: `${PagePath.faqList}/${ENV_CONFIG.TSURE_BENEFIT_ID}`,
      // },
    ],
  },
];

//* Footer Data
export const FooterQuickLinkData: TNavbarLinkItem = {
  id: 1,
  title: "Quick Link",
  subItem: [
    { id: 1, title: "common.footer.dealers", link: `${PagePath.dealerList}` },
    { id: 2, title: "common.footer.interested_in_buy", link: `${PagePath.wantToBuy}` },
    { id: 3, title: "common.footer.news", link: `${PagePath.articleNews}` },
    { id: 4, title: "common.footer.installment-cal", link: `${PagePath.financialCalculator}` },
    // { id: 5, title: "เคล็ดลับและข้อควรรู้", link: `${PagePath.articleKnowledge}` },
  ],
};

export const FooterOtherData: TNavbarLinkItem = {
  id: 2,
  title: "common.footer.others",
  subItem: [
    {
      id: 1,
      title: "common.footer.condition-evaluation-standards",
      link: `${PagePath.aboutUsList}/${ENV_CONFIG.ASSESSMENT_STANDARD_AND_QUALITY_ASSURANCE_ID}`,
    },
    {
      id: 2,
      title: "common.footer.certified-used-cars",
      link: `${PagePath.aboutUsList}/${ENV_CONFIG.USED_CAR_GUARANTEED_FROM_TSURE_ID}`,
    },
    {
      id: 3,
      title: "common.footer.policies-terms-and-conditions",
      link: `${PagePath.privacy_policy}`,
    },
    {
      id: 4,
      title: "common.footer.about_us",
      link: `${PagePath.aboutUsList}`,
    },
    {
      id: 5,
      title: "common.footer.faq",
      link: `${PagePath.faqList}`,
    },
  ],
};

export const FooterFilterDirectLinkData: TNavbarLinkItem[] = [
  {
    id: 1,
    title: "common.footer_choose_car_filter_1.select_used_cars_by_price",
    subItem: [
      { id: 563, title: "common.footer_choose_car_filter_1.used_cars_under_250_000_thb", link: `${PagePath.wantToBuy}` },
      { id: 564, title: "common.footer_choose_car_filter_1.used_cars_under_300_000_thb", link: `${PagePath.wantToBuy}` },
      { id: 565, title: "common.footer_choose_car_filter_1.used_cars_under_350_000_thb", link: `${PagePath.wantToBuy}` },
      { id: 566, title: "common.footer_choose_car_filter_1.used_cars_under_400_000_thb", link: `${PagePath.wantToBuy}` },
      { id: 567, title: "common.footer_choose_car_filter_1.used_cars_under_450_000_thb", link: `${PagePath.wantToBuy}` },
      { id: 568, title: "common.footer_choose_car_filter_1.used_cars_under_500_000_thb", link: `${PagePath.wantToBuy}` },
      { id: 849636, title: "common.footer_choose_car_filter_1.used_cars_under_750_000_thb", link: `${PagePath.wantToBuy}` },
      { id: 849637, title: "common.footer_choose_car_filter_1.used_cars_under_1_000_000_thb", link: `${PagePath.wantToBuy}` },
    ],
  },
  {
    id: 2,
    title: "common.footer_choose_car_filter_2.select_used_cars_by_type_and_price",
    subItem: [
      { id: 572, title: "common.footer_choose_car_filter_2.used_sedans_under_250_000_thb", link: `${PagePath.wantToBuy}` },
      { id: 849640, title: "common.footer_choose_car_filter_2.used_pickup_trucks_under_350_000_thb", link: `${PagePath.wantToBuy}` },
      { id: 574, title: "common.footer_choose_car_filter_2.used_vans_under_700_000_thb", link: `${PagePath.wantToBuy}` },
      {
        id: 610,
        title: "common.footer_choose_car_filter_2.used_mp_vs_su_vs_under_500_000_thb",
        link: `${PagePath.wantToBuy}`,
      },
      { id: 611, title: "common.footer_choose_car_filter_2.used_sedans_under_350_000_thb", link: `${PagePath.wantToBuy}` },
      { id: 612, title: "common.footer_choose_car_filter_2.used_pickup_trucks_under_450_000_thb", link: `${PagePath.wantToBuy}` },
      { id: 613, title: "common.footer_choose_car_filter_2.used_vans_under_800_000_thb", link: `${PagePath.wantToBuy}` },
    ],
  },

  {
    id: 3,
    title: "common.footer_choose_car_filter_3.select_by_popular_brands",
    subItem: [
      { id: 580, title: "common.footer_choose_car_filter_3.used_toyota_cars", link: `${PagePath.wantToBuy}` },
      { id: 615, title: "common.footer_choose_car_filter_3.used_honda_cars", link: `${PagePath.wantToBuy}` },
      { id: 581, title: "common.footer_choose_car_filter_3.used_mitsubishi_cars", link: `${PagePath.wantToBuy}` },
      { id: 582, title: "common.footer_choose_car_filter_3.used_nissan_cars", link: `${PagePath.wantToBuy}` },
      { id: 583, title: "common.footer_choose_car_filter_3.used_mazda_cars", link: `${PagePath.wantToBuy}` },
      { id: 584, title: "common.footer_choose_car_filter_3.used_isuzu_cars", link: `${PagePath.wantToBuy}` },
      { id: 616, title: "common.footer_choose_car_filter_3.used_ford_cars", link: `${PagePath.wantToBuy}` },
      { id: 849642, title: "common.footer_choose_car_filter_3.used_chevrolet_cars", link: `${PagePath.wantToBuy}` },
    ],
  },

  {
    id: 4,
    title: "common.footer_choose_car_filter_4.choose_by_popular_models",
    subItem: [
      { id: 585, title: "common.footer_choose_car_filter_4.used_altis_cars", link: `${PagePath.wantToBuy}` },
      { id: 586, title: "common.footer_choose_car_filter_4.used_yaris_cars", link: `${PagePath.wantToBuy}` },
      { id: 587, title: "common.footer_choose_car_filter_4.used_camry_cars", link: `${PagePath.wantToBuy}` },
      { id: 588, title: "common.footer_choose_car_filter_4.used_fortuner_cars", link: `${PagePath.wantToBuy}` },
      { id: 589, title: "common.footer_choose_car_filter_4.used_d_max_cars", link: `${PagePath.wantToBuy}` },
      { id: 590, title: "common.footer_choose_car_filter_4.used_corolla_cross_cars", link: `${PagePath.wantToBuy}` },
    ],
  },
];
