import { Box, Stack } from "@mui/material";
import Image from "next/image";

import FacebookLogo from "@/assets/image/facebook-logo.webp";
import GoogleLogo from "@/assets/image/google-logo.webp";
import { ENV_CONFIG } from "@/libs";

export const transformUrlSocialAuth = (provider: string) => {
  return `${ENV_CONFIG.SOCIAL_AUTH}/${provider}?current_uri=${window.location.pathname}${window.location.search}`;
};

const SocialLogin = () => {
  const socialList = [
    {
      key: "google",
      image: GoogleLogo.src,
      onClick: () => {
        window.location.href = transformUrlSocialAuth("google");
      },
    },
    {
      key: "facebook",
      image: FacebookLogo.src,
      onClick: () => {
        window.location.href = transformUrlSocialAuth("facebook");
      },
    },
  ];

  return (
    <Stack
      sx={{ alignItems: "center", flexDirection: "row", gap: "20px", justifyContent: "center" }}
    >
      {socialList.map((social) => {
        return (
          <Box key={`social-login-${social.key}`} sx={{ cursor: "pointer" }}>
            <Image alt="Social Image" src={social.image} width={40} height={40} onClick={social.onClick} />
          </Box>
        );
      })}
    </Stack>
  );
};

export default SocialLogin;
