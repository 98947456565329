import { FC, useMemo } from "react";
import { SxProps, TextFieldProps } from "@mui/material";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";

import { useTranslation } from "@/i18n/client";
import FormControlContainer from "./FormControlContainer";
import InputEmail from "../Input/InputEmail";
import color from "@/styles/color";

export type RHFEmailFieldProps = TextFieldProps & {
  name: NonNullable<TextFieldProps["name"]>;
  label: string;
  rules?: ControllerProps["rules"];
  withIcon?: boolean;
  disabled?: boolean;
  containerSx?: SxProps
  labelSx?: SxProps
};

const RHFEmailField: FC<RHFEmailFieldProps> = ({
  name,
  label,
  rules,
  withIcon,
  disabled,
  labelSx,
  containerSx,
  ...rest
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const isRequired = useMemo(() => {
    return Boolean(rules?.required || false);
  }, [rules]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, value, ...other }, fieldState: { error } }) => {
        return (
          <FormControlContainer label={label} labelSx={labelSx} containerSx={containerSx} isRequired={isRequired}>
            <InputEmail
              withIcon={withIcon}
              inputRef={ref}
              error={!!error}
              value={(value as unknown) ?? ""}
              {...other}
              {...rest}
              disabled={disabled}
              helperText={t(error?.message as string)}
              sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                  color: `${color.black} !important`,
                  bgcolor: `#D1D1D1 !important`,
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  bgcolor: `unset !important`,
                  color: "#d32f2f !important",
                },
                "& .Mui-disabled": {
                  "-webkit-text-fill-color": "none !important"
                }
              }}
            />
          </FormControlContainer>
        );
      }}
    />
  );
};

export default RHFEmailField;
