"use client";
import color from "@/styles/color";
import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface IButtonOutlinedCustomProps extends ButtonProps {
  label: string;
}

function ButtonOutlinedCustom({ label, ...rest }: IButtonOutlinedCustomProps) {
  return (
    <Button
      variant="outlined"
      {...rest}
      sx={{
        width: "100%",
        height: "56px",
        borderRadius: "100px",
        padding: "24px 48px",
        fontWeight: 400,
        fontSize: { xs: "15px", sm: "16px", xl: "20px" },
        lineHeight: { xs: "24px" },
        color: color.gray400,
        border: `1px solid ${color.gray900}`,
        boxShadow: "unset",
        ":hover": {
          boxShadow: "unset",
          opacity: 1,
          backgroundColor: "transparent",
          border: `1px solid ${color.gray900}`,
        },
        ...rest?.sx,
      }}
    >
      {label}
    </Button>
  );
}

export default ButtonOutlinedCustom;
