import ButtonCustom from "@/components/Button/ButtonCustom";
import RHFEmailField from "@/components/Form/RHFEmailField";
import RHFPasswordField from "@/components/Form/RHFPasswordField";
import { useTranslation } from "@/i18n/client";
import { ResetPasswordFormSchema } from "@/libs/types/authentication.type";
import color from "@/styles/color";
import { Grid, Stack } from "@mui/material";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";

type ResetPasswordForm = {
  form: UseFormReturn<ResetPasswordFormSchema, any, undefined>;
};

const ResetPasswordForm: FC<ResetPasswordForm> = () => {
  const { t } = useTranslation("", {
    keyPrefix: "Authen",
  });

  return (
    <Stack sx={{ gap: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <RHFEmailField
            withIcon
            disabled
            name="email"
            label={t("form.email")}
            autoComplete="new-email"
            placeholder={t("placeholder.email")}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <RHFPasswordField
            name="password"
            label={t("form.password")}
            placeholder={t("placeholder.password")}
            autoComplete="new-password"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <RHFPasswordField
            name="confirmPassword"
            label={t("form.confirm-password")}
            autoComplete="new-confirm-password"
            placeholder={t("placeholder.confirm-password")}
            rules={{ required: true }}
          />
        </Grid>
      </Grid>
      <ButtonCustom
        type="submit"
        label={t(`reset-password.button-reset`)}
        sx={{
          height: { xs: "40px" },
          fontSize: { xs: "14px", xl: "16px" },
          background: color.redGradient,
          color: color.white,
        }}
      />
    </Stack>
  );
};

export default ResetPasswordForm;
