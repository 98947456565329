"use client";

import React, { memo, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const WithLazy = ({
  children,
  style,
  delay,
  getRender,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  delay?: number;
  getRender?: (isReady: boolean) => void;
}) => {
  const [isReady, setIsReady] = useState(false);
  const { ref, inView = false } = useInView({ delay: delay || 0, threshold: 0.9 });

  useEffect(() => {
    if (!isReady && inView) {
      setIsReady(true);
      if (typeof getRender === "function") {
        getRender && getRender(true);
      }
    }
  }, [inView, isReady]);

  return (
    <div ref={ref} style={{ width: "100%", ...style }}>
      {isReady ? children : <></>}
    </div>
  );
};

export default memo(WithLazy);
