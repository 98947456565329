"use Client";

import { DividerCustom } from "@/components/Divider/DividerCustom";
import color from "@/styles/color";
import { Box, IconButton, Typography } from "@mui/material";
import ButtonCustom from "@/components/Button/ButtonCustom";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { DealerMobile, TSocialKey } from "@/contexts/useDealer";
import { useCallback } from "react";
import Link from "next/link";
import IconLoader from "@/components/IconLoader";
import { getFacebookFanpageByID, toFormatMobile } from "@/libs/utils/function";
import { useTranslation } from "@/i18n/client";

interface IDealerSocialContactModalContentProps {
  from: TSocialKey;
  title: string;
  contact: DealerMobile[];
  isMobile: boolean;
  showPhoneNumber: boolean;
  facebookID: string
}

function DealerDetailSocialContactModalContent({
  contact,
  title,
  isMobile,
  facebookID,
  showPhoneNumber,
}: IDealerSocialContactModalContentProps) {
  const { t } = useTranslation()
  const { isLessThanLg } = useReadScreen();

  const onCopy = useCallback(async (mobile: string = "-") => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(mobile);
    }
  }, []);

  return (
    <Box component={"div"} sx={{ width: "100%", padding: { xs: "24px 0px", sm: "30px" } }}>
      <Box
        component={"div"}
        sx={{
          width: "100%",
          bgcolor: color.white,
          borderRadius: "16px",
          padding: { xs: "0px", sm: "32px 24px" },
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          component={"h3"}
          sx={{
            display: { xs: "none", sm: "block" },
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "34px",
            background: color.redGradient,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        <DividerCustom
          sx={{
            display: { xs: "none", sm: "block" },
            borderColor: color.gray170,
          }}
        />
        {showPhoneNumber && (
          <Box
            component={"div"}
            sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
          >
            {contact.map((item, index) => (
              <Box
                component={"div"}
                key={`dealer_social_item_${index}`}
                sx={{
                  width: "100%",
                  height: { xs: "56px", sm: "60px" },
                  borderRadius: { xs: "8px", sm: "16px" },
                  border: `1px solid ${color.gray240}`,
                  padding: "8px 16px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "14px", sm: "16px" },
                    lineHeight: { xs: "21px", sm: "24px" },
                    color: color.gray400,
                    width: { xs: "100%", sm: "70%" },
                  }}
                >
                  {item.name || "-"}
                </Typography>

                <Typography
                  component={"span"}
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "14px", sm: "16px" },
                    lineHeight: { xs: "21px", sm: "24px" },
                    color: color.gray400,
                    width: { xs: "100%", sm: "90%" },
                  }}
                >
                  {toFormatMobile(item.mobile || "-")}
                </Typography>
                <Box
                  component={"div"}
                  sx={{
                    display: "flex",
                    gap: { xs: "8px", xl: "24px" },
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  {isLessThanLg ? (
                    <Link href={"tel:" + item.mobile} scroll={false} rel="noopener noreferrer">
                      <IconButton
                        sx={{ width: "40px", height: "40px", background: color.redGradient }}
                      >
                        <IconLoader
                          iconName="TelephoneIcon"
                          sx={{
                            fontSize: "20px",
                            color: color.white,
                          }}
                        />
                      </IconButton>
                    </Link>
                  ) : (
                    <ButtonCustom
                      label={t('common.copy')}
                      sx={{
                        width: "7.31rem",
                        height: "44px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        padding: "16px",
                        "& .MuiButton-startIcon": {
                          marginRight: { xs: "8px", sm: "4px", xl: "8px" },
                          marginLeft: { xs: "-4px", sm: "0px", xl: "-4px" },
                        },
                      }}
                      startIcon={
                        <IconLoader
                          iconName="CopyIcon"
                          sx={{
                            fontSize: "24px",
                            color: color.white,
                          }}
                        />
                      }
                      onClick={async () => onCopy(item.mobile || "-")}
                    />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <iframe
            title="Facebook Page"
            src={getFacebookFanpageByID(facebookID || "", isMobile)}
            style={{
              border: "none",
              overflow: "hidden",
              width: isMobile ? "300px" : "500px",
              height: "130px",
            }}
            scrolling="no"
            frameBorder="0"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
}

export default DealerDetailSocialContactModalContent;
