"use client";

import ModalCloseOutBoxCustom from "@/components/Modal/ModalCloseOutBoxCustom";
import { useRateLimitStateStore } from "@/contexts/useDialogRateLimit";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { useTranslation } from "@/i18n/client";
import MyError from "@/modules/authentication/components/pages/Error";

const RateLimitPopup = () => {
  const { t } = useTranslation();
  const { isMoreThanLg } = useReadScreen();
  const {isOpenModalRateLimit, onCloseModal} = useRateLimitStateStore();

  return (
    <>
      <ModalCloseOutBoxCustom
        name="error-Rate-Limit"
        open={isOpenModalRateLimit}
        onClose={onCloseModal}
        showCloseIcon={isMoreThanLg}
        dialogProps={{
          sx: {
            "& .MuiPaper-root": {
              bgcolor: "transparent",
              margin: "8px",
              boxShadow: "unset",
              width: { sm: "610px", lg: "500px" },
              maxWidth: { sm: "610px", lg: "500px" },
            },
            "& .MuiModal-backdrop": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
            "& .MuiDialogContent-root": {
              overflow: "hidden",
            },
          },
        }}
      >
          <MyError
            onRetry={onCloseModal}
            content={{
              title: t("rate-limit.title"),
              centent: t("rate-limit.description"),
              labelButton: t("Authen.errors.try-again")
            }}
          />
      </ModalCloseOutBoxCustom>
    </>
  );
};

export default RateLimitPopup;
