"use client";

import ButtonCustom from "@/components/Button/ButtonCustom";
import ButtonOutlinedCustom from "@/components/Button/ButtonOutlinedCustom";
import RHFEmailField from "@/components/Form/RHFEmailField";
import { useAuthenticationStore } from "@/contexts/useAuthentication";
import { useTranslation } from "@/i18n/client";
import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";
import { ForgotPasswordFormSchema } from "@/libs/types/authentication.type";
import color from "@/styles/color";
import { Stack } from "@mui/material";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";

type VerifyFormType = {
  form: UseFormReturn<ForgotPasswordFormSchema, any, undefined>;
};

const VerifyForm: FC<VerifyFormType> = () => {
  const { t } = useTranslation("", {
    keyPrefix: "Authen",
  });

  const { setCurrentPage } = useAuthenticationStore();

  return (
    <Stack sx={{ gap: 3 }}>
      <RHFEmailField
        withIcon
        name="email"
        autoComplete="new-email"
        label={t("form.email")}
        placeholder={t("placeholder.email")}
        rules={{ required: true }}
      />
      <ButtonCustom
        type="submit"
        label={t(`verify.button-verify`)}
        sx={{
          height: { xs: "40px" },
          fontSize: { xs: "14px", xl: "16px" },
          background: color.redGradient,
          color: color.white,
        }}
      />
      <ButtonOutlinedCustom
        onClick={() => setCurrentPage(AuthenticationPageEnum.Login)}
        disableRipple
        disableFocusRipple
        label={t(`forgot-password.button-back`)}
        sx={{
          border: "none !important",
          height: { xs: "40px" },
          fontSize: { xs: "14px", xl: "16px" },
          fontWeight: 600,
          color: color.gray400,
          textDecoration: "underline !important",
          textTransform: 'none'
        }}
      />
    </Stack>
  );
};

export default VerifyForm;
