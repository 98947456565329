import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";
import { create } from "zustand";

type AuthenticationStore = {
  currentPage: AuthenticationPageEnum;
  setCurrentPage: (currentPage: AuthenticationPageEnum) => void;

  showAuthenticationPopup: boolean;
  setShowAuthenticationPopup: (showAuthenticationPopup: boolean) => void;
};

const initialState = {
  currentPage: AuthenticationPageEnum.Login,
  showAuthenticationPopup: false,
};

export const useAuthenticationStore = create<AuthenticationStore>((set) => ({
  currentPage: initialState.currentPage,
  setCurrentPage: (currentPage: AuthenticationPageEnum) => {
    set({
      currentPage,
    });
  },
  showAuthenticationPopup: initialState.showAuthenticationPopup,
  setShowAuthenticationPopup: (showAuthenticationPopup: boolean) => {
    set((state) => {
      return {
        showAuthenticationPopup,
        currentPage: showAuthenticationPopup ? initialState.currentPage : state.currentPage,
      };
    });
  },
}));
