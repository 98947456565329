import { create } from "zustand";

type SocialShareStore = {
  isOpenModalRateLimit: boolean;
  onCloseModal: () => void;
  onOpenModal: () => void;
};

export const useRateLimitStateStore = create<SocialShareStore>((set) => ({
  isOpenModalRateLimit: false,
  onCloseModal: () => {
    set({ isOpenModalRateLimit: false });
  },
  onOpenModal: () => {
    set({ isOpenModalRateLimit: true });
  },
}));
