"use client";

import { createTheme } from "@mui/material";

export const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 833,
      lg: 1194,
      xl: 1300,
      "2xl": 1440,
    },
  },
  typography: {
    fontFamily: `var(--font-poppins), var(--font-kanit)`,
  },
});
