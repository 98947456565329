import { axiosServerInstance } from "@/libs/axios/axios-server-instance";
import {
  ChangePasswordFormSchema,
  ForgotPasswordFormSchema,
  LoginFormSchema,
  RegisterFormSchema,
  ResetPasswordFormSchema,
} from "@/libs/types/authentication.type";
import { UserProfile } from "@/libs/types/edit-profile.type";

export const authentication = {
  signIn: async (params: LoginFormSchema, locale: string) => {
    return axiosServerInstance.post("/api/v1/auth/login", {...params, token: params.tokenCaptcha, lang: locale }).then((response) => response);
  },
  signOut: async () => {
    return axiosServerInstance.post("/api/v1/auth/logout").then((response) => response);
  },
  signUp: async (params: RegisterFormSchema) => {
    return axiosServerInstance
      .post(
        "/api/v1/auth/register",
        {
          ...params,
          isActive: true,
          howYouKnowToyotasure: Number(params.howToKnow),
          token: params.tokenCaptcha
        },
        { withCredentials: false },
      )
      .then((response) => response);
  },
  getProfile: async () => {
    return axiosServerInstance.get("/api/v1/auth/profile").then((response) => response);
  },
  forgotPassword: async (params: ForgotPasswordFormSchema) => {
    return axiosServerInstance
      .post("/api/v1/member/forgot-password", params, { withCredentials: false })
      .then((response) => response);
  },
  resetPassword: async (params: ResetPasswordFormSchema) => {
    return axiosServerInstance
      .post("/api/v1/member/reset-password", params)
      .then((response) => response);
  },
  getEmail: async (token: string) => {
    return axiosServerInstance
      .get("/api/v1/member/verify/token", { params: { token }, withCredentials: false })
      .then((response) => response);
  },
  verifyChaptchaToken: async (token: string) => {
    return axiosServerInstance
      .post("/api/v1/auth/verify/capcha", { token })
      .then((response) => response);
  },
  gender: async () => {
    return axiosServerInstance.get("/api/v1/member/gender").then((response) => response);
  },
  howDidYouKnowAboutUs: async (lang: string) => {
    return axiosServerInstance.get("/api/v1/contents/how-did-you-know-about-us", { params: { lang }, withCredentials: false })
    .then((response) => response);
  },
  updateProfile: async (params: UserProfile) => {
    return axiosServerInstance
      .patch("/api/v1/member/edit-profile", {
        ...params,
        ...(params.genderId && { genderId: +params.genderId }),
      })
      .then((response) => response);
  },
  refreshToken: async () => {
    return axiosServerInstance.get("/api/v1/auth/refreshToken").then((response) => response);
  },
  changePassword: async (params: ChangePasswordFormSchema) => {
    return axiosServerInstance
      .post("/api/v1/member/change-password", params)
      .then((response) => response);
  },
  requestVerify: async (email: string) => {
    return axiosServerInstance
      .post("/api/v1/auth/get-verify", { email })
      .then((response) => response);
  },
  verifySignup: async (token: string) => {
    return axiosServerInstance
      .post("/api/v1/auth/verify", { token })
      .then((response) => response);
  },
};