import { useTranslation } from "@/i18n/client";
import { RedGradientBtn } from "@/styles/customStyle";
import { Box, Typography } from "@mui/material";
import React from "react";
import { DividerCustom } from "@/components/Divider/DividerCustom";
import color from "@/styles/color";
import {
  NavbarItems,
  NavbarKeyType,
  NavbarLanguageItems,
} from "@/libs/constant/navbar-item.constant";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { useRouter } from "next/navigation";
import thailand from "@/assets/image/thailand.webp";
import english from "@/assets/image/english.webp";
import favHeart from "@/assets/image/car_favorited.webp";
import AccordionCustom from "@/components/Accordion/AccordionCustom";
import { User } from "@/libs/types/authentication.type";
import FilterNameComponent from "@/components/CarFilterSection/FilterNameComponent";
import IconLoader from "@/components/IconLoader";

interface IMainMenuDrawerProps {
  userProfile: JSX.Element;
  isLogged: boolean;
  lang: string;
  profile?: User | null;
  goWishlist: () => void;
  onClickLogin: () => void;
  onClose: () => void;
  onChangeLanguage: (lang: string) => void;
  onGoSecondaryDrawer: (key: NavbarKeyType) => void;
  onClickLogout: () => void;
  onClickResetPassword: () => void;
  onClickEditUser: () => void;
}

function MainMenuDrawer({
  userProfile,
  isLogged,
  lang,
  profile,
  goWishlist,
  onClickLogin,
  onClose,
  onChangeLanguage,
  onGoSecondaryDrawer,
  onClickLogout,
  onClickResetPassword,
  onClickEditUser,
}: IMainMenuDrawerProps) {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <Box
      component={"div"}
      sx={{
        width: "100%",
        padding: "1rem",
        minHeight: "calc(100vh - 56px)",
        height: "100%",
      }}
    >
      <Box component={"div"} sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        {isLogged ? (
          <Box
            component={"div"}
            sx={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}
          >
            <Box
              component={"div"}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconLoader iconName="AccountWhiteIcon" />
            </Box>
            <Box
              component={"div"}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                component={"h3"}
                sx={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  color: color.white,
                }}
              >{`${profile?.firstName || ""} ${profile?.lastName || ""}`}</Typography>
              <Typography
                component={"h3"}
                sx={{
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  lineHeight: "1.5rem",
                  color: color.white,
                }}
              >{`${profile?.email || ""}`}</Typography>
            </Box>
            <Box
              component={"div"}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "44px",
                padding: "0px 8px",
                cursor: "pointer",
              }}
              onClick={onClickEditUser}
            >
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <IconLoader iconName="EditorWhiteIcon" />
                <Typography
                  sx={{
                    color: color.white,
                    fontSize: "1rem",
                    fontWeight: 600,
                    lineHeight: "1.75rem",
                  }}
                >
                  {t("Authen.login.edit-account")}
                </Typography>
              </Box>
              <ArrowForwardRoundedIcon sx={{ color: color.white, fontSize: "1.5rem" }} />
            </Box>
            <Box
              component={"div"}
              sx={{
                display: profile?.isSocialAccount ? "none" : "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "44px",
                padding: "0px 8px",
                cursor: "pointer",
              }}
              onClick={onClickResetPassword}
            >
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <IconLoader iconName="PasswordWhiteIcon" />
                <Typography
                  sx={{
                    color: color.white,
                    fontSize: "1rem",
                    fontWeight: 600,
                    lineHeight: "1.75rem",
                  }}
                >
                  {t("Authen.login.reset-password")}
                </Typography>
              </Box>
              <ArrowForwardRoundedIcon sx={{ color: color.white, fontSize: "1.5rem" }} />
            </Box>
          </Box>
        ) : (
          <RedGradientBtn
            sx={{
              display: "flex",
              width: "100% !important",
              maxWidth: "100%",
              height: "44px !important",
            }}
            onClick={onClickLogin}
          >
            <IconLoader iconName="UserIcon" />
            {userProfile}
          </RedGradientBtn>
        )}
        <DividerCustom
          sx={{
            borderColor: color.gray800,
          }}
        />
        <React.Fragment>
          {NavbarItems.map((item, index) =>
            item.haveDrawer ? (
              <Typography
                key={`navbar-item-${index + 1}`}
                component={"h1"}
                onClick={() => onGoSecondaryDrawer(item.key)}
                sx={{
                  color: color.white,
                  fontSize: "1rem",
                  fontWeight: 600,
                  lineHeight: "1.75rem",
                  padding: "0px 8px",
                  cursor: "pointer",
                  height: "44px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t(item.title)}
              </Typography>
            ) : (
              <Box
                key={`navbar-item-${index + 1}`}
                component={"div"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 8px",
                  cursor: "pointer",
                  height: "44px",
                }}
                onClick={() => {
                  onClose();
                  router.push(item.link);
                }}
              >
                <Typography
                  sx={{
                    color: color.white,
                    fontSize: "1rem",
                    fontWeight: 600,
                    lineHeight: "1.75rem",
                  }}
                >
                  {t(item.title)}
                </Typography>
                {item.showIcon && <ArrowForwardRoundedIcon sx={{ color: color.white, fontSize: "1.5rem" }} />}
              </Box>
            ),
          )}
        </React.Fragment>
        <DividerCustom
          sx={{
            borderColor: color.gray800,
          }}
        />
        <Box
          component={"div"}
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            cursor: "pointer",
            padding: "0px 8px",
            height: "44px",
          }}
          onClick={() => {}}
        >
          <Box
            component={"img"}
            src={favHeart.src}
            alt="favorite icon"
            sx={{
              display: "block",
              width: "24px",
              height: "24px",
            }}
          />
          <Typography
            sx={{
              color: color.white,
              fontSize: "1rem",
              fontWeight: 600,
              lineHeight: "1.75rem",
            }}
            onClick={()=> {
              goWishlist()
              onClose()
            }}
          >
            {t("Navbar.title.favorite_list")}
          </Typography>
        </Box>

        <AccordionCustom
          summaryName={"navbar_language"}
          expanded={false}
          summaryElement={
            <FilterNameComponent
              title={`${t("common.lang")} : ${t(`Navbar.title.${lang === "th" ? "thailand" : "english"}`)}`}
              icon={
                <Box
                  component={"img"}
                  src={lang === "th" ? thailand.src : english.src}
                  alt="language icon"
                  sx={{
                    display: "block",
                    width: "24px",
                    height: "24px",
                  }}
                />
              }
              fontColor={color.white}
            />
          }
          accordionSx={{
            padding: "0px 8px",
            border: `none`,
            borderRadius: "8px",
            backgroundColor: "transparent",
            "&::before": {
              display: "none",
            },
            "--color-stop-3": color.white,
            "--color-stop-4": color.white,
            "& .Mui-expanded": {
              background: "transparent",
              color: color.white,
              "--color-stop-3": color.white,
              "--color-stop-4": color.white,
            },
          }}
          accordionSumSx={{
            padding: "0px",
            backgroundColor: "transparent",
            borderRadius: "8px",
            flexDirection: "row",
            minHeight: "44px",
            maxHeight: "44px",
            margin: "1px",
            "& .MuiAccordionSummary-expandIconWrapper": {
              background: "transparent",
              borderRadius: "100%",
              "--color-stop-1": color.white,
              "--color-stop-2": color.white,
              transform: "rotate(0deg)",
              "&.Mui-expanded": {
                background: "transparent",
                "--color-stop-1": color.white,
                "--color-stop-2": color.white,
                transform: "rotate(180deg)",
              },
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: "0px",
            },
          }}
          sx={{
            paddingTop: "16px",
          }}
        >
          <Box component={"div"} sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {NavbarLanguageItems.map((item, index) => (
              <Box
                key={index}
                component={"div"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  minHeight: "44px",
                  alignItems: "center",
                  height: "100%",
                  cursor: "pointer",
                }}
                onClick={() => onChangeLanguage(item.key)}
              >
                <FilterNameComponent
                  title={t(item.title)}
                  icon={
                    <Box
                      component={"img"}
                      src={item.image}
                      alt="language icon"
                      sx={{
                        display: "block",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  }
                  fontColor={color.white}
                />
                {lang === item.key && <IconLoader iconName="CheckCircleIcon" />}
              </Box>
            ))}
          </Box>
        </AccordionCustom>
        {isLogged && (
          <React.Fragment>
            <DividerCustom
              sx={{
                borderColor: color.gray800,
              }}
            />
            <RedGradientBtn
              sx={{
                display: "flex",
                width: "100% !important",
                maxWidth: "100%",
                height: "44px !important",
              }}
              onClick={onClickLogout}
            >
              <IconLoader iconName="SignOutWhiteIcon" />
              {t("Authen.login.sign-out")}
            </RedGradientBtn>
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
}

export default MainMenuDrawer;
