import { Box, SxProps, Typography } from "@mui/material";

type FilterNameComponentProps = {
  icon: JSX.Element;
  title: string;
  fontColor?: string;
  labelSx?: SxProps;
  containerSx?: SxProps;
};

const FilterNameComponent = ({
  icon,
  title,
  fontColor = "currentcolor",
  labelSx = {},
  containerSx = {},
}: FilterNameComponentProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        alignItems: "center",
        ...containerSx,
      }}
    >
      {icon}
      <Typography
        sx={{
          fontWeight: 600,
          color: fontColor,
          fontSize: { xs: "16px", sm: "16px", lg: "14px", xl: "14px" },
          lineHeight: "28px",
          ...labelSx,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default FilterNameComponent;
