import { Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "@/i18n/client";

import ForgotPasswordForm from "../forms/ForgotPasswordForm";
import RHFFormProvider from "@/libs/react-hook-form/FormProvider";
import { ForgotPasswordFormSchema } from "@/libs/types/authentication.type";
import color from "@/styles/color";
import { OnClickProp } from "../AuthenticationContent";
import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordSchema } from "../forms/schema/reset-password";

const ForgotPassword = ({ onClick }: OnClickProp) => {
  const { t } = useTranslation("", {
    keyPrefix: "Authen.forgot-password",
  });

  const forgotPasswordForm = useForm<ForgotPasswordFormSchema>({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const handleOnSubmit = forgotPasswordForm.handleSubmit(async (values) => {
    onClick({ event: AuthenticationPageEnum.ForgotPassword, body: values });
  });

  return (
    <Stack sx={{ gap: 3 }}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: { xs: "1rem" },
          lineHeight: { xs: "1.875rem" },
          color: color.black200,
          textAlign: "center",
          whiteSpace: "pre-wrap",
        }}
      >
        {t("title")}
      </Typography>
      <RHFFormProvider methods={forgotPasswordForm} onSubmit={handleOnSubmit}>
        <ForgotPasswordForm form={forgotPasswordForm} />
      </RHFFormProvider>
    </Stack>
  );
};

export default ForgotPassword;
