import { FC, ReactNode, useMemo } from "react";
import Image from "next/image";
import { Box, Stack, SxProps, Typography } from "@mui/material";

import ToyotaLogoImg from "@/assets/image/toyota-sure-main-logo.webp";
import { thousandComma } from "@/libs/common/string";
import { VehicleCard } from "@/libs/types/vehicle.type";
import color from "@/styles/color";

import RemoveButton from "./RemoveButton";
import { getVehiclePrice } from "@/libs/utils/vehicle";
import { _startCase } from "@/libs/utils/helper";

type CardCarDetailProps = {
  data: VehicleCard;
  layout: "horizontal" | "vertical";
  onClickRemove?: () => void;
  customFooter?: ReactNode;
  customContainerSx?: SxProps;
  customImageContainerSx?: SxProps;
  hideRemoveButton?: boolean;
};

const CardCarDetail: FC<CardCarDetailProps> = ({
  data,
  layout,
  onClickRemove,
  customFooter,
  customContainerSx,
  customImageContainerSx,
  hideRemoveButton = false,
}) => {
  const { carPrice, carPriceWithVat } = useMemo(() => {
    return getVehiclePrice(data);
  }, [data]);

  return (
    <Stack
      flexDirection={layout === "horizontal" ? "row" : "column"}
      sx={{
        borderRadius: 4,
        boxShadow: "0px 4px 4px 0px #0000001A",
        position: "relative",
        ...customContainerSx,
      }}
    >
      <Box sx={{ ...customImageContainerSx }}>
        <Image
          src={
            data?.VehicleImageURL && data?.VehicleImageURL !== ""
              ? data.VehicleImageURL
              : ToyotaLogoImg.src
          }
          alt="Car Thumbnail"
          width={295}
          height={200}
          sizes="50vw"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: layout === "horizontal" ? "16px 0px 0px 16px" : "16px 16px 0px 0px",
            objectFit: "cover",
          }}
          priority={true}
          fetchPriority="high"
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: { xs: "8px", sm: customFooter ? "8px" : "16px" },
          width: "100%",
        }}
      >
        <Stack
          gap={0.5}
          sx={{
            flex: 1,
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: { lg: "space-between" },
            width: "100%",
          }}
        >
          <Stack>
            <Typography
              sx={{
                fontWeight: 600,
                color: color.black,
                fontSize: { xs: "10px", lg: "13px" },
                lineHeight: { xs: "16px", lg: "23px" },
                "@media print": {
                  fontSize: "13px",
                  lineHeight: "23px",
                },
              }}
            >
              {`${_startCase(data?.Brand)} ${data?.Model}`}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                color: color.gray300,
                fontSize: { xs: "8px", lg: "10px" },
                lineHeight: { xs: "16px", lg: "23px" },
                "@media print": {
                  fontSize: "10px",
                  lineHeight: "23px",
                },
              }}
            >
              {data?.Grade}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: { xs: "12px", lg: "20px" },
                lineHeight: { xs: "16px", lg: "24px" },
                background: color.redGradient,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                "@media print": {
                  fontSize: "20px",
                  lineHeight: "24px",
                },
              }}
            >
              {thousandComma(carPrice || 0)} บาท
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { xs: "8px", lg: "10px" },
                lineHeight: { xs: "8px", lg: "24px" },
                color: color.black,
                opacity: 0.5,
                "@media print": {
                  fontSize: "10px",
                  lineHeight: "24px",
                },
              }}
            >
              {thousandComma(carPriceWithVat || 0)} บาท (รวมภาษี 7%)
            </Typography>
          </Stack>
        </Stack>
        {!hideRemoveButton && (
          <RemoveButton
            onClick={() => onClickRemove && onClickRemove()}
            sx={{
              ...(layout === "vertical" && {
                position: "absolute",
                top: { xs: -5, lg: -10 },
                right: { xs: -5, lg: -10 },
              }),
            }}
          />
        )}
      </Box>
      {customFooter && (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: { xs: "0px 8px 8px 8px", lg: "0px 16px 16px 16px" },
            width: "100%",
          }}
        >
          {customFooter}
        </Box>
      )}
    </Stack>
  );
};

export default CardCarDetail;
