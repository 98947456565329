import axios from "axios";
import { ENV_CONFIG } from "../env";
import { authentication } from "../services/authentication/authentication.service";
import { getCookie } from "cookies-next";

const lang = getCookie('preferred_language')

const maxRetries = 3;
let retry = 0;

const axiosServerInstance = axios.create({
  baseURL: ENV_CONFIG.API_URL_INTERNAL,
  headers: {
    "Content-type": "application/json",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
    "X-Frame-Options": "DENY",
    "Content-Security-Policy": "default-src 'self'; object-src 'none'",
    "X-Content-Type-Options": "nosniff",
    "Cache-Control": "no-store, no-cache, must-re-validate"
  },
  params: {
    Lang: lang
  },
  withCredentials: true,
});

axiosServerInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosServerInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalRequest = err?.config;

    if (err.response?.status === 401 && !originalRequest?._retry && retry++ <= maxRetries) {
      originalRequest._retry = true;

      try {
        await authentication.refreshToken();
        return axiosServerInstance(originalRequest);
      } catch (error) {
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  },
);

export { axiosServerInstance };
