import { axiosDSureInstance } from "@/libs/axios/axios-dsure-instance";
import { apiPaths } from "@/libs/constant/api";
import { DropLeadRequestSchema, CustomerContact } from "@/libs/types/drop-lead.type";

//
export const dropleadService = {
  contact: async (params: DropLeadRequestSchema | CustomerContact) => {
    return axiosDSureInstance.post(apiPaths.droplead.contact, params).then((response) => response);
  },
};
