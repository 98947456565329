import * as yup from "yup";

export const regexSpecialChar = /^(?!.*alert\(.*\))(?!.*<[^>]+>).*$/
const userValidationSchema = yup.object().shape({
  id: yup.number().optional(),
  firstName: yup
    .string()
    .required("Authen.errors.fistname-require")
    .matches(/^\S+$/, "Authen.errors.fistname-require")
    .matches(regexSpecialChar, "Authen.errors.fistname-wrong"),
  lastName: yup
    .string()
    .required("Authen.errors.lastname-require")
    .matches(/^\S+$/, "Authen.errors.lastname-require")
    .matches(regexSpecialChar, "Authen.errors.lastname-wrong"),
  gender: yup.number().optional().nullable(),
  dateOfBirth: yup.date().optional().nullable(),
  email: yup
    .string()
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "Authen.errors.email-is-incorrect-pattern",
    ),
  phone: yup
    .string()
    .optional()
    .matches(/^\S+$/, "Authen.errors.mobile-require")
    .matches(/^0[0-9]{2}-[0-9]{3}-[0-9]{4}$/, "Authen.errors.mobile-is-incorrect-pattern"),
  citizenId: yup
    .string()
    .optional()
    .nullable()
    .min(13, "Authen.errors.citizen-id-incorrect")
    .max(13, "Authen.errors.citizen-id-incorrect")
    .test("pattern", "Authen.errors.citizen-id-incorrect", (value) => {
      if (!value) return true;
      if (value.includes("_")) return true;
      // STEP 1 - get only first 12 digits
      const sum = value
        .split("")
        .splice(0, 12)
        .map((it: string, id) => Number(it) * (13 - id))
        .reduce((prev, curr) => {
          return prev + Number(curr);
        }, 0);
      // STEP 4 - mod sum with 11
      const mod = sum % 11;
      // STEP 5 - subtract 11 with mod, then mod 10 to get unit
      const check = (11 - mod) % 10;
      // STEP 6 - if check is match the digit 13th is correct
      if (check == parseInt(value.charAt(12))) {
        return true;
      }
      return false;
    }),
  lineId: yup
    .string()
    .optional()
    .nullable()
    .matches(/^[a-zA-Z0-9.@_]*$/gi, "Authen.errors.address-is-incorrect-pattern"), // can be null
  address: yup
    .string()
    .optional()
    .nullable()
    .matches(/^(?!.*alert\(.*\))(?!.*<[^>]+>).*$/, "Authen.errors.address-is-incorrect-pattern"), // can be null
});

export default userValidationSchema;
