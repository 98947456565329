import * as yup from "yup";
import { regexSpecialChar } from "./edit-profile";


export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("Authen.errors.email-require")
    .matches(/^\S+$/, "Authen.errors.email-require")
    .matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, "Authen.errors.email-is-incorrect-pattern"),
  locale: yup.string().optional(),
});

export const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("errors.email-require")
    .matches(/^\S+$/, "errors.email-require")
    .matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, "errors.email-is-incorrect-pattern"),
  password: yup
    .string()
    .required("Authen.errors.password-require")
    .matches(/^\S+$/, "Authen.errors.password-require")
    .min(6, "Authen.errors.password-must-be-longer-6-length")
    .max(16, "Authen.errors.password-must-be-less-than-6-length")
    .matches(/^(?=.{6,})/, "Authen.errors.password-must-be-longer-6-length")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])/, "Authen.errors.password-must-be-uppercase-lowercase")
    .matches(/^(?=.{6,20}$)\D*\d/, "Authen.errors.password-must-be-include-number")
    .matches(regexSpecialChar, "Authen.errors.password-must-be-include-number"),
  confirmPassword: yup
    .string()
    .required("Authen.errors.confirm-password-require")
    .matches(/^\S+$/, "Authen.errors.confirm-password-require")
    .oneOf([yup.ref("password")], "Authen.errors.password-miss-matched"),
  token: yup.string().optional(),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("Authen.errors.password-require")
    .matches(/^\S+$/, "Authen.errors.password-require")
    .min(6, "Authen.errors.password-must-be-longer-6-length")
    .max(16, "Authen.errors.password-must-be-less-than-6-length")
    .matches(/^(?=.{6,})/, "Authen.errors.password-must-be-longer-6-length")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])/, "Authen.errors.password-must-be-uppercase-lowercase")
    .matches(/^(?=.{6,20}$)\D*\d/, "Authen.errors.password-must-be-include-number")
    .matches(regexSpecialChar, "Authen.errors.password-must-be-include-number"),
  password: yup
    .string()
    .required("Authen.errors.password-require")
    .matches(/^\S+$/, "Authen.errors.password-require")
    .min(6, "Authen.errors.password-must-be-longer-6-length")
    .max(16, "Authen.errors.password-must-be-less-than-6-length")
    .matches(/^(?=.{6,})/, "Authen.errors.password-must-be-longer-6-length")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])/, "Authen.errors.password-must-be-uppercase-lowercase")
    .matches(/^(?=.{6,20}$)\D*\d/, "Authen.errors.password-must-be-include-number")
    .matches(regexSpecialChar, "Authen.errors.password-must-be-include-number")
    .notOneOf([yup.ref("oldPassword")], "Authen.errors.password-must-be-new"),
  confirmPassword: yup
    .string()
    .required("Authen.errors.confirm-password-require")
    .matches(/^\S+$/, "Authen.errors.confirm-password-require")
    .oneOf([yup.ref("password")], "Authen.errors.password-miss-matched"),
});
