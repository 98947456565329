"use client";
import color from "@/styles/color";
import {
  AccordionProps,
  AccordionSummaryProps,
  styled,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  AccordionDetailsProps,
  SxProps,
} from "@mui/material";
import React from "react";
import IconLoader from "../IconLoader";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  padding: "0px",
  border: "0px",
  backgroundColor: "transparent",
  "&::before": {
    display: "none",
  },
  "--color-stop-3": color.red,
  "--color-stop-4": color.red110,
  "& .Mui-expanded": {
    background: color.redGradient,
    color: color.white,
    "--color-stop-3": color.white,
    "--color-stop-4": color.white,
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => {
  return (
    <MuiAccordionSummary
      expandIcon={
        <IconLoader iconName="ArrowDownAccordionGradientIcon" sx={{ fontSize: "20px" }} />
      }
      {...props}
    />
  );
})(({ theme }) => ({
  padding: "0px 24px",
  backgroundColor: color.gray120,
  borderRadius: "30px",
  flexDirection: "row",
  minHeight: "40px",
  height: "20px",
  maxHeight: "40px",
  "& .MuiAccordionSummary-expandIconWrapper": {
    background: "transparent",
    borderRadius: "100%",
    "--color-stop-3": color.red,
    "--color-stop-4": color.red110,
    transform: "rotate(0deg)",
    "&.Mui-expanded": {
      background: "transparent",
      "--color-stop-3": color.white,
      "--color-stop-4": color.white,
      transform: "rotate(180deg)",
    },
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

interface IAccordionCustomProps {
  expanded?: boolean;
  summaryElement: JSX.Element | string;
  summaryName: string;
  accordionSumSx?: SxProps;
  accordionSx?: SxProps;
  accordionProps?: AccordionProps;
  accordionSumProps?: AccordionSummaryProps;
  isDisabled?: boolean
}

function AccordionCustom({
  summaryName,
  expanded = false,
  summaryElement,
  children,
  accordionSumSx = {},
  accordionSx = {},
  accordionProps,
  accordionSumProps,
  isDisabled = false,
  ...rest
}: IAccordionCustomProps & AccordionDetailsProps) {
  return (
    <Accordion
      defaultExpanded={expanded}
      slotProps={{ transition: { unmountOnExit: true } }}
      {...accordionProps}
      sx={{ ...accordionSx }}
      disabled={isDisabled}
    >
      <AccordionSummary aria-label={summaryName} {...accordionSumProps} sx={{ ...accordionSumSx }}>
        {summaryElement}
      </AccordionSummary>
      <AccordionDetails
        {...rest}
        sx={{ padding: "0px", backgroundColor: "transparent", ...rest?.sx }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default React.memo(AccordionCustom);
