import { FC, useMemo, useState } from "react";
import { InputAdornment } from "@mui/material";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";

import { useTranslation } from "@/i18n/client";
import FormControlContainer from "./FormControlContainer";
import { DatePickerProps, DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import color from "@/styles/color";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export type RHFDatePickerFieldProps = DatePickerProps<any, any> & {
  name: NonNullable<DatePickerProps<any, any>["name"]>;
  label: string;
  rules?: ControllerProps["rules"];
  placeholder: string;
};

const RHFDateTimePickerField: FC<RHFDatePickerFieldProps> = ({ name, label, rules, ...rest }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { control } = useFormContext();

  const isRequired = useMemo(() => {
    return Boolean(rules?.required || false);
  }, [rules]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, value, ...other }, fieldState: { error } }) => {
        return (
          <FormControlContainer label={label} isRequired={isRequired}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                inputRef={ref}
                ampm={false}
                value={dayjs(value)}
                disablePast
                format="DD/MM/YYYY HH:mm"
                {...other}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                slotProps={{
                  inputAdornment: {
                    position: "start",
                  },
                  leftArrowIcon: {},
                  textField: {
                    helperText: t(error?.message as string),
                    InputProps: {
                      sx: {
                        borderRadius: "8px !important",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "23px",
                        color: color.black,
                        background: color.gray280,
                        height: { xs: "44px", lg: "56px" },
                        width: "100%",
                        padding: "10px",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarTodayIcon style={{ cursor: "pointer" }} />
                        </InputAdornment>
                      ),
                      onClick: () => setOpen(!open),
                    },
                    InputLabelProps: {
                      shrink: true,
                      sx: {
                        "&.Mui-disabled": {
                          color: "#979797 !important",
                        },
                      },
                    },
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                        borderWidth: "0px !important",
                      },
                      "& .Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderWidth: "0px !important",
                        },
                      },
                      "& .Mui-disabled": {
                        color: "#979797 !important",
                        WebkitTextFillColor: "#979797 !important",
                        bgcolor: "#FFFFFF !important",
                      },
                      "& input::placeholder": {
                        opacity: 1,
                        color: color.grey,
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "23px",
                      },
                      "& .MuiInputBase-input": {
                        ":-webkit-autofill": {
                          height: "19px",
                          bgcolor: "transparent",
                        },
                      },
                      ...rest?.sx,
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </FormControlContainer>
        );
      }}
    />
  );
};

export default RHFDateTimePickerField;
