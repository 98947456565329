import { SessionOptions } from "iron-session";
import { ENV_CONFIG } from "../env";

const cookieName = "dsure.session";

export const sessionOptions: SessionOptions = {
  cookieName,
  cookieOptions: {
    httpOnly: true,
    secure: ENV_CONFIG.NODE_ENV === "develop" ? false : true,
    sameSite: "lax",
    maxAge: 60 * 60 * 24, // 24hour
    path: "/",
  },
  password: ENV_CONFIG.COOKIE_PASSWORD as string,
};

declare module "iron-session" {
  export interface DSureSessionData {
    token?: {
      jwtToken: string;
      refreshToken: string;
    };
  }
}
