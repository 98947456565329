import thailand from "@/assets/image/thailand.webp";
import english from "@/assets/image/english.webp";

interface INavbarItem {
  title: string;
  key: NavbarKeyType;
  link: string;
  haveDrawer: boolean;
  showIcon: boolean
}

export type NavbarKeyType = "car_interest" | "article" | "about_us" | "dealer";

export const NavbarItems: INavbarItem[] = [
  {
    title: "Navbar.title.car_interest",
    key: "car_interest",
    link: "/",
    haveDrawer: true,
    showIcon: false
  },
  {
    title: "Navbar.title.dealer",
    key: "dealer",
    link: "/dealerdetails/dealerlist",
    haveDrawer: false,
    showIcon: false
  },
  {
    title: "Navbar.title.article",
    key: "article",
    link: "/",
    haveDrawer: true,
    showIcon: false
  },
  {
    title: "Navbar.title.about_us",
    key: "about_us",
    link: "/",
    haveDrawer: true,
    showIcon: false
  },
];

export const NavbarLanguageItems = [
  {
    key: "th",
    title: "Navbar.title.thailand",
    image: thailand.src,
  },
  {
    key: "en",
    title: "Navbar.title.english",
    image: english.src,
  },
];
