import * as yup from "yup";
import { regexSpecialChar } from "./edit-profile";

export const registerSchemaValidator = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .required("Authen.errors.fistname-require")
      .matches(/^\S+$/, "Authen.errors.fistname-require")
      .matches(regexSpecialChar, "Authen.errors.fistname-wrong"),
    lastName: yup
      .string()
      .required("Authen.errors.lastname-require")
      .matches(/^\S+$/, "Authen.errors.lastname-require")
      .matches(regexSpecialChar, "Authen.errors.lastname-wrong"),
    phone: yup
      .string()
      .required("Authen.errors.mobile-require")
      .matches(/^\S+$/, "Authen.errors.mobile-require")
      .matches(/^0[0-9]{2}-[0-9]{3}-[0-9]{4}$/, "Authen.errors.mobile-is-incorrect-pattern"), 
    email: yup
      .string()
      .required("Authen.errors.email-require")
      .matches(/^\S+$/, "Authen.errors.email-require")
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Authen.errors.email-is-incorrect-pattern"),
    password: yup
      .string()
      .required("Authen.errors.password-require")
      .matches(/^\S+$/, "Authen.errors.password-require")
      .min(6, "Authen.errors.password-must-be-longer-6-length")
      .max(16, "Authen.errors.password-must-be-less-than-6-length")
      .matches(/^(?=.{6,})/, "Authen.errors.password-must-be-longer-6-length")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])/, "Authen.errors.password-must-be-uppercase-lowercase")
      .matches(/^(?=.{6,20}$)\D*\d/, "Authen.errors.password-must-be-include-number")
      .matches(regexSpecialChar, "Authen.errors.password-must-be-include-number"),
    confirmPassword: yup
      .string()
      .required("Authen.errors.confirm-password-require")
      .matches(/^\S+$/, "Authen.errors.confirm-password-require")
      .oneOf([yup.ref("password")], "Authen.errors.password-miss-matched"),
    howToKnow: yup.number().optional(),
    tokenCaptcha: yup.string().optional(),
    isAccept: yup.boolean().required(),
  })
  .required();
