import { Toolbar } from "@mui/material";
import { useRouter } from "next/navigation";

import { useCompareCarStore } from "@/contexts/useCompareCar";
import { useTranslation } from "@/i18n/client";

import ButtonCustom from "@/components/Button/ButtonCustom";
import ButtonOutlinedCustom from "@/components/Button/ButtonOutlinedCustom";
import color from "@/styles/color";
import { generateVehicleUrlParams } from "@/libs/utils/vehicle";

const DrawerFooterSection = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { compareCarSelectedList, resetCompareCarSelected } = useCompareCarStore();

  const handleReset = () => {
    resetCompareCarSelected();
  };

  const buttonStyleSx = {
    height: "40px",
    fontSize: { xs: "14px" },
    lineHeight: { xs: "23px" },
    padding: 0,
  };

  const handleClickGoToCompareCarPage = () => {
    const vehicleParams = generateVehicleUrlParams(compareCarSelectedList);
    router.push(`/home/compare-car?vehiclecar=${vehicleParams}`);
  };

  return (
    <Toolbar
      variant="dense"
      sx={{
        minHeight: "unset",
        gap: 1,
        justifyContent: "center",
      }}
      disableGutters
    >
      <ButtonOutlinedCustom
        onClick={handleReset}
        label={t("CompareCar.modal.button-reset")}
        sx={{ ...buttonStyleSx, maxWidth: { md: "358px" } }}
      />
      <ButtonCustom
        onClick={handleClickGoToCompareCarPage}
        disabled={compareCarSelectedList.length < 2}
        label={t("CompareCar.modal.button-compare")}
        sx={{
          ...buttonStyleSx,
          maxWidth: { md: "358px" },
          "&:disabled": {
            background: color.gray900,
            color: color.white,
          },
        }}
      />
    </Toolbar>
  );
};

export default DrawerFooterSection;
