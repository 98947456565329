import { FC, useMemo } from "react";
import { MenuItem, SelectProps, SxProps, TextFieldProps } from "@mui/material";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";

import { useTranslation } from "@/i18n/client";
import FormControlContainer from "./FormControlContainer";
import DropDownMuiCustom from "../MenuSelect/DropDownMuiCustom";

export type RHFAutoCompleteProps = SelectProps & {
  name: NonNullable<SelectProps["name"]>;
  label: string;
  options?: Record<string, string>[];
  rules?: ControllerProps["rules"];
  inputProps: TextFieldProps;
  containerSx?: SxProps
  labelSx?: SxProps;
};

const RHFAutoComplete: FC<RHFAutoCompleteProps> = ({ id = "", name, label, rules, labelSx, containerSx, ...rest }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const isRequired = useMemo(() => {
    return Boolean(rules?.required || false);
  }, [rules]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, value, ...other }, fieldState: { error } }) => {
        return (
          <FormControlContainer labelSx={labelSx} containerSx={containerSx} label={label} isRequired={isRequired}>
            <DropDownMuiCustom
              id={id}
              {...rest}
              value={value ?? ""}
              error={!!error}
              inputProps={{
                ...other,
                helperText: t(error?.message as string),
              }}
              menuItems={(rest.options || []).map((data, index) => {
                return (
                  <MenuItem key={data["value"] + "_" + index} value={data["value"]}>
                    {data["label"]}
                  </MenuItem>
                );
              })}
            />
          </FormControlContainer>
        );
      }}
    />
  );
};

export default RHFAutoComplete;
