/* global google */

import { ReadonlyURLSearchParams } from "next/navigation";
import { forEach } from "../common/foreach";
import { reduce } from "../common/reduce";

export function applyUpdaterToNextProps(
  updaterMap: any,
  prevProps: any,
  nextProps: any,
  instance: any,
): any {
  const map: any = {};

  const iter = (fn: any, key: string): void => {
    const nextValue = nextProps[key];

    if (nextValue !== prevProps[key]) {
      map[key] = nextValue;
      fn(instance, nextValue);
    }
  };

  forEach(updaterMap, iter);

  return map;
}

export function registerEvents(
  props: any,
  instance: any,
  eventMap: Record<string, string>,
): google.maps.MapsEventListener[] {
  const registeredList = reduce(
    eventMap,
    function reducer(
      acc: google.maps.MapsEventListener[],
      googleEventName: string,
      onEventName: any,
    ): google.maps.MapsEventListener[] {
      if (typeof props[onEventName] === "function") {
        acc.push(google.maps.event.addListener(instance, googleEventName, props[onEventName]));
      }

      return acc;
    },
    [],
  );

  return registeredList;
}

function unregisterEvent(registered: google.maps.MapsEventListener): void {
  google.maps.event.removeListener(registered);
}

export function unregisterEvents(events: google.maps.MapsEventListener[] = []): void {
  events.forEach(unregisterEvent);
}

export function applyUpdatersToPropsAndRegisterEvents({
  updaterMap,
  eventMap,
  prevProps,
  nextProps,
  instance,
}: {
  updaterMap: any;
  eventMap: Record<string, string>;
  prevProps: any;
  nextProps: any;
  instance: any;
}): google.maps.MapsEventListener[] {
  const registeredEvents = registerEvents(nextProps, instance, eventMap);

  applyUpdaterToNextProps(updaterMap, prevProps, nextProps, instance);

  return registeredEvents;
}

export const setQueues = <T>(arg: T[], size: number = 5) => {
  const data: T[][] = [];
  const totalRows = arg.length;
  const totalRounds = Math.ceil(totalRows / size);

  for (let index = 0; index < totalRounds; index++) {
    const start = index * size;
    const end = start + size;
    const capacityData = arg.slice(start, end);

    data.push(capacityData);
  }

  return data;
};

export const currentUrl = (
  pathname: string,
  params: ReadonlyURLSearchParams,
  removeQuery: string[],
  destination: Record<string, string>,
) => {
  const searchParams = new URLSearchParams(params);

  if (removeQuery.length) {
    removeQuery.forEach((query) => {
      searchParams.delete(query);
    });
  }

  const destinations = Object.entries(destination);
  if (destinations.length) {
    destinations.forEach(([key, value]) => {
      searchParams.set(key, value);
    });
  }

  return pathname.concat("?", searchParams?.toString());
};

export const genericType = <T>(args: unknown): T => {
  return args as T;
};
