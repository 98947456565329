import { Fragment, useCallback, useMemo } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import { useCompareCarStore } from "@/contexts/useCompareCar";
import { maxCarCompareLength } from "@/libs/constant/compare-car";

import CardAddCar from "./CardAddCar";
import CardCarDetail from "./CardCarDetail";

const DrawerBodySection = () => {
  const { compareCarSelectedList, setShowDrawer, removeCompareCarSelected } = useCompareCarStore();

  const theme = useTheme();
  const isLessThanSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickAddCar = useCallback(() => {
    setShowDrawer(false);
  }, [setShowDrawer]);

  const handleClickRemoveCar = useCallback(
    (id: number) => {
      removeCompareCarSelected(id);
    },
    [removeCompareCarSelected],
  );

  const renderAddCarButton = useMemo(() => {
    const remainLength = maxCarCompareLength - compareCarSelectedList.length;
    return Array.from({ length: remainLength }, (_, index) => {
      return (
        <Fragment key={`add-car-button-${index}`}>
          <CardAddCar
            onClick={handleClickAddCar}
            customContainerSx={{
              minHeight: { sm: "230px", md: "280px" },
            }}
          />
        </Fragment>
      );
    });
  }, [compareCarSelectedList, handleClickAddCar]);

  return (
    <Box
      gap={{ xs: 1, md: 3 }}
      sx={{
        display: { xs: "flex", sm: "grid" },
        ...(isLessThanSm
          ? {
              flexDirection: "column",
            }
          : {
              gridTemplateColumns: {
                sm: "repeat(3, minmax(0, 204px))",
                md: "repeat(3, minmax(0, 295px))",
              },
            }),
      }}
    >
      {compareCarSelectedList?.map((carSelected) => {
        return (
          <Fragment key={`card-car-${carSelected.VehicleMasterId}`}>
            <CardCarDetail
              data={carSelected}
              layout={isLessThanSm ? "horizontal" : "vertical"}
              onClickRemove={() => handleClickRemoveCar(carSelected.VehicleMasterId)}
              customImageContainerSx={{
                width: {
                  xs: "120px",
                  sm: "204px",
                  md: "235px",
                  lg: "295px",
                },
                height: {
                  xs: "80px",
                  sm: "138px",
                  md: "195px",
                  lg: "200px",
                },
              }}
            />
          </Fragment>
        );
      })}
      {renderAddCarButton}
    </Box>
  );
};

export default DrawerBodySection;
