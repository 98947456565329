import ButtonCustom from "@/components/Button/ButtonCustom";
import RHFTextField from "@/components/Form/RHFTextField";
import { useTranslation } from "@/i18n/client";
import color from "@/styles/color";
import { Grid, Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import RHFPhoneNumberField from "@/components/Form/RHFPhoneNumberField";
import RHFAutoComplete from "@/components/Form/RHFAutoComplete";
import { UserProfile } from "@/libs/types/edit-profile.type";
import RHFEmailField from "@/components/Form/RHFEmailField";
import RHFDatePickerField from "@/components/Form/RHFDatepicker";
import { genericType } from "@/libs/helpers/map.helper";
import { authentication } from "@/libs/services/authentication/authentication.service";
import dayjs from "dayjs";

type EditProdileFormProps = {
  form: UseFormReturn<UserProfile, any, undefined>;
};

type GenderResponse = {
  id: number;
  genderName: string;
};

const genderOrder: Record<string, number> = {
  "Male": 1,
  "Female": 2,
  "Other": 3
};

const EditProfileForm: FC<EditProdileFormProps> = () => {
  const { t } = useTranslation("", {
    keyPrefix: "Authen",
  });

  const [isLoad, setIsLoad] = useState(true);
  const [genders, setGenders] = useState<Record<string, string>[]>([]);

  const getGenderData = async () => {
    try {
      const responseGender = await authentication.gender();
      if (responseGender.status === 200) {
        const data = genericType<GenderResponse[]>(responseGender.data || []);
        const gender = data
        .sort((prev, curr) => genderOrder[prev.genderName] - genderOrder[curr.genderName])
        .map<Record<string, string>>((gend) => ({
          value: String(gend.id),
          label: gend.genderName,
        }));
        setGenders(gender);
      }
    } catch (error) {
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    getGenderData();
  }, []);

  return (
    <Stack sx={{ gap: 3 }}>
      <Stack
        sx={{
          overflowY: { xs: "auto", sm: "unset" },
          maxHeight: { xs: "310px", sm: "unset" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <RHFTextField
              name="firstName"
              label={t("form.first-name")}
              placeholder={t("placeholder.first-name")}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFTextField
              name="lastName"
              label={t("form.last-name")}
              placeholder={t("placeholder.last-name")}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFAutoComplete
              id="genderId"
              name="genderId"
              label={t("form.gender")}
              options={genders}
              placeholder={t("placeholder.gender")}
              inputProps={{}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFDatePickerField
              name="dateOfBirth"
              label={t("form.dateOfBirth")}
              placeholder={t("placeholder.date-of-birth")}
              maxDate={dayjs()}
              disableFuture={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFPhoneNumberField
              name="phone"
              label={t("form.phone")}
              placeholder={t("placeholder.phone")}
              autoComplete="new-phone"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFEmailField
              disabled
              name="email"
              withIcon={false}
              autoComplete="new-email"
              label={t("form.email")}
              placeholder={t("placeholder.email")}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFTextField
              name="citizenId"
              label={t("form.citizen-id")}
              placeholder={t("placeholder.specify")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RHFTextField
              name="lineId"
              label={t("form.line-id")}
              placeholder={t("placeholder.specify")}
            />
          </Grid>
          <Grid item xs={12}>
            <RHFTextField
              name="address"
              label={t("form.address")}
              placeholder={t("placeholder.specify")}
            />
          </Grid>
        </Grid>
      </Stack>
      <ButtonCustom
        type="submit"
        disabled={isLoad}
        label={t(`edit-profile.button-change-password`)}
        sx={{
          height: { xs: "40px" },
          fontSize: { xs: "14px", xl: "16px" },
          background: color.redGradient,
          color: color.white,
        }}
      />
    </Stack>
  );
};

export default EditProfileForm;
