import { Box, SxProps, Typography } from "@mui/material";
import CheckboxCustom from "../Checkbox/CheckboxCustom";
import color from "@/styles/color";
import DrawerBottomCustom from "../Drawer/DrawerBottomCustom";
import ModalCloseOutBoxCustom from "../Modal/ModalCloseOutBoxCustom";
import DealerPrivacyPolicyModalContent from "@/modules/product-detail/components/modal/DealerPrivacyPolicyModalContent";
import { FC, useState } from "react";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import IconLoader from "../IconLoader";
import { ENV_CONFIG_SERVICE_TERM } from "@/libs";
import { useTranslation } from "@/i18n/client";

type AcceptPrivacyPolicyCheckboxProps = {
  checked: boolean;
  onCheckedAcceptPolicy: () => void;
  checkboxSx?: SxProps;
};

const AcceptPrivacyPolicyCheckbox: FC<AcceptPrivacyPolicyCheckboxProps> = ({
  checked,
  onCheckedAcceptPolicy,
}) => {
  const { t } = useTranslation()
  const { isLessThanSm } = useReadScreen();
  const [showServiceTermOpen, setShowServiceTermOpen] = useState<boolean>(false);
  const [contentId, setContentId] = useState<string>();
  const allContentId = ENV_CONFIG_SERVICE_TERM;
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <CheckboxCustom
          checked={checked}
          sx={{
            color: color.gray400,
            width: "30px",
            height: "30px",
          }}
          iconSize="30px"
          icon={
            <IconLoader iconName="UncheckedIcon" sx={{ fontSize: "24px", color: color.gray400 }} />
          }
          onClick={onCheckedAcceptPolicy}
        />
        <Typography
          component={"span"}
          sx={{
            fontWeight: 300,
            fontSize: { xs: "12px", sm: "14px", xl: "14px" },
            lineHeight: { xs: "16px", sm: "18px" },
            color: color.gray400,
          }}
        >
          {t('Authen.consent.sec-1')}{" "}
          <button
            style={{
              color: color.red,
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowServiceTermOpen(true);
              setContentId(allContentId.SERVICE_POLICY_TERM_CONDITION_ID);
            }}
          >
            {t('Authen.consent.sec-2')}
          </button>{" "}
          {t('Authen.consent.sec-3')}{" "}
          <button
            style={{
              color: color.red,
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowServiceTermOpen(true);
              setContentId(allContentId.PRIVACY_POLICY_TERM_CONDITION_ID);
            }}
          >
            {t('Authen.consent.sec-4')}
          </button>{" "}
          {t('Authen.consent.sec-5')}{" "}
          <button
            style={{
              color: color.red,
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowServiceTermOpen(true);
              setContentId(allContentId.COOKIE_POLICY_TERM_CONDITION_ID);
            }}
          >
            {t('Authen.consent.sec-6')}
          </button>
        </Typography>
      </Box>
      <ModalCloseOutBoxCustom
        name="privacy-policy"
        open={!isLessThanSm && showServiceTermOpen}
        onClose={() => setShowServiceTermOpen(false)}
        dialogProps={{
          sx: {
            "& .MuiPaper-root": {
              bgcolor: "transparent",
              margin: "8px",
              boxShadow: "unset",
              width: { xs: "1060px" },
              maxWidth: { xs: "1060px" },
            },
            "& .MuiModal-backdrop": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          },
        }}
      >
        <DealerPrivacyPolicyModalContent contentId={contentId} />
      </ModalCloseOutBoxCustom>
      <DrawerBottomCustom
        labelName={t('Authen.consent.label')}
        isOpen={isLessThanSm && showServiceTermOpen}
        onCloseDrawer={() => setShowServiceTermOpen(false)}
      >
        <DealerPrivacyPolicyModalContent contentId={contentId} />
      </DrawerBottomCustom>
    </>
  );
};

export default AcceptPrivacyPolicyCheckbox;
