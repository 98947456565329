"use client";

import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { Box, Container, Dialog, DialogContent, Stack, Toolbar, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { getCookie, setCookie } from "cookies-next";

import DrawerBottomCustom from "../Drawer/DrawerBottomCustom";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { cookieConsentDetail } from "@/libs/constant/cookie";
import color from "@/styles/color";
import IconButtonLightWeightCustom from "../Button/IconButtonLightWeightCustom";
import IconLoader from "../IconLoader";
import { useTranslation } from "@/i18n/client";

const CookieConsentKey = "accept-cookie-consent";

const CookieConsent = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const { isLessThanSm } = useReadScreen();
  const [showFloating, setShowFloating] = useState<boolean>(false);
  const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false);

  const initialState = useRef<boolean>(false);

  useEffect(() => {
    if (!initialState.current) {
      const accepted = getCookie(CookieConsentKey);
      setShowFloating(!Boolean(accepted));
      initialState.current = true;
    }
  }, [language]);

  const renderCookieConsentDetail = (
    <Box
      component="div"
      sx={{
        overflowY: "auto",
        "& > div": {
          lineHeight: "1.725rem",
          "& p": {
            marginBottom: "12px !important",
          },
          "& ol": {
            listStyleType: "decimal",
            paddingLeft: "40px !important",
          },
        },
      }}
      dangerouslySetInnerHTML={{ __html: cookieConsentDetail }}
    />
  );

  const handleHideFloating = useCallback(() => {
    setShowFloating(false);
    setCookie(CookieConsentKey, true);
  }, []);

  const handleClose = useCallback(() => {
    setShowDetailPopup(false);
  }, []);

  return (
    <>
      <Toolbar
        component={"nav"}
        sx={{
          background: color.black10,
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: 5000,
          paddingY: 3,
          transition: "all .3s ease-out",
          transform: `translateY(${showFloating ? 0 : 100}%)`,
        }}
        variant="dense"
        disableGutters
      >
        <Container
          maxWidth="2xl"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            padding: "16px !important",
          }}
        >
          <IconButtonLightWeightCustom
            aria-label="close-modal"
            sx={{
              position: "absolute",
              top: "-5px",
              right: "15px",
              zIndex: 100,
              width: "30px",
              height: "30px",
              background: color.redGradient,
              ":hover": { background: color.redGradient, opacity: 0.9 },
            }}
            onClick={handleHideFloating}
          >
            <CloseRoundedIcon
              sx={{
                fontSize: "20px",
                color: color.white,
              }}
            />
          </IconButtonLightWeightCustom>
          <Stack sx={{ flexDirection: "row", gap: 1, alignItems: "top" }}>
            <IconLoader
              iconName="CoookieIcon"
              sx={{ fontSize: "16px", color: color.white, marginTop: { lg: "3px" } }}
            />
            <Suspense>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: "12px", lg: "14px" },
                  lineHeight: { xs: "18px", sm: "20px", lg: "24px" },
                  color: color.white,
                  whiteSpace: "pre-wrap",
                  span: {
                    fontWeight: 500,
                    fontSize: { xs: "12px", lg: "14px" },
                    lineHeight: { xs: "18px", sm: "20px", lg: "24px" },
                    background: color.redGradient,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    position: "relative",
                    cursor: "pointer",
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      borderImage: `${color.redGradient} 1`,
                      borderStyle: "solid",
                      borderBottomWidth: 1,
                      width: "100%",
                      bottom: 2,
                      left: 0,
                    },
                  },
                }}
              >
                <Trans
                  i18nKey="Cookie.title"
                  components={{
                    color: <button
                      onClick={() => setShowDetailPopup(true)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          setShowDetailPopup(true);
                        }
                      }}
                      tabIndex={0}
                      aria-label="View cookie policy details"
                    />,
                  }}
                />
              </Typography>
            </Suspense>
          </Stack>
        </Container>
      </Toolbar>
      <Dialog
        aria-labelledby="modal-cookie-detail"
        open={Boolean(!isLessThanSm && showDetailPopup)}
        onClose={(_, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") return;
          handleClose();
        }}
        sx={{
          zIndex: 5005,
          height: "100%",
          maxHeight: "90dvh",
          overflow: "hidden",
          "& .MuiPaper-root": {
            bgcolor: "transparent",
            margin: "8px",
            boxShadow: "unset",
            width: { xs: "100%" },
            maxWidth: { xs: "960px" },
            height: "100%",
          },
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: "0px",
            backgroundColor: "transparent",
          }}
        >
          <Box
            sx={{
              height: "100%",
              position: "relative",
            }}
          >
            <IconButtonLightWeightCustom
              aria-label="close-modal"
              sx={{
                position: "absolute",
                top: "5px",
                right: "5px",
                zIndex: 100,
                width: "60px",
                height: "60px",
                background: color.redGradient,
                ":hover": { background: color.redGradient, opacity: 0.9 },
              }}
              onClick={handleClose}
            >
              <CloseRoundedIcon
                sx={{
                  fontSize: "40px",
                  color: color.white,
                }}
              />
            </IconButtonLightWeightCustom>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                padding: { sm: "30px" },
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  bgcolor: color.white,
                  borderRadius: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  padding: { sm: "32px 24px" },
                  gap: 2,
                }}
              >
                {renderCookieConsentDetail}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <DrawerBottomCustom
        isOpen={Boolean(isLessThanSm && showDetailPopup)}
        onCloseDrawer={handleClose}
        sx={{
          zIndex: 5010,
        }}
      >
        {renderCookieConsentDetail}
      </DrawerBottomCustom>
    </>
  );
};

export default CookieConsent;
