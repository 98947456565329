import axios from "axios";
import { ENV_CONFIG } from "../env";
import { getCookie } from "cookies-next";

const lang = getCookie('preferred_language')

const axiosSelfInstance = axios.create({
  baseURL: ENV_CONFIG.MAIN_URL,
  headers: {
    "Content-type": "application/json",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
    "X-Frame-Options": "DENY",
    "Content-Security-Policy": "default-src 'self'; object-src 'none'",
    "X-Content-Type-Options": "nosniff",
    "Cache-Control": "no-store, no-cache, must-re-validate"
  },
  params: {
    Lang: lang
  },
  withCredentials: true,
});

axiosSelfInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosSelfInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    return Promise.reject(err);
  },
);

export { axiosSelfInstance };
