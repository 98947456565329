"use client";

import { Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";
import { useForm } from "react-hook-form";
import RHFFormProvider from "@/libs/react-hook-form/FormProvider";
import { OnClickProp } from "../AuthenticationContent";
import EditProfileForm from "../forms/EditProfile";
import { ResponseUserProfile, UserProfile } from "@/libs/types/edit-profile.type";
import userValidationSchema from "../forms/schema/edit-profile";
import { useEffect } from "react";
import { authentication } from "@/libs/services/authentication/authentication.service";
import { genericType } from "@/libs/helpers/map.helper";
import dayjs from "dayjs";

const EditProfilePage = ({ onClick }: OnClickProp) => {
  const editProfile = useForm<UserProfile>({
    resolver: yupResolver(userValidationSchema),
  });

  const getInitialData = async () => {
    const response = await authentication.getProfile();
    const data = genericType<ResponseUserProfile>(response.data);

    if (response.status === 200) {
      editProfile.reset({
        ...data,
        ...(data.genderId && {
          genderId: data.genderId.toString(),
        }),
        ...(data.dateOfBirth && {
          dateOfBirth: dayjs(data.dateOfBirth).toDate(),
        }),
      });
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handleOnSubmit = editProfile.handleSubmit(async (params) => {
    onClick({ event: AuthenticationPageEnum.EditProfile, body: params });
  });

  return (
    <Stack sx={{ gap: { xs: 3, sm: 2 } }}>
      <RHFFormProvider methods={editProfile} onSubmit={handleOnSubmit}>
        <EditProfileForm form={editProfile} />
      </RHFFormProvider>
    </Stack>
  );
};

export default EditProfilePage;
