"use Client";

import { DividerCustom } from "@/components/Divider/DividerCustom";
import color from "@/styles/color";
import { Box, Typography } from "@mui/material";
import { DealerLine, TSocialKey } from "@/contexts/useDealer";
import DealerDetailLine from "@/components/Dealer/DealerDetailLine";
import { useCallback, useState } from "react";
import { copyToClipboard } from "@/libs/utils/function";
import ModalContactSuccess from "@/modules/find-my-car/components/modal/ModalContactSuccess";
import { useTranslation } from "@/i18n/client";

interface IDealerSocialContactModalContentProps {
  from: TSocialKey;
  title: string;
  lineContact: DealerLine;
}

function DealerDetailLineContactModalContent({
  lineContact,
  title,
}: IDealerSocialContactModalContentProps) {
  const { t } = useTranslation();
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const setShowAndClosePopup = async () => {
    setIsCopySuccess(true);
    await new Promise(() => setTimeout(() => setIsCopySuccess(false), 2500));
  };

  const handleCopy = useCallback((text: string) => {
    copyToClipboard(text);
    setShowAndClosePopup();
  }, []);

  return (
    <>
      <Box component={"div"} sx={{ width: "100%", padding: { xs: "24px 0px", sm: "30px" } }}>
        <Box
          component={"div"}
          sx={{
            width: "100%",
            bgcolor: color.white,
            borderRadius: "16px",
            padding: { xs: "0px", sm: "32px 24px" },
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Typography
            component={"h3"}
            sx={{
              display: { xs: "none", sm: "block" },
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "34px",
              background: color.redGradient,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
          <DividerCustom
            sx={{
              display: { xs: "none", sm: "block" },
              borderColor: color.gray170,
            }}
          />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: "0.75rem", xl: "0.875rem" },
              lineHeight: { xs: "24px", xl: "27px" },
              color: color.gray400,
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {t('common.contact.Official-line-id')}
          </Typography>
          <DealerDetailLine lineId={lineContact.offical} onCopy={handleCopy} />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: "0.75rem", xl: "0.875rem" },
              lineHeight: { xs: "24px", xl: "27px" },
              color: color.gray400,
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {t('common.contact.Saleman-line-id')}
          </Typography>
          <Box
            component={"div"}
            sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
          >
            {lineContact?.saleman?.map((item, index) => (
              <DealerDetailLine
                lineId={item.lineId}
                key={`dealer_line_item_${index}`}
                onCopy={handleCopy}
              />
            ))}
          </Box>
        </Box>
      </Box>

      <ModalContactSuccess
        visible={isCopySuccess}
        message={t("Article.dataLink.copyLink-success")}
      />
    </>
  );
}

export default DealerDetailLineContactModalContent;
