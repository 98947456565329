"use client";

import { Box, Stack } from "@mui/material";
import { AuthenticationPageEnum } from "@/libs/enum/authentication.enum";
import color from "@/styles/color";
import { ForgotPassword, Login, Verify, Register, ResetPassword } from "./pages";
import { useAuthenticationStore } from "@/contexts/useAuthentication";
import EditProfilePage from "./pages/editProfile";
import ChangePasswordPage from "./pages/ChangePassword";
import { useAuthStore } from "@/contexts/useAuth";

export type OnClickProp = {
  onClick: (params: Record<string, string | Object>) => Promise<void>;
};

type Props = {
  event: AuthenticationPageEnum | null;
  onClick: (params: Record<string, string | Object>) => Promise<void>;
};

const AuthenticationContent = ({ onClick }: Props) => {
  const { currentPage } = useAuthenticationStore();
  const { isLogged } = useAuthStore();
  return (
    <Box
      component={"div"}
      sx={{
        width: "100%",
        bgcolor: color.white,
        borderRadius: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: { xs: "14px" },
      }}
    >
      <Stack
        sx={{
          paddingY: { xs: 3, sm: 0 },
        }}
      >
        {currentPage === AuthenticationPageEnum.Login && <Login onClick={onClick} />}
        {currentPage === AuthenticationPageEnum.Register && <Register onClick={onClick} />}
        {currentPage === AuthenticationPageEnum.Verify && <Verify onClick={onClick} />}
        {currentPage === AuthenticationPageEnum.ForgotPassword && (
          <ForgotPassword onClick={onClick} />
        )}
        {currentPage === AuthenticationPageEnum.ResetPassword && (
          <ResetPassword onClick={onClick} />
        )}
        {currentPage === AuthenticationPageEnum.EditProfile && isLogged && (
          <EditProfilePage onClick={onClick} />
        )}
        {currentPage === AuthenticationPageEnum.ChangePassword && isLogged && (
          <ChangePasswordPage onClick={onClick} />
        )}
      </Stack>
    </Box>
  );
};

export default AuthenticationContent;
