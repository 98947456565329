"use client";

import IconLoader from "@/components/IconLoader";
import color from "@/styles/color";
import { Box, Modal, Typography } from "@mui/material";
import React from "react";

interface ModalContactSuccessType {
  visible: boolean;
  message: string;
}

const ModalContactSuccess = ({ visible, message }: ModalContactSuccessType) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: 'none',
    outline: 'none'
  };

  return (
    <Modal
      open={visible}
      aria-labelledby="modal-success-title"
      aria-describedby="modal-success-description"
    >
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: "flex",
            width: { xs: 180, sm: 250, lg: 300 },
            height: { xs: 180, sm: 250, lg: 300 },
            bgcolor: "#FFFFFF",
            borderRadius: { xs: 8, sm: 16, lg: 16 },
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <IconLoader
            iconName="SuccessIcon"
            sx={{
              width: 80,
              height: 80,
            }}
          />
          <Typography
            sx={{
              display: "block",
              fontWeight: 600,
              fontSize: { xs: 16, sm: 20, xl: 24 },
              lineHeight: "34px",
              background: color.black,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textAlign: "center",
            }}
          >
            {message}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalContactSuccess;
