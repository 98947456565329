import axios, { AxiosRequestConfig } from "axios";
import { ENV_CONFIG } from "../env";
import { axiosSelfInstance } from "./axios-self-instance";
import { getDsureSession } from "../actions/dsure-session";
import { DSureSessionData, unsealData } from "iron-session";
import { sessionOptions } from "../configs/dsure.session";
import { getCookie } from "cookies-next";

type RetryQueueItem = {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
};
const lang = getCookie('preferred_language')

const axiosDSureInstance = axios.create({
  baseURL: ENV_CONFIG.API_URL,
  headers: {
    "Content-type": "application/json",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
    "X-Frame-Options": "DENY",
    "Content-Security-Policy": "default-src 'self'; object-src 'none'",
    "X-Content-Type-Options": "nosniff",
    "Cache-Control": "no-store, no-cache, must-re-validate"
  },
  params: {
    Lang: lang
  },
});

axiosDSureInstance.interceptors.request.use(
  async (config) => {
    const secureDSureSession = await getDsureSession();
    const dSureSession = await unsealData<DSureSessionData>(secureDSureSession, {
      password: sessionOptions.password,
    });
    if (dSureSession.token) {
      config.headers["Token"] = dSureSession.token?.jwtToken;
    }

    return config

    // const lang = getCookie('preferred_language') || 'th'

    // return {...config, params: { ...config.params, Lang: lang }};
  },
  (error) => {
    return Promise.reject(error);
  },
);

let isRefreshing = false;
const refreshAndRetryQueue: RetryQueueItem[] = [];

axiosDSureInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;
    if (originalRequest.url !== "/TSure_API/rest/Auth/RefreshToken" && err.response) {
      if (
        (err.response.status === 401 && !originalRequest._retry) ||
        (err.response.status === 403 &&
          !originalRequest._retry &&
          err.response.data?.ErrorMessage?.includes("JWT Token Expired"))
      ) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            axiosSelfInstance.get("/auth/dsure/refresh_token").then(async () => {
              const secureDSureSession = await getDsureSession();
              const dSureSession = await unsealData<DSureSessionData>(secureDSureSession, {
                password: sessionOptions.password,
              });
              const token = dSureSession?.token?.jwtToken!;
              originalRequest.headers["Token"] = `${token}`;
              // Retry all requests in the queue with the new token
              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                axiosDSureInstance
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });
            });

            // Clear the queue
            refreshAndRetryQueue.length = 0;

            // Retry the original request
            return axiosDSureInstance(originalRequest);
          } catch (refreshError) {
            // Handle token refresh error
            // You can clear all storage and redirect the user to the login page
            throw refreshError;
          } finally {
            isRefreshing = false;
          }
        }
      }
      // Add the original request to the queue
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    return Promise.reject(err);
  },
);

export { axiosDSureInstance };
